import { WorkspaceInfo } from '@air/api/types';

const messageTypes = ['welcome', 'open-asset', 'open-board'] as const;

export type WebPageSocketMessageType = (typeof messageTypes)[number];

export interface BaseWebPageSocketMessage<T = any> {
  type: WebPageSocketMessageType;
  data: T;
}

export interface WebPageWelcomeMessageData {
  appVersion: string;
  syncedWorkspacesIds: WorkspaceInfo['id'][];
}

export interface WebPageWelcomeMessage extends BaseWebPageSocketMessage<WebPageWelcomeMessageData> {
  type: 'welcome';
}

export interface OpenAssetMessage
  extends BaseWebPageSocketMessage<{
    assetId: string;
    boardId?: string;
    /**
     * versionId was confusingly being passed in for assetId in the message
     * In order to maintain backwards compatability while also allowing for the correct value for assetId to sent,
     * we keep the original assetId field while also sending a new asset object
     */
    asset: { id: string };
  }> {
  type: 'open-asset';
}

export interface OpenBoardMessage extends BaseWebPageSocketMessage<{ boardId: string }> {
  type: 'open-board';
}

export type WebPageSocketMessage = WebPageWelcomeMessage | OpenAssetMessage | OpenBoardMessage;

export const isWebPageSocketMessage = (message: any): message is WebPageSocketMessage =>
  'type' in message && messageTypes.includes(message.type);
