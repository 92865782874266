import { Subscriptions } from '@air/api';
import { Subscription } from '@air/api/types';
import { useQuery } from '@tanstack/react-query';
import { isString } from 'lodash';

import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';

export const SUBSCRIPTION = 'SUBSCRIPTION';

export const getSubscriptionKey = (workspaceId?: string) => {
  return workspaceId ? [SUBSCRIPTION, { workspaceId }] : [SUBSCRIPTION];
};

export interface EnhancedSubscription extends Subscription {
  hasActiveSubscription: boolean;
  isInFreePlan: boolean;
  isOnPlusPlan: boolean;
  isOnProPlan: boolean;
  isFixedModel: boolean;
  isPerMemberModel: boolean;
  isEnterprise: boolean;
}

const proPlans: Subscription['plan'][] = ['pro', 'demo', 'enterprise'];

export const useSubscription = () => {
  const { currentWorkspace } = useCurrentWorkspace();
  const subscriptionKey = getSubscriptionKey(currentWorkspace?.id);

  return useQuery({
    queryKey: subscriptionKey,

    queryFn: async () => {
      const workspaceId = currentWorkspace?.id;
      if (!workspaceId) {
        throw new Error('No workspace id');
      }
      const subscription = await Subscriptions.get({ workspaceId });

      return {
        ...subscription,
        /**
         * The backend is currently sending down a string for the usedStorage instead of a number sometimes (it has to do with caching).
         */
        usedStorage: isString(subscription.usedStorage) ? parseInt(subscription.usedStorage) : subscription.usedStorage,
        hasActiveSubscription: subscription.status === 'active',
        isFixedModel: subscription.pricingModel === 'fixed',
        isPerMemberModel: subscription.pricingModel === 'perMember',
        isInFreePlan: subscription.plan === 'basic',
        isOnPlusPlan: subscription.plan === 'plus',
        isEnterprise: subscription.plan === 'enterprise',
        isOnProPlan: proPlans.includes(subscription.plan),
      };
    },
    staleTime: Infinity,
    enabled: !!currentWorkspace?.id,
  });
};
