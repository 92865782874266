import { AirActionTrackingLocation, useTrackFavoritedBoard } from '@air/analytics';
import { Boards } from '@air/api';
import { Board } from '@air/api/types';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { useAccountContext } from '~/providers/AccountProvider';
import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';
import { addUserToBoardAction, removeUserFromBoardAction } from '~/store/centralizedBoard/actions';
import { reportErrorToBugsnag } from '~/utils/ErrorUtils';
import { useFavoriteBoardInAllLists, useUnfavoriteBoardInAllLists } from '~/utils/mutateUtils/AllBoards';

import { useActiveSubscriptionCallback } from '../subscriptions/useActiveSubscriptionCallback';

/**
 * This hook gives you a function to favorite boards
 */
export const useFavoriteBoards = () => {
  const dispatch = useDispatch();
  const { activeSubscriptionCallback } = useActiveSubscriptionCallback();
  const { data: user } = useAccountContext();
  const { favoriteBoardInAllLists } = useFavoriteBoardInAllLists();
  const { unfavoriteBoardInAllLists } = useUnfavoriteBoardInAllLists();
  const { trackFavoritedBoard } = useTrackFavoritedBoard();
  const { currentWorkspace } = useCurrentWorkspace();
  const workspaceId = currentWorkspace?.id;

  const favoriteBoards = useCallback(
    ({
      boards,
      trackLocation,
      moveToTop = true,
    }: {
      boards: Board[];
      trackLocation: AirActionTrackingLocation;
      moveToTop?: boolean;
    }) =>
      activeSubscriptionCallback(async () => {
        if (!user?.id) {
          throw new Error(`User doesn't have an id`);
        }

        if (!workspaceId) {
          throw new Error('No workspaceId found');
        }

        return Promise.all(
          boards.map((board) => {
            try {
              favoriteBoardInAllLists(board);

              dispatch(
                addUserToBoardAction({
                  user,
                  boardId: board.id,
                  moveToTop,
                }),
              );

              trackFavoritedBoard({ boardId: board.id, boardTitle: board.title, location: trackLocation });

              return Boards.addMembers({ boardId: board.id, accountIds: [user.id], workspaceId });
            } catch (error) {
              unfavoriteBoardInAllLists(board);
              dispatch(removeUserFromBoardAction({ userId: user.id, boardId: board.id }));

              throw reportErrorToBugsnag({
                error,
                context: `Failed to favorite a board`,
                metadata: { key: 'Board', data: board },
              });
            }
          }),
        );
      }),
    [
      activeSubscriptionCallback,
      user,
      workspaceId,
      favoriteBoardInAllLists,
      dispatch,
      trackFavoritedBoard,
      unfavoriteBoardInAllLists,
    ],
  );

  return {
    favoriteBoards,
  };
};
