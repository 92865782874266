import { Board, ListSlackIntegrationsObject } from '@air/api/types';
import { memo } from 'react';

import { useBoardPermissions } from '~/hooks/useBoardPermissions';
import { useCurrentWorkspacePermissionsContext } from '~/providers/CurrentWorkspacePermissionsProvider';
import { canChangeBoardIntegrationDetails } from '~/utils/permissions/boardPermissions';

import { ConnectedToSlack } from './components/ConnectedToSlack';
import { ConnectToSlack } from './components/ConnectToSlack';

interface SlackIntegrationProps {
  integration: ListSlackIntegrationsObject;
  board: Board;
}

export const SlackIntegration = memo(({ integration, board }: SlackIntegrationProps) => {
  const { data: workspacePermissions } = useCurrentWorkspacePermissionsContext();
  const { boardPermissions } = useBoardPermissions({ boardId: board.id });
  const canManageSlackIntegration = canChangeBoardIntegrationDetails({
    workspaceContext: workspacePermissions,
    boardContext: boardPermissions,
  });

  return (
    <div>
      <div className="text-14 font-medium text-grey-12">Connect to Slack</div>
      <div className="text-12 text-grey-11">
        Connect this board to a channel in Slack to receive notifications for uploads, board creations, comments, and
        edits to custom fields.
      </div>
      {!!integration && (
        <ConnectedToSlack
          integration={integration}
          boardId={board.id}
          canManageSlackIntegration={canManageSlackIntegration}
        />
      )}
      {!integration && canManageSlackIntegration && <ConnectToSlack board={board} />}
    </div>
  );
});

SlackIntegration.displayName = 'SlackIntegration';
