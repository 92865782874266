import { Slack } from '@air/api';
import { WorkspaceInfo } from '@air/api/types';
import { useQuery } from '@tanstack/react-query';

import { useCurrentWorkspacePermissionsContext } from '~/providers/CurrentWorkspacePermissionsProvider';
import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';
import { canSeeWorkspaceIntegrationDetails } from '~/utils/permissions/workspacePermissions';

const BOARD_SLACK_INTEGRATIONS_LIST = 'BOARD_SLACK_INTEGRATIONS_LIST';

export const getBoardSlackIntegrationsKey = (boardId: WorkspaceInfo['id']) => {
  return [BOARD_SLACK_INTEGRATIONS_LIST, { boardId }];
};

export const useBoardSlackIntegrations = (boardId?: string) => {
  const { currentWorkspace } = useCurrentWorkspace();
  const { data: permissions } = useCurrentWorkspacePermissionsContext();

  return useQuery({
    queryKey: getBoardSlackIntegrationsKey(boardId!),

    queryFn: () => {
      const workspaceId = currentWorkspace?.id;
      if (!workspaceId) {
        throw new Error('No workspace id');
      }
      return Slack.listIntegrationsForBoard({ workspaceId, boardId: boardId! });
    },
    enabled: !!boardId && canSeeWorkspaceIntegrationDetails(permissions) && !!currentWorkspace,
    refetchOnWindowFocus: true,
  });
};
