import { isArray } from 'lodash';

import { isItemTypeFilter } from '~/components/Filters/types';
import {
  CustomFieldFilters,
  FilterParamNames,
  FiltersWithArrayValue,
  FiltersWithBooleanValue,
  FiltersWithSingleValue,
  QueryParamNames,
} from '~/constants/search';
import { FiltersContextValueType } from '~/providers/FiltersProvider';
import { FilterParamsType } from '~/utils/filters/filtersToUrlParamsUtils';

export const urlQueryToFilterParams = ({
  query,
}: {
  query: URLSearchParams;
}): Pick<FiltersContextValueType, 'filters' | 'search'> => {
  const arrayValueFilters = FiltersWithArrayValue.reduce<FilterParamsType>((acc, filterName) => {
    const values = query.getAll(filterName);
    if (values.length) {
      if (filterName === FilterParamNames.type) {
        acc[filterName] = values.filter(isItemTypeFilter);
      } else {
        acc[filterName] = values;
      }
    }

    return acc;
  }, {});

  const singleValueFilters = FiltersWithSingleValue.reduce<FilterParamsType>((acc, filterName) => {
    const value = query.get(filterName);
    if (value) {
      if (FiltersWithBooleanValue.includes(filterName)) {
        acc = { ...acc, [filterName]: value === 'true' };
      } else {
        acc = { ...acc, [filterName]: value };
      }
    }
    return acc;
  }, {});

  const customFieldKeys: { [key: string]: string[] } = {};
  for (const param of query.keys()) {
    CustomFieldFilters.forEach((filterName) => {
      if (param.startsWith(filterName)) {
        const values = query.getAll(param);
        if (values.length) {
          customFieldKeys[param] = values;
        }
      }
    });
  }

  const filters = {
    ...singleValueFilters,
    ...arrayValueFilters,
  };

  if (!!Object.keys(customFieldKeys).length) {
    filters.customFields = customFieldKeys;
  }
  let search = query.get(QueryParamNames.Search) ?? undefined;
  if (!!search && isArray(search)) {
    search = search.join(' ');
  }

  return {
    filters,
    search,
  };
};
