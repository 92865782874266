import { noop, omit } from 'lodash';
import Router from 'next/router';
import { createContext, type ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { QueryParamNames } from '~/constants/search';
import { isDevOrTestStage } from '~/swr-hooks/utils';
import { reportErrorToBugsnag } from '~/utils/ErrorUtils';

export enum AssetModalPanel {
  ANNOTATIONS = 'ANNOTATIONS',
  BOARDS = 'BOARDS',
  CAPTURED_TEXT = 'CAPTURED_TEXT',
  COMMENTS = 'COMMENTS',
  FIELDS = 'FIELDS',
  INFO = 'INFO',
  NONE = 'NONE',
  TRANSCRIPT = 'TRANSCRIPT',
  VERSIONS = 'VERSIONS',
  PEOPLE = 'PEOPLE',
}

export type AssetModalPanelContextValue = {
  panel: AssetModalPanel;
  hidePanel: () => void;
  showPanel: (panel: AssetModalPanel) => void;
  togglePanel: (panel: AssetModalPanel) => void;
  updatePanel: (panel: AssetModalPanel) => void;
};

const defaultContextValue: AssetModalPanelContextValue = {
  panel: AssetModalPanel.NONE,
  hidePanel: noop,
  showPanel: noop,
  togglePanel: noop,
  updatePanel: noop,
};

export const AssetModalPanelContext = createContext<AssetModalPanelContextValue>(defaultContextValue);

export type AssetModalPanelContextProviderProps = {
  children: ReactNode;
};

export const AssetModalPanelContextProvider = ({ children }: AssetModalPanelContextProviderProps) => {
  const [panel, setPanel] = useState<AssetModalPanel>(AssetModalPanel.NONE);

  useEffect(() => {
    /**
     * We use this instead of `useRouter` because we want to avoid re-rendering
     * the component when the query changes.
     */
    const searchParams = new URLSearchParams(window.location.search);
    const tab = searchParams.get(QueryParamNames.tab) as AssetModalPanel;

    if (tab) {
      setPanel(tab);
    }
  }, []);

  const updatePanel = useCallback((panel: AssetModalPanel) => {
    setPanel(panel);

    if (panel === AssetModalPanel.NONE) {
      Router.push(
        {
          pathname: Router.pathname,
          query: omit(Router.query, QueryParamNames.tab),
        },
        undefined,
        { shallow: true },
      );
    } else {
      Router.push(
        {
          pathname: Router.pathname,
          query: { ...Router.query, [QueryParamNames.tab]: panel.toString() },
        },
        undefined,
        { shallow: true },
      );
    }
  }, []);

  const togglePanel = useCallback(
    (newPanel: AssetModalPanel) => {
      if (newPanel === panel) {
        updatePanel(AssetModalPanel.NONE);
      } else {
        updatePanel(newPanel);
      }
    },
    [panel, updatePanel],
  );

  const hidePanel = useCallback(() => {
    updatePanel(AssetModalPanel.NONE);
  }, [updatePanel]);

  const showPanel = useCallback(
    (panel: AssetModalPanel) => {
      updatePanel(panel);
    },
    [updatePanel],
  );

  const value = useMemo<AssetModalPanelContextValue>(() => {
    return {
      hidePanel,
      panel,
      showPanel,
      togglePanel,
      updatePanel,
    };
  }, [hidePanel, panel, showPanel, togglePanel, updatePanel]);

  return <AssetModalPanelContext.Provider value={value}>{children}</AssetModalPanelContext.Provider>;
};

export const useAssetModalPanelContext = () => {
  const context = useContext(AssetModalPanelContext);

  if (context === defaultContextValue) {
    const error = 'useAssetModalPanelContext must be used within an AssetModalPanelContextProvider';
    if (isDevOrTestStage()) {
      throw error;
    } else {
      reportErrorToBugsnag({
        error,
        context: error,
      });
    }
  }

  return context;
};
