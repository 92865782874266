import { useTrackAddedShareLinkPasscode } from '@air/analytics';
import { ShortUrlResponse } from '@air/api/types';
import { Eye as EyeIcon, EyeClosed as EyeClosedIcon } from '@air/next-icons';
import { Button } from '@air/primitive-button';
import { IconButton } from '@air/primitive-icon-button';
import { Input } from '@air/primitive-input';
import { Switch } from '@air/primitive-switch';
import classNames from 'classnames';
import { Formik } from 'formik';
import { useToggle } from 'react-use';
import * as Yup from 'yup';

import { ShareUrlResponseWithSource } from '~/components/SharePrivateClipModal/types';
import { UpsellButton } from '~/components/UpsellButton';
import {
  PASSCODE_PROTECTION_INPUT,
  PASSCODE_PROTECTION_LABEL,
  PASSCODE_PROTECTION_SUBMIT_BUTTON,
  PASSCODE_PROTECTION_TOGGLE,
} from '~/constants/testIDs';
import { usePlanFeature } from '~/hooks/usePlanFeature';
import { PASSWORD_REQS_STRING } from '~/utils/Auth';

const SignUpValidationSchema = Yup.object().shape({
  passcode: Yup.string()
    .required('Passcode is required')
    .min(8, 'Passcode must be at least 8 characters.')
    .matches(/\d+/, 'Passcode must include a number.')
    .matches(/[a-z]/, 'Passcode must include one lowercase letter.')
    .matches(/[A-Z]/, 'Passcode must include one uppercase letter.')
    .default(''),
});

interface SharePrivateContentPasscodeProtectionProps {
  shortIdInfo: ShortUrlResponse;
  onChangePasscode: (passcode: string | null) => Promise<ShareUrlResponseWithSource>;
}

export const SharePrivateContentPasscodeProtection = ({
  shortIdInfo,
  onChangePasscode,
}: SharePrivateContentPasscodeProtectionProps) => {
  const canUsePasscodeProtectedLinks = usePlanFeature('passcodeProtectedLinks');
  const [showPasscode, toggleShowPasscode] = useToggle(true);
  const [isShowingPasscodeForm, showPasscodeForm] = useToggle(!!shortIdInfo.passcode);
  const { trackAddedShareLinkPasscode } = useTrackAddedShareLinkPasscode();

  return (
    <div>
      <div className="flex items-center justify-between">
        <div className="text-14 text-grey-12" data-testid={PASSCODE_PROTECTION_LABEL}>
          Add passcode protection
        </div>
        {!canUsePasscodeProtectedLinks ? (
          <UpsellButton>Upgrade to Air Pro</UpsellButton>
        ) : (
          <Switch
            checked={isShowingPasscodeForm}
            data-testid={PASSCODE_PROTECTION_TOGGLE}
            id="password-protect"
            onClick={async () => {
              if (shortIdInfo.passcode && isShowingPasscodeForm) {
                // don't await on the update. update immediately
                onChangePasscode(null);
              }

              showPasscodeForm(!isShowingPasscodeForm);
            }}
          />
        )}
      </div>

      {canUsePasscodeProtectedLinks && isShowingPasscodeForm && (
        <Formik
          validationSchema={SignUpValidationSchema}
          initialValues={{
            passcode: shortIdInfo.passcode ?? '',
          }}
          onSubmit={async ({ passcode }, { setSubmitting, resetForm }) => {
            try {
              setSubmitting(true);
              trackAddedShareLinkPasscode({ shortId: shortIdInfo?.id });
              await onChangePasscode(passcode || null);
              setSubmitting(false);

              resetForm({
                values: {
                  passcode,
                },
              });
            } catch (_error) {
              setSubmitting(false);
            }
          }}
        >
          {({ isSubmitting, handleSubmit, values, handleChange, dirty, isValid, errors, touched, handleBlur }) => (
            <div>
              <form className="relative mt-3 flex items-center" onSubmit={handleSubmit}>
                <div className="relative w-full">
                  <Input
                    size="large"
                    value={values.passcode}
                    data-testid={PASSCODE_PROTECTION_INPUT}
                    onChange={handleChange}
                    type={showPasscode ? 'text' : 'password'}
                    name="passcode"
                    className={classNames('w-full pr-8', !isValid && dirty && 'border-red-9')}
                    placeholder="Enter passcode"
                    onBlur={handleBlur}
                  />
                  <IconButton
                    appearance="ghost"
                    className="absolute right-1 top-1"
                    color="grey"
                    label="Show passcode"
                    icon={showPasscode ? EyeClosedIcon : EyeIcon}
                    onClick={toggleShowPasscode}
                    size="medium"
                  />
                </div>
                <Button
                  appearance="filled"
                  className="ml-3"
                  color="grey"
                  data-testid={PASSCODE_PROTECTION_SUBMIT_BUTTON}
                  disabled={isSubmitting || !dirty || !isValid}
                  isLoading={isSubmitting}
                  size="large"
                  type="submit"
                >
                  Save
                </Button>
              </form>
              {!!errors.passcode && touched.passcode ? (
                <p className="mb-0 text-12 text-red-9">{errors.passcode}</p>
              ) : (
                <p className="mb-0 text-12 text-grey-10">{PASSWORD_REQS_STRING}</p>
              )}
            </div>
          )}
        </Formik>
      )}
    </div>
  );
};
