import { Subscriptions } from '@air/api';
import { useQuery } from '@tanstack/react-query';

import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';
import { useSubscriptionContext } from '~/providers/SubscriptionProvider';

export const SUBSCRIPTION_PLAN = 'SUBSCRIPTION_PLAN';

export const getSubscriptionPlanKey = (workspaceId?: string) => {
  return workspaceId ? [SUBSCRIPTION_PLAN, { workspaceId }] : [SUBSCRIPTION_PLAN];
};

export const useSubscriptionPlan = () => {
  const { data: subscription } = useSubscriptionContext();
  const { currentWorkspace } = useCurrentWorkspace();

  return useQuery({
    queryKey: getSubscriptionPlanKey(currentWorkspace?.id),

    queryFn: () => {
      const workspaceId = currentWorkspace?.id;
      if (!workspaceId) {
        throw new Error('No workspace id');
      }
      return Subscriptions.getSubscriptionPlan({ workspaceId });
    },
    enabled: !!currentWorkspace?.id && !!subscription?.planId,
  });
};
