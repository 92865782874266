import { useTrackClickedNotifyAdminLink } from '@air/analytics';
import { useCallback } from 'react';

import AdminImage from '~/assets/bird-carrying-sack.svg';
import UserImage from '~/assets/cat-in-box.svg';
import { AccountAnnouncementModal } from '~/components/Modals/AccountAnnouncementModal';
import { useFixPlanAction } from '~/hooks/useFixPlanAction';
import { useCurrentWorkspacePermissionsContext } from '~/providers/CurrentWorkspacePermissionsProvider';
import { canManageSubscription } from '~/utils/permissions/workspacePermissions';

const baseDescription = `Your workspace's subscription has expired.`;
const descriptionForAdmin = baseDescription.concat(` Reactivate now to keep using Air.`);
const descriptionForNonAdmin = baseDescription.concat(` Notify an admin to reactivate.`);

/**
 * This modal is displayed when a non-admin user clicks 'Upgrade plan'
 */
export const SubscriptionExpiredModal = ({ onClose }: AirModalProps<object>) => {
  const { trackClickedNotifyAdminLink } = useTrackClickedNotifyAdminLink();
  const { data: workspacePermissions } = useCurrentWorkspacePermissionsContext();

  const clickedNotifyAdmin = useCallback(
    () => trackClickedNotifyAdminLink({ location: 'Subscription expired modal' }),
    [trackClickedNotifyAdminLink],
  );

  const actionProps = useFixPlanAction({
    action: 'renew',
    onClickAction: clickedNotifyAdmin,
    onShowPlansModal: onClose,
  });

  const canEditPlan = canManageSubscription(workspacePermissions);

  return (
    <AccountAnnouncementModal
      image={canEditPlan ? AdminImage : UserImage}
      onClose={onClose}
      description={canEditPlan ? descriptionForAdmin : descriptionForNonAdmin}
      title="Subscription expired"
      ctaProps={actionProps}
    />
  );
};
