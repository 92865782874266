import { Libraries } from '@air/api';
import { BaseCustomField, type Library } from '@air/api/types';
import { useQuery } from '@tanstack/react-query';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';

/**
 * @TODO Remove once types are updated in `@air/api` package
 */
export type LibraryCustomField = Pick<BaseCustomField, 'id' | 'name' | 'type'> & {
  visible: boolean;
};

export const LIBRARY_CUSTOM_FIELDS_QUERY_KEY = 'LIBRARY_CUSTOM_FIELD';

export const getLibraryCustomFieldsKey = (libraryId: string | undefined) => [
  LIBRARY_CUSTOM_FIELDS_QUERY_KEY,
  { libraryId },
];

export type UseLibraryCustomFieldsParams = {
  libraryId: Library['id'] | undefined;
};

export const useLibraryCustomFields = ({ libraryId }: UseLibraryCustomFieldsParams) => {
  const { currentWorkspace } = useCurrentWorkspace();
  const { customFieldVisibility } = useFlags();

  return useQuery({
    queryKey: getLibraryCustomFieldsKey(libraryId),

    queryFn: () => {
      const workspaceId = currentWorkspace?.id;

      if (!workspaceId) {
        throw new Error('No workspace id');
      }

      if (!libraryId) {
        throw new Error('No library id');
      }

      return Libraries.listCustomFields({ workspaceId, libraryId });
    },
    enabled: !!currentWorkspace?.id && !!libraryId && !!customFieldVisibility,
  });
};
