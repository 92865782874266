import { tailwindVariants } from '@air/tailwind-variants';
import * as RadixPopover from '@radix-ui/react-popover';
import { PopoverPortalProps } from '@radix-ui/react-popover';

const popover = tailwindVariants({
  base: 'max-h-[calc(var(--radix-popover-content-available-height)-32px)] w-full overflow-y-auto rounded bg-surface-1 shadow-popover outline-none radix-side-bottom:animate-slide-up radix-side-top:animate-slide-down dark:border dark:border-grey-5',
  variants: {
    size: {
      small: 'min-w-[216px] p-1.5',
      large: 'min-w-[240px] p-2',
    },
  },
  defaultVariants: {
    size: 'small',
  },
});

export type PopoverSize = 'small' | 'large';

export type PopoverProps = RadixPopover.PopoverProps &
  RadixPopover.PopperContentProps & {
    size?: PopoverSize;
    trigger: RadixPopover.PopoverTriggerProps['children'];
    container?: PopoverPortalProps['container'];
  };

export const Popover = ({
  align = 'start',
  children,
  className,
  defaultOpen,
  modal,
  onOpenChange,
  open,
  side = 'bottom',
  sideOffset = 8,
  size = 'small',
  trigger,
  container,
  ...restOfProps
}: PopoverProps) => {
  return (
    <RadixPopover.Root defaultOpen={defaultOpen} open={open} onOpenChange={onOpenChange} modal={modal}>
      <RadixPopover.Trigger asChild>{trigger}</RadixPopover.Trigger>
      <RadixPopover.Portal container={container}>
        <RadixPopover.Content
          align={align}
          className={popover({ size, class: className })}
          side={side}
          sideOffset={sideOffset}
          {...restOfProps}
        >
          {children}
        </RadixPopover.Content>
      </RadixPopover.Portal>
    </RadixPopover.Root>
  );
};
