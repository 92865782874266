import { Search } from '@air/next-icons';
import { Input } from '@air/primitive-input';
import { debounce } from 'lodash';
import { memo, useCallback } from 'react';

export type BoardSelectSearchProps = {
  onChange: (search: string) => void;
};

export const BoardSelectSearch = memo(({ onChange }: BoardSelectSearchProps) => {
  /**
   * ESLint complains about passing inline function, but only this version works with debounce
   */
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedOnChange = useCallback(debounce(onChange, 300), [onChange]);

  return (
    <div className="relative w-full">
      <Search className="absolute left-2 top-1/2 h-6 w-6 -translate-y-1/2 text-grey-9" />
      <Input
        className="w-full pl-10"
        onChange={(event) => debouncedOnChange(event.target.value)}
        placeholder="Search workspace"
        size="large"
        type="search"
      />
    </div>
  );
});

BoardSelectSearch.displayName = 'BoardSelectSearch';
