import { permissionsSelector } from '~/store/permissions/selectors';
import { useAirSelector } from '~/utils/ReduxUtils';

export interface UseWorkspacePermissionsParams {
  workspaceId: string | undefined;
}

export const useWorkspacePermissions = ({ workspaceId }: UseWorkspacePermissionsParams) => {
  const workspacePermissions = useAirSelector((st) => permissionsSelector(st, 'workspaceId', workspaceId));

  return {
    workspacePermissions,
  };
};
