import { MutableRefObject } from 'react';

import { PENDING_SUBSCRIPTIONS, SUBSCRIPTIONS } from '~/constants/sockets';
import { ReadyState } from '~/types/sockets';
import { reportErrorToBugsnag } from '~/utils/ErrorUtils';
import { convertSubscriptionsToPending } from '~/utils/sockets/convert-subscriptions-to-pending';
import { getIterableSubscriptions } from '~/utils/sockets/get-iterable-subscriptions';

import { WebSocketMessage } from '../shared/typings';
import { sharedWebSocket } from './shared/constants';
import { CreateOrJoinArgs } from './shared/typings';

const RECONNECT_LIMIT = 20;

interface AttachWsListenersArgs extends Omit<CreateOrJoinArgs, 'url' | 'webSocketRef'> {
  webSocketInstance: WebSocket;
}

const handleSubscribeSuccess = (pendingUUID: string, subscriptionId: string) => {
  const pendingSubscription = PENDING_SUBSCRIPTIONS[pendingUUID];

  if (!pendingSubscription) {
    return;
  }

  // If the subscription already exists, just add the pending subscriptions listeners to its
  // set of listeners.
  if (!!SUBSCRIPTIONS[subscriptionId]) {
    pendingSubscription.subscribers.forEach(
      SUBSCRIPTIONS[subscriptionId].subscribers.add,
      SUBSCRIPTIONS[subscriptionId].subscribers,
    );
  } else {
    SUBSCRIPTIONS[subscriptionId] = { ...pendingSubscription, subscriptionId };
  }

  pendingSubscription.subscribers.forEach((subscriber) => {
    subscriber.options.current?.onSubscribe?.();
    subscriber.onSubscriptionSuccess(subscriptionId);
  });

  delete PENDING_SUBSCRIPTIONS[pendingUUID];
};

const bindMessageHandler = (webSocketInstance: WebSocket) => {
  webSocketInstance.onmessage = (message: WebSocketEventMap['message']) => {
    try {
      const data = JSON.parse(message.data);

      if (data.method === 'subscribe' && data.status === 'SUCCESS') {
        handleSubscribeSuccess(data.id, data.payload.subscriptionId);
      } else if (data.status === 'UPDATE') {
        const subscription = SUBSCRIPTIONS[data.payload.subscriptionId];
        if (!!subscription) {
          subscription.subscribers.forEach((subscriber) => {
            subscriber.options.current?.onUpdate?.(data.payload.event);
          });
        }
      }
    } catch (error) {
      reportErrorToBugsnag({ error, context: 'Failed to parse websocket message.', metadata: { message } });
    }
  };
};

const bindCloseHandler = (
  webSocketInstance: WebSocket,
  reconnect: () => void,
  setReadyState: (readyState: ReadyState) => void,
  reconnectCount: MutableRefObject<number>,
) => {
  if (webSocketInstance instanceof WebSocket) {
    webSocketInstance.onclose = () => {
      setReadyState(ReadyState.CLOSED);
      sharedWebSocket.ws = null;

      // If the websocket closes, we want to try and reconnect.
      if (reconnectCount.current < RECONNECT_LIMIT) {
        window.setTimeout(
          () => {
            reconnectCount.current = reconnectCount.current + 1;
            reconnect();
          },
          Math.random() * (10000 - 5000) + 5000,
        );
      } else {
        console.warn(
          `Max WebSocket reconnect attempts of ${RECONNECT_LIMIT} exceeded, connection couldn't be established`,
        );
      }
    };
  }
};

const bindErrorHandler = (webSocketInstance: WebSocket, setReadyState: (readyState: ReadyState) => void) => {
  webSocketInstance.onerror = (error: WebSocketEventMap['error']) => {
    const subscriptionsArray = getIterableSubscriptions();

    subscriptionsArray.forEach((subscription) => {
      subscription.subscribers.forEach((subscriber) => {
        subscriber.options.current?.onError?.(error);
      });
    });

    setReadyState(ReadyState.CLOSED);
  };
};

const bindOpenHandler = (
  webSocketInstance: WebSocket,
  setReadyState: (readyState: ReadyState) => void,
  messageQueue: AttachWsListenersArgs['messageQueue'],
) => {
  webSocketInstance.onopen = () => {
    // When the socket opens, convert any previously subscribed subscriptions to pending subscriptions
    // and re-send subscriptions messages for each. This conversion is required because the server treats each new client
    // connection as a new client, and will use different subscriptionIds, so we have to treat them as new client side as well.
    convertSubscriptionsToPending();

    const messages: WebSocketMessage[] = [];

    Object.keys(PENDING_SUBSCRIPTIONS).forEach((key) => {
      const subscription = PENDING_SUBSCRIPTIONS[key];

      if (!!subscription) {
        messages.push(
          JSON.stringify({
            method: 'subscribe',
            id: key,
            args: {
              eventType: subscription.event.eventType,
              pattern: { ...subscription.event.pattern, workspaceId: subscription.workspaceId },
            },
          }),
        );
      }
    });

    messageQueue.current = messageQueue.current.concat(messages);

    setReadyState(ReadyState.OPEN);
  };
};

export const attachWsListeners = ({
  webSocketInstance,
  setReadyState,
  reconnect,
  reconnectCount,
  messageQueue,
}: AttachWsListenersArgs) => {
  bindMessageHandler(webSocketInstance);
  bindCloseHandler(webSocketInstance, reconnect, setReadyState, reconnectCount);
  bindErrorHandler(webSocketInstance, setReadyState);
  bindOpenHandler(webSocketInstance, setReadyState, messageQueue);
};
