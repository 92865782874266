import { memo } from 'react';

import { PaneContainer } from '~/components/FileStatusTrackingPane/PaneContainer';
import { GetTaskInStatus, ZippingTask } from '~/store/tasks/types';

import { ZippingTaskCompletedPanelItem } from './ZippingTaskCompletedPanelItem';
import { ZippingTaskFailedPanelItem } from './ZippingTaskFailedPanelItem';
import { ZippingTaskInProgressPanelItem } from './ZippingTaskInProgressPanelItem';

export interface ZippingTasksPanelProps {
  zippingTasks: ZippingTask[];
  onCancel: (localTaskId: ZippingTask['localTaskId']) => void;
  onClear: (localTaskId: ZippingTask['localTaskId']) => void;
  onHandleExpiredLink: (remoteTaskId: GetTaskInStatus<ZippingTask, 'completed'>['remoteTaskId']) => void;
}

export const ZippingTasksPanel = memo(
  ({ zippingTasks, onCancel, onClear, onHandleExpiredLink }: ZippingTasksPanelProps) => {
    return (
      <div data-testid="ZIPPING_TASK_PANEL">
        {zippingTasks.map((zipping) => {
          return (
            <PaneContainer key={zipping.localTaskId} className="mb-2 last:mb-0">
              {zipping.status === 'in-progress' ? (
                <ZippingTaskInProgressPanelItem onCancel={() => onCancel(zipping.localTaskId)} {...zipping} />
              ) : zipping.status === 'completed' ? (
                <ZippingTaskCompletedPanelItem
                  onHandleExpiredLink={() => onHandleExpiredLink(zipping.remoteTaskId)}
                  onClear={() => onClear(zipping.localTaskId)}
                  {...zipping}
                />
              ) : (
                <ZippingTaskFailedPanelItem onClear={() => onClear(zipping.localTaskId)} {...zipping} />
              )}
            </PaneContainer>
          );
        })}
      </div>
    );
  },
);

ZippingTasksPanel.displayName = 'ZippingFilesPanel';
