import { type AirActionTrackingLocation } from '@air/analytics';
import type { Board, Clip } from '@air/api/types';
import { type Airror } from '@air/errors';
import { useToasts } from '@air/provider-toast';
import { useMutation } from '@tanstack/react-query';

import { BoardSearchLibrary } from '~/components/BoardSearch/shared/hooks';
import { ToastLink } from '~/components/UI/ToastLink';
import { useGoToBoardPage } from '~/hooks/useGoToBoardPage';
import { useMoveBoards } from '~/swr-hooks/boards/useMoveBoards';
import { useMoveAssetsToBoard } from '~/swr-hooks/clips/useMoveAssetsToBoard';
import { BoardsSelectOption } from '~/types/BoardSearch';
import { reportErrorToBugsnag } from '~/utils/ErrorUtils';
import { insertIf } from '~/utils/insertIf';
import { getBoardIdFromPath } from '~/utils/PathUtils';

export type UseMoveItemsParams = {
  boards?: Board[];
  clips?: Pick<Clip, 'assetId' | 'id' | 'openDiscussionCount'>[];
  trackLocation: AirActionTrackingLocation;
};

export const useMoveItems = ({ boards = [], clips = [], trackLocation }: UseMoveItemsParams) => {
  const { showToast } = useToasts();
  const { goToBoardPage } = useGoToBoardPage();
  const {
    moveBoards: { mutate: moveBoards },
  } = useMoveBoards();
  const {
    moveAssetsToBoard: { mutate: moveAssetsToBoard },
  } = useMoveAssetsToBoard();

  const moveItems = useMutation<
    void[],
    Error | Airror,
    {
      selectedBoard: BoardsSelectOption | null;
      selectedLibrary?: BoardSearchLibrary;
      onMove?: () => void;
    }
  >({
    mutationFn: ({ selectedBoard, selectedLibrary }) => {
      const currentBoardId = getBoardIdFromPath(window.location.pathname);

      return Promise.all([
        ...insertIf(boards.length > 0, () => [
          moveBoards({
            newParentId: selectedBoard?.id !== 'root' ? selectedBoard?.id || null : null,
            boards,
            library: selectedLibrary,
          }),
        ]),
        ...insertIf(clips.length > 0 && !!currentBoardId && !!selectedBoard, () => [
          moveAssetsToBoard({ clipsInfo: clips, board: selectedBoard!, trackLocation, parentBoardId: currentBoardId! }),
        ]),
      ]);
    },
    onSuccess: (_data, { onMove, selectedBoard, selectedLibrary }) => {
      const isRootBoard = selectedBoard?.id === 'root';

      onMove?.();

      showToast(
        <>
          {`Moved to `}
          {selectedBoard && (
            <ToastLink
              onClick={() => {
                /**
                 * When we move the board to the top-level, we need to redirect the
                 * user to the board that was moved to prevent a 404 error.
                 */
                goToBoardPage({ board: isRootBoard ? boards[0] : selectedBoard, trackLocation: 'moved-board-toast' });
              }}
            >
              {isRootBoard ? 'top-level' : selectedBoard.title}
            </ToastLink>
          )}
          {selectedLibrary && selectedLibrary.title}
        </>,
      );
    },
    onError: (error, { selectedBoard, selectedLibrary }) =>
      reportErrorToBugsnag({
        error,
        context: 'Failed to move a private board',
        metadata: { boards, clips, selectedBoard, selectedLibrary },
      }),
  });

  return { moveItems };
};
