import { CustomFields } from '@air/api';
import { useQuery } from '@tanstack/react-query';
import invariant from 'tiny-invariant';

import { getWorkspaceCustomFieldsKey } from '~/constants/react-query-keys';
import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';

export const useGetPrivateCustomFields = (enabled = true) => {
  const { currentWorkspace } = useCurrentWorkspace();
  const workspaceId = currentWorkspace?.id;
  const key = getWorkspaceCustomFieldsKey(workspaceId);

  return useQuery({
    queryKey: key,

    queryFn: () => {
      invariant(workspaceId, 'No workspace id');

      return CustomFields.listCustomFields({ workspaceId, options: { limit: 100 } });
    },
    enabled: !!workspaceId && enabled,
  });
};
