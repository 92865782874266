import { Bell as BellIcon, Check as CheckIcon } from '@air/next-icons';
import { useToasts } from '@air/provider-toast';
import { useFlags } from 'launchdarkly-react-client-sdk';
import Link from 'next/link';
import { useCallback } from 'react';

import { Routes } from '~/constants/routes';
import {
  saveTurnOnNotificationsPromptShown,
  wasTurnOnNotificationsPromptShown,
} from '~/hooks/turnOnEmailShareNotificationsToast/turnOnNotificationsPromptUtils';
import { useAccountContext } from '~/providers/AccountProvider';
import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';
import { useNotificationSettings } from '~/swr-hooks/account/useNotificationSettings';
import { useUpdateNotificationSettings } from '~/swr-hooks/account/useUpdateNotificationSettings';

export const useShowTurnOnShareLinkNotifications = () => {
  const { data: account } = useAccountContext();
  const { currentWorkspace } = useCurrentWorkspace();
  const { data: notificationSettings } = useNotificationSettings();
  const { updateNotificationSettings } = useUpdateNotificationSettings();
  const { newNotificationSettings } = useFlags();

  const { showToast, dismissAllToasts } = useToasts();

  const showTurnOnShareLinkNotifications = useCallback(
    ({ onManageClick }: { onManageClick: () => void }) => {
      if (!account || !currentWorkspace || !newNotificationSettings) {
        return;
      }

      const areSettingsAlreadyEnabled =
        !!notificationSettings?.emails.shareLinkViews && !!notificationSettings?.emails.shareLinkDownloads;

      // user already has setting enabled - no need to show toast
      if (areSettingsAlreadyEnabled) {
        return;
      }

      const wasPromptShown = wasTurnOnNotificationsPromptShown({
        email: account?.email,
        workspaceId: currentWorkspace?.id,
      });
      if (wasPromptShown) {
        return;
      }

      const showSuccessToast = () => {
        showToast(
          <div className="flex gap-2">
            You will now receive emails about new activity on share links you create{' '}
            <Link
              href={Routes.account.notificationSettings}
              onClick={() => onManageClick()}
              className="ml-2 text-14 font-semibold text-pigeon-25 hover:underline"
            >
              Manage
            </Link>
          </div>,
          {
            prefix: <CheckIcon className="h-4 w-4 text-parrot-400" />,
          },
        );
      };

      const turnOnNotifications = async () => {
        try {
          await updateNotificationSettings.mutateAsync({
            emails: {
              shareLinkViews: true,
              shareLinkDownloads: true,
            },
          });
          showSuccessToast();
        } catch (_error) {
          showToast(<>Something went wrong. Please try again later.</>, {
            color: 'red',
          });
        }
      };

      showToast(
        <div className="flex gap-2">
          Turn on email notifications for share links you create
          <button
            onClick={() => {
              dismissAllToasts();
              turnOnNotifications();
            }}
            className="text-14 font-semibold text-grey-5 hover:underline"
          >
            Turn on
          </button>
        </div>,
        {
          prefix: <BellIcon className="h-4 w-4" />,
          withCloseButton: true,
        },
      );

      saveTurnOnNotificationsPromptShown({
        email: account?.email,
        workspaceId: currentWorkspace?.id,
      });
    },
    [
      account,
      currentWorkspace,
      dismissAllToasts,
      newNotificationSettings,
      notificationSettings?.emails.shareLinkDownloads,
      notificationSettings?.emails.shareLinkViews,
      showToast,
      updateNotificationSettings,
    ],
  );

  return {
    showTurnOnShareLinkNotifications,
  };
};
