import * as RadixRadioGroup from '@radix-ui/react-radio-group';
import classNames from 'classnames';

export type RadioGroupProps = Omit<RadixRadioGroup.RadioGroupProps, 'asChild'>;

export const RadioGroup = ({ children, className, ...restOfProps }: RadioGroupProps) => {
  return (
    <RadixRadioGroup.Root className={classNames('flex flex-col gap-3', className)} {...restOfProps}>
      {children}
    </RadixRadioGroup.Root>
  );
};
