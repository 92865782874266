import { TriangleDown as TriangleDownIcon } from '@air/next-icons';
import classNames from 'classnames';
import type { ComponentProps, ReactNode } from 'react';

export type TreeHeaderProps = ComponentProps<'div'> & {
  adornmentLeft?: ReactNode;
  adornmentRight?: ReactNode;
  isExpanded?: boolean;
  onTitleClick: ComponentProps<'button'>['onClick'];
  showAdornmentLeft?: boolean;
  showAdornmentRight?: boolean;
  title: ReactNode;
};

export const TreeHeader = ({
  adornmentLeft,
  adornmentRight,
  className,
  isExpanded = false,
  onTitleClick,
  showAdornmentLeft,
  showAdornmentRight,
  title,
  ...restOfProps
}: TreeHeaderProps) => {
  return (
    <header
      className={classNames('group flex h-9 items-center gap-2', className)}
      data-testid={`TREE_HEADER-${title}`}
      {...restOfProps}
    >
      {adornmentLeft && (
        <div
          className={classNames(
            'flex shrink-0 gap-1',
            showAdornmentLeft ? 'pointer-events-auto opacity-100' : 'pointer-events-none opacity-0',
          )}
        >
          {adornmentLeft}
        </div>
      )}
      {title && (
        <button
          className="mr-auto flex h-6 select-none items-center rounded border-0 bg-transparent px-2 text-12 font-semibold text-grey-9 transition-colors hover:bg-grey-4"
          onClick={onTitleClick}
        >
          {title}
          <TriangleDownIcon
            className={classNames(
              'block h-4 w-4 opacity-0 transition-transform group-hover:opacity-100',
              isExpanded ? 'rotate-0' : '-rotate-90',
            )}
          />
        </button>
      )}

      {adornmentRight && (
        <div
          className={classNames(
            'flex shrink-0 gap-1',
            showAdornmentRight ? 'pointer-events-auto opacity-100' : 'pointer-events-none opacity-0',
          )}
        >
          {adornmentRight}
        </div>
      )}
    </header>
  );
};
