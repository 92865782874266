import { memo } from 'react';

import { PermissionToggleInfo } from '~/components/SharePrivateContentModal/shared/PermissionToggleInfo';
import { SharePrivateContentPermissionToggle } from '~/components/SharePrivateContentModal/shared/SharePrivateContentPermissionToggle';

interface EnableShareLinkDownloadToggleProps {
  enabled: boolean;
  isLoading: boolean;
  onToggle: (enabled: boolean) => void;
  infoText: string;
}

export const EnableShareLinkDownloadToggle = memo(
  ({ onToggle, enabled, isLoading, infoText }: EnableShareLinkDownloadToggleProps) => {
    return (
      <SharePrivateContentPermissionToggle
        id="canViewOriginal"
        label="Allow downloads"
        checked={enabled}
        isLoading={isLoading}
        onCheckedChange={onToggle}
      >
        <PermissionToggleInfo text={infoText} />
      </SharePrivateContentPermissionToggle>
    );
  },
);

EnableShareLinkDownloadToggle.displayName = 'EnableShareLinkDownloadToggle';
