import { Library } from '@air/api/types';
import { activeUploadStates, uploadArraySelector, UploadStatus } from '@air/redux-uploader';
import { createSelector } from 'reselect';

import { privateUploadMetadataSelector } from '~/store/privateUploadMetadata/selectors';
import { viewingFavoritesSelector } from '~/store/router/selectors';
import { uploadingPathToBoardIdSelector } from '~/store/uploadingPathToBoardId/selector';
import { getUploadBoardIdUtil } from '~/store/uploadingPathToBoardId/utils';

import { AirState } from '../types';

const centralizedBoardStateSelector = (state: AirState) => state.centralizedBoard;
const boardIdParamSelector = (_: AirState, boardId: string) => boardId;
const libraryIdSelector = (_: AirState, libraryId: Library['id']) => libraryId;

export const centralizedBoardSelector = createSelector(centralizedBoardStateSelector, (state) => state.board);

export const centralizedBoardAncestorsSelector = createSelector(
  centralizedBoardSelector,
  (board) => board?.ancestors ?? [],
);

export const isAncestorOfCentralizedBoardSelector = createSelector(
  centralizedBoardAncestorsSelector,
  boardIdParamSelector,
  (ancestors, boardId) => ancestors.some((a) => a.id === boardId),
);
export const isCentralizedBoardInCurrentLibrarySelector = createSelector(
  centralizedBoardSelector,
  libraryIdSelector,
  (centralizedBoard, libraryId) => centralizedBoard?.library?.id === libraryId,
);

export const centralizedBoardLibrary = createSelector(centralizedBoardSelector, (board) => board?.library);

const boardIdSelector = createSelector(centralizedBoardStateSelector, (state) => state?.id);
export const centralizedBoardExistsSelector = createSelector(centralizedBoardSelector, (board) => !!board);
export const centralizedBoardTitleSelector = createSelector(centralizedBoardStateSelector, (state) => state?.title);
export const centralizedBoardLibrarySelector = createSelector(centralizedBoardSelector, (board) => board?.library);
export const centralizedBoardLibraryIdSelector = createSelector(
  centralizedBoardSelector,
  (board) => board?.library?.id,
);

export const centralizedBoardHasCurrentUserSelector = createSelector(
  centralizedBoardSelector,
  (board) => board?.hasCurrentUser,
);
export const centralizedBoardDescriptionSelector = createSelector(
  centralizedBoardSelector,
  (board) => board?.description,
);

export const centralizedBoardWorkspaceIdSelector = createSelector(
  centralizedBoardSelector,
  (board) => board?.workspaceId,
);

/**
 * This selector is used to get currently uploading files for a board or all uploads if there is no boardId passed
 * Uploads are sorted by their ids to keep the order in a list when upload is finished
 * upload2.createdAt is the same for all uploads (they are created in the same moment)
 */
export const uploadsForCurrentBoardSelector = createSelector(
  [
    uploadArraySelector,
    uploadingPathToBoardIdSelector,
    boardIdSelector,
    viewingFavoritesSelector,
    privateUploadMetadataSelector,
  ],
  (uploads, pathToBoardId, boardId, viewingFavorites, uploadsMetadata) =>
    viewingFavorites
      ? []
      : uploads
          .filter((u) => {
            const uploadMetadata = uploadsMetadata[u.id];
            const uploadBoardId = getUploadBoardIdUtil({
              batchId: u.batchId,
              uploadMetadata,
              pathToBoardIdState: pathToBoardId,
            });
            const isInCurrentBoard = !boardId || boardId === uploadBoardId;
            const isActive = u.status !== UploadStatus.completed && activeUploadStates.includes(u.status);
            const isVersion = uploadMetadata.isVersion;
            return isInCurrentBoard && isActive && !isVersion;
          })
          .sort((upload1, upload2) =>
            upload1.id.toLowerCase() === upload2.id.toLowerCase()
              ? 0
              : upload1.id.toLowerCase() > upload2.id.toLowerCase()
              ? -1
              : 1,
          ),
);

// This is necessary for Cypress to mock methods, and must include all exported methods
export const CentralizedBoardSelectors = {
  boardId: boardIdSelector,
};
