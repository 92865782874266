import { Portal } from '@air/primitive-portal';
import { noop } from 'lodash';
import { createContext, memo, ReactNode, useContext, useMemo, useState } from 'react';

interface ActionBarProviderProps {
  children: ReactNode;
}

type ActionBarProviderContextValue = {
  setActionBar: (component: ReactNode) => void;
  setActionBarXOffset: (offset: number) => void;
  actionBar: ReactNode;
};

const defaultValue: ActionBarProviderContextValue = {
  setActionBar: noop,
  setActionBarXOffset: noop,
  actionBar: null,
};

const ActionBarContext = createContext(defaultValue);

export const ActionBarProvider = memo(({ children }: ActionBarProviderProps) => {
  const [actionBarOffset, setActionBarXOffset] = useState<number>(0);
  const [actionBar, setActionBar] = useState<ReactNode>(null);

  const value = useMemo(
    () => ({
      setActionBar,
      setActionBarXOffset,
      actionBar,
    }),
    [actionBar],
  );

  return (
    <ActionBarContext.Provider value={value}>
      {children}
      <Portal>
        <div
          className="pointer-events-none fixed bottom-0 right-0 m-0 flex flex-col items-center overflow-hidden px-0 py-3"
          style={{ width: `calc(100% - ${actionBarOffset}px)` }}
        >
          {actionBar}
        </div>
      </Portal>
    </ActionBarContext.Provider>
  );
});

ActionBarProvider.displayName = 'ActionBarProvider';

export function useActionBar() {
  const context = useContext(ActionBarContext);

  if (context === defaultValue) {
    throw 'useActionBar used outside of ActionBarProvider';
  }

  return context;
}
