import { AirActionTrackingLocation } from '@air/analytics';
import { createBoardRoute } from '@air/api/shared-utils';
import { Library } from '@air/api/types';
import { ConfirmationModal } from '@air/component-confirmation-modal';
import { Switch } from '@air/primitive-switch';
import classNames from 'classnames';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { intersection } from 'lodash';
import Router from 'next/router';
import pluralize from 'pluralize';
import { useCallback, useState } from 'react';

import { useCreateDeleteBoardTask } from '~/components/DeleteBoardTasks/hooks/useCreateDeleteBoardTask';
import { DeleteAssetsWarning } from '~/components/Modals/DeleteBoards/DeleteAssetsWarning';
import { useIsUploadingToSelectedBoard } from '~/components/Modals/DeleteBoards/util';
import { Routes } from '~/constants/routes';
import { DELETE_BOARDS_TOGGLE } from '~/constants/testIDs';
import { useBoardPermissionsCache } from '~/hooks/useBoardPermissionsCache';
import { useFetchObjectsPermissions } from '~/hooks/useFetchObjectsPermissions';
import { useShowSubscriptionExpiredModal } from '~/hooks/useShowSubscriptionExpiredModal';
import { centralizedBoardSelector } from '~/store/centralizedBoard/selectors';
import { getBoardIdFromPath } from '~/utils/PathUtils';
import { canDeleteAsset } from '~/utils/permissions/assetPermissions';
import { useAirStore } from '~/utils/ReduxUtils';

export interface DeleteBoardModalProps {
  boardIds: string[];
  onDelete?: () => void;
  trackLocation: AirActionTrackingLocation;
  libraryId?: Library['id'];
}

export const DeleteBoardModal = ({
  onClose,
  boardIds,
  onDelete,
  trackLocation,
  libraryId,
}: AirModalProps<DeleteBoardModalProps>) => {
  const { deletionRecoveryToggle: deletionRecoveryToggleFlag } = useFlags();
  const [removeClips, setRemoveClips] = useState(false);
  const { showingSubscriptionExpiredModal } = useShowSubscriptionExpiredModal({ onClose });

  const { createDeleteBoardTask } = useCreateDeleteBoardTask();
  const { getBoardPermissions } = useBoardPermissionsCache();

  const { isUploadingToBoard } = useIsUploadingToSelectedBoard(boardIds);
  const [isPending, setIsPending] = useState(false);
  const store = useAirStore();

  const handleCreateDeleteBoardTask = useCallback(async () => {
    try {
      setIsPending(true);
      await createDeleteBoardTask({
        boardIds,
        libraryId,
        removeClips: deletionRecoveryToggleFlag || removeClips,
        trackLocation,
      });

      const centralizedBoard = centralizedBoardSelector(store.getState());
      const centralizedBoardAncestorIds = centralizedBoard?.ancestors?.map(({ id }) => id) || [];

      const boardIdInUrl = getBoardIdFromPath(window.location.pathname);

      // Check if one of the deleted boards is a board the user is on
      if (boardIdInUrl && boardIds.includes(boardIdInUrl)) {
        // If the board deleted has a parent, send the user to the parent, else home
        Router.push(centralizedBoard?.parentId ? createBoardRoute(centralizedBoard.parentId) : Routes.media.all);
        // Redirect if the route is on a child of the deleted board
      } else if (centralizedBoardAncestorIds.length && intersection(centralizedBoardAncestorIds, boardIds).length) {
        Router.push(Routes.media.all);
      }

      onDelete?.();
      onClose();
    } catch (error) {
      console.error('Failed to create delete board task', error);
    }
    setIsPending(false);
  }, [
    boardIds,
    createDeleteBoardTask,
    deletionRecoveryToggleFlag,
    libraryId,
    onClose,
    onDelete,
    removeClips,
    store,
    trackLocation,
  ]);

  useFetchObjectsPermissions({
    objects: {
      boardIds,
    },
  });

  if (showingSubscriptionExpiredModal) {
    return null;
  }

  const canDeleteBoardsAssets = boardIds.every((boardId) => canDeleteAsset(getBoardPermissions(boardId)));

  return (
    <ConfirmationModal
      ctaPrimaryText="Delete"
      id="delete-board"
      isDangerous
      isDisabled={isUploadingToBoard || isPending}
      isLoading={isUploadingToBoard || isPending}
      isOpen
      onConfirm={handleCreateDeleteBoardTask}
      onDismiss={onClose}
      title={
        deletionRecoveryToggleFlag
          ? `Delete ${pluralize('board', boardIds.length, true)}?`
          : `Delete ${pluralize('board', boardIds.length, true)}`
      }
    >
      {isUploadingToBoard && (
        <p className="mb-4 font-medium text-red-9">
          Items are currently being uploaded to this board. The board cannot be deleted until this upload has completed.
        </p>
      )}
      {deletionRecoveryToggleFlag ? (
        <>
          <ul className="mt-2 list-inside list-disc">
            <li className="mb-2 text-14">
              {boardIds.length > 1
                ? 'Any sub-boards contained within these boards will also be deleted.'
                : 'Any sub-boards contained within this board will also be deleted.'}
            </li>
            <li className="mb-2 text-14">Assets that live only in this board will be deleted.</li>
          </ul>
        </>
      ) : (
        <>
          <p className="mb-10 text-grey-10" data-testid="text">
            You are about to delete <b>{pluralize('board', boardIds.length, true)}.</b>
            <ul className="mt-2 list-disc pl-6">
              <li className="mb-2 text-14">
                {removeClips
                  ? ` Assets inside the ${pluralize(
                      'board',
                      boardIds.length,
                    )} will be deleted unless they appear in other boards.`
                  : ` Assets inside the ${pluralize('board', boardIds.length)} will remain in your workspace.`}
              </li>
              <li className="mb-2 text-14">
                Comments inside the {pluralize('board', boardIds.length)} will be deleted.
              </li>
            </ul>
          </p>

          {canDeleteBoardsAssets && (
            <div className="mb-6 flex items-center justify-between">
              <div className={classNames('text-grey-12', boardIds.length === 1 ? 'mr-3' : 'mr-0')}>
                Delete assets inside {pluralize('this', boardIds.length)} {pluralize('board', boardIds.length)} from my
                workspace
              </div>

              <div className="flex items-center gap-3">
                <span className="text-12 font-semibold uppercase text-grey-10">{removeClips ? 'ON' : 'OFF'}</span>
                <Switch
                  checked={removeClips}
                  data-testid={DELETE_BOARDS_TOGGLE}
                  id="remove-clips"
                  onCheckedChange={(checked) => setRemoveClips(checked)}
                />
              </div>
            </div>
          )}
          {removeClips && <DeleteAssetsWarning />}
        </>
      )}
    </ConfirmationModal>
  );
};
