import type { Board } from '@air/api/types';
import { type ComponentProps, memo, useCallback, useState } from 'react';

import { BoardSelectBoardListItem } from '~/components/BoardSelect/BoardSelectBoardListItem';
import { BoardSelectLibraryListItem } from '~/components/BoardSelect/BoardSelectLibraryListItem';
import { BoardSelectList } from '~/components/BoardSelect/components/BoardSelectList';
import { BoardSelectListHeader } from '~/components/BoardSelect/components/BoardSelectListHeader';
import { BoardSelectListItem } from '~/components/BoardSelect/components/BoardSelectListItem';
import { BoardSelectSearch, BoardSelectSearchProps } from '~/components/BoardSelect/components/BoardSelectSearch';
import { GeneralLibrary } from '~/utils/librariesUtils';

export type BoardSelectLibrary = Required<Board>['library'];

export type BoardSelectProps = Pick<ComponentProps<'div'>, 'children'> & {
  boards: Board[];
  getBoardDisabledMessage?: (board: Board) => string | undefined;
  getLibraryDisabledMessage?: (library: BoardSelectLibrary) => string | undefined;
  hasLibraries?: boolean;
  initialBoard?: Pick<Board, 'ancestors' | 'id' | 'library' | 'title'>;
  isBoardDisabled?: (board: Board) => boolean;
  isBoardSelected?: (board: Board) => boolean;
  isLibraryDisabled?: (library: BoardSelectLibrary) => boolean;
  isLibrarySelected?: (library: BoardSelectLibrary) => boolean;
  libraries: BoardSelectLibrary[];
  onCreateBoard?: () => void;
  onCreateLibrary?: () => void;
  onSelectBoard?: (board: Pick<Board, 'id' | 'title' | 'ancestors' | 'thumbnails' | 'library'> | null) => void;
  onSelectLibrary?: (library: BoardSelectLibrary | undefined) => void;
  onSearchChange?: BoardSelectSearchProps['onChange'];
  search?: string;
  shouldShowSubBoards?: (id: Board['id']) => boolean;
};

export const BoardSelect = memo(
  ({
    boards,
    children,
    getBoardDisabledMessage,
    getLibraryDisabledMessage,
    hasLibraries,
    initialBoard,
    isBoardDisabled,
    isBoardSelected,
    isLibrarySelected,
    libraries,
    search,
    onCreateBoard,
    onCreateLibrary,
    onSearchChange,
    onSelectBoard,
    onSelectLibrary,
    shouldShowSubBoards,
  }: BoardSelectProps) => {
    /**
     * We use `useState` instead of `ref` to ensure the scroll element is always up to date.
     */
    const [scrollElement, setScrollElement] = useState<HTMLDivElement | null>();
    const [expanded, setExpanded] = useState<{
      libraries: Set<BoardSelectLibrary['id']>;
      boards: Set<Board['id']>;
    }>({
      libraries: initialBoard?.library ? new Set([initialBoard.library.id]) : new Set([GeneralLibrary.id]),
      boards: initialBoard ? new Set([initialBoard.id]) : new Set(),
    });

    const isBoardExpanded = useCallback((id: string) => expanded.boards.has(id), [expanded.boards]);

    const isLibraryExpanded = useCallback(
      (library: Pick<BoardSelectLibrary, 'id'>) => expanded.libraries.has(library.id),
      [expanded.libraries],
    );

    const onBoardOpenChange = useCallback(({ id, isOpen }: { id: Board['id']; isOpen?: boolean }) => {
      setExpanded((prev) => {
        const { boards } = prev;
        const newBoards = new Set(boards);

        if (isOpen) {
          newBoards.add(id);
        } else {
          newBoards.delete(id);
        }

        return {
          ...prev,
          boards: newBoards,
        };
      });
    }, []);

    const onLibraryOpenChange = useCallback(({ id, isOpen }: { id: BoardSelectLibrary['id']; isOpen?: boolean }) => {
      setExpanded((prev) => {
        const { libraries } = prev;
        const newLibraries = new Set(libraries);

        if (isOpen) {
          newLibraries.add(id);
        } else {
          newLibraries.delete(id);
        }

        return {
          ...prev,
          libraries: newLibraries,
        };
      });
    }, []);

    const boardsListEl = scrollElement ? (
      <BoardSelectList<Board>
        initialIndex={!hasLibraries && initialBoard ? boards.findIndex((b) => b.id === initialBoard.id) : undefined}
        items={boards}
        onCreate={onCreateBoard}
        renderListItem={({ data }) => (
          <BoardSelectBoardListItem
            board={data}
            getBoardDisabledMessage={getBoardDisabledMessage}
            isBoardDisabled={isBoardDisabled}
            isBoardExpanded={isBoardExpanded}
            isBoardSelected={isBoardSelected}
            onSelectBoard={onSelectBoard}
            onBoardOpenChange={onBoardOpenChange}
            shouldShowSubBoards={shouldShowSubBoards}
          />
        )}
        scrollElement={scrollElement}
        title="Boards"
      />
    ) : null;

    return (
      <div className="flex w-full flex-col gap-3">
        {!!onSearchChange && (
          <BoardSelectSearch
            onChange={(event) => {
              onSearchChange(event);
              /**
               * Scrolls to the top of the list when the search changes.
               */
              scrollElement?.scrollTo(0, 0);
            }}
          />
        )}

        <div
          className="flex h-[320px] flex-col overflow-y-auto rounded border border-grey-5 bg-grey-1 p-2 pt-4"
          ref={(ref) => setScrollElement(ref)}
        >
          <BoardSelectListHeader onCreate={onCreateBoard}>
            {hasLibraries ? 'Libraries' : 'Boards'}
          </BoardSelectListHeader>

          {!!scrollElement && (
            <>
              {!hasLibraries ? (
                boardsListEl
              ) : (
                <>
                  {!!boards.length && (
                    <BoardSelectListItem
                      isExpanded={isLibraryExpanded?.({ id: GeneralLibrary.id })}
                      id="general-library"
                      onSelect={() =>
                        onLibraryOpenChange({
                          id: GeneralLibrary.id,
                          isOpen: !isLibraryExpanded?.({ id: GeneralLibrary.id }),
                        })
                      }
                      onOpenChange={(isOpen) => onLibraryOpenChange({ id: GeneralLibrary.id, isOpen })}
                      prefix={
                        <div className="flex h-6 w-6 items-center justify-center rounded bg-grey-4">
                          <GeneralLibrary.Icon className="h-4 w-4 text-grey-10" />
                        </div>
                      }
                      title="General"
                    >
                      {boardsListEl}
                    </BoardSelectListItem>
                  )}
                  {!!libraries.length && (
                    <BoardSelectList<BoardSelectLibrary>
                      initialIndex={
                        initialBoard?.library && !search
                          ? libraries.findIndex((l) => l.id === initialBoard?.library?.id)
                          : undefined
                      }
                      items={libraries}
                      onCreate={onCreateLibrary}
                      renderListItem={({ data }) => (
                        <BoardSelectLibraryListItem
                          getBoardDisabledMessage={getBoardDisabledMessage}
                          getLibraryDisabledMessage={getLibraryDisabledMessage}
                          isLibraryExpanded={isLibraryExpanded}
                          library={data}
                          isBoardDisabled={isBoardDisabled}
                          isBoardExpanded={isBoardExpanded}
                          isBoardSelected={isBoardSelected}
                          isLibrarySelected={isLibrarySelected}
                          onBoardOpenChange={onBoardOpenChange}
                          onLibraryOpenChange={onLibraryOpenChange}
                          onSelectBoard={onSelectBoard}
                          onSelectLibrary={onSelectLibrary}
                          search={search}
                          shouldShowSubBoards={shouldShowSubBoards}
                        />
                      )}
                      scrollElement={scrollElement}
                      title="Libraries"
                    />
                  )}
                </>
              )}
            </>
          )}
          {children}
        </div>
      </div>
    );
  },
);

BoardSelect.displayName = 'BoardSelect';
