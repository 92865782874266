import { Boards, GetBoardResponse } from '@air/api';
import { Board } from '@air/api/types';
import { useQueryClient } from '@tanstack/react-query';
import Router from 'next/router';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { getPageBoardKey } from '~/constants/react-query-keys';
import { createBoardRoute } from '~/constants/routes';
import { setCentralizedBoardAction, setCentralizedBoardIdAndTitleAction } from '~/store/centralizedBoard/actions';
import { LocationBoard } from '~/store/router/types';
import { reportErrorToBugsnag } from '~/utils/ErrorUtils';

export function useGoToBoardPage() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const goToBoardPage = useCallback(
    async ({
      method,
      board,
    }: {
      board: LocationBoard | Board;
      trackLocation?: string;
      method?: 'push' | 'replace';
    }) => {
      Router.ready(async () => {
        const boardRoute = createBoardRoute(board.id, board.title);

        if (Router.asPath.includes(board.id)) return;

        const cachedBoard = queryClient.getQueryData<GetBoardResponse>(getPageBoardKey(board.id));

        if (cachedBoard) {
          dispatch(setCentralizedBoardAction({ board: cachedBoard }));
        } else {
          dispatch(setCentralizedBoardIdAndTitleAction({ board }));
        }

        if (method === 'replace') {
          Router.replace(boardRoute);
        } else {
          Router.push(boardRoute);
        }

        try {
          await Boards.viewBoard({ boardId: board.id });
        } catch (error) {
          reportErrorToBugsnag({
            error,
            context: 'Failed to trigger a view for a board',
            metadata: { data: { boardId: board.id } },
          });
        }
      });
    },
    [dispatch, queryClient],
  );

  return {
    goToBoardPage,
  };
}
