import { Boards } from '@air/api';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';
import { getFollowingBoardsKey } from '~/swr-hooks/followedBoards/useFollowingBoards';
import { reportErrorToBugsnag } from '~/utils/ErrorUtils';

import { isBoardFollowerKey } from './useIsFollowingBoard';

export const useFollowBoard = () => {
  const queryClient = useQueryClient();
  const { currentWorkspace } = useCurrentWorkspace();
  const workspaceId = currentWorkspace?.id;

  const followBoard = useMutation({
    mutationFn: (boardId: string) => {
      if (!workspaceId) {
        throw new Error('No workspaceId found');
      }

      return Boards.follow({ boardId, workspaceId });
    },
    onMutate: (boardId) => {
      queryClient.setQueryData<boolean | undefined>(isBoardFollowerKey(boardId), true);
    },
    onSuccess: (_data, boardId) => {
      queryClient.invalidateQueries({ queryKey: isBoardFollowerKey(boardId) });
      queryClient.invalidateQueries({ queryKey: getFollowingBoardsKey(workspaceId) });
    },
    onError: (error, boardId) => {
      queryClient.setQueryData<boolean | undefined>(isBoardFollowerKey(boardId), false);
      reportErrorToBugsnag({ error, context: 'Failed to follow a board', metadata: { data: { boardId } } });
    },
  });

  return {
    followBoard,
  };
};
