import { memo } from 'react';

import { SharePrivateContentPermissionToggle } from '~/components/SharePrivateContentModal/shared/SharePrivateContentPermissionToggle';
import { SharePermissionSection } from '~/components/SharePrivateContentModal/SharePrivateContentSettings/SharePermissionSection';
import { SharePermissionSectionContentProps } from '~/components/SharePrivateContentModal/SharePrivateContentSettings/types';

export const ShareCommentsPermissions = memo(
  ({ onPermissionUpdated, shortIdInfo, isLoading }: SharePermissionSectionContentProps) => {
    return (
      <SharePermissionSection title="Comments">
        <SharePrivateContentPermissionToggle
          label="Show comments"
          id="canViewDiscussions"
          isLoading={isLoading}
          checked={shortIdInfo.permissions.asset.canViewDiscussions}
          onCheckedChange={(checked) =>
            onPermissionUpdated({
              asset: {
                canViewDiscussions: checked,
                canManageDiscussions: checked ? shortIdInfo.permissions.asset.canManageDiscussions : false,
              },
            })
          }
        />
        <SharePrivateContentPermissionToggle
          label="Allow commenting"
          id="canManageDiscussions"
          isLoading={isLoading}
          checked={shortIdInfo.permissions.asset.canManageDiscussions}
          disabled={!shortIdInfo.permissions.asset.canViewDiscussions}
          onCheckedChange={(checked) =>
            onPermissionUpdated({
              asset: {
                canManageDiscussions: checked,
              },
            })
          }
        />
      </SharePermissionSection>
    );
  },
);

ShareCommentsPermissions.displayName = 'ShareCommentsPermissions';
