import { Board, Clip } from '@air/api/types';
import { constant, noop } from 'lodash';
import { createContext, memo, PropsWithChildren, useCallback, useContext, useEffect, useMemo, useRef } from 'react';

import {
  DesktopAppWebSocket,
  DesktopAppWebSocketSubscription,
} from '~/classes/desktopAppWebsocket/DesktopAppWebSocket';
import { Routes } from '~/constants/routes';
import { QueryParamNames } from '~/constants/search';
import { NEW_TAB } from '~/constants/urls';
import { isDevOrTestStage } from '~/swr-hooks/utils';
import { reportErrorToBugsnag } from '~/utils/ErrorUtils';

export interface DesktopAppConnectionProviderValue {
  isConnected: () => boolean;
  openClipInFinder: (clip: Pick<Clip, 'id' | 'assetId'>, boardId?: Board['id']) => void;
  openBoardInFinder: (boardId: Board['id']) => void;
}

const defaultValue: DesktopAppConnectionProviderValue = {
  isConnected: constant(false),
  openClipInFinder: noop,
  openBoardInFinder: noop,
};

const DesktopAppConnectionContext = createContext<DesktopAppConnectionProviderValue>(defaultValue);

export const DesktopAppConnectionProvider = memo(({ children }: PropsWithChildren<object>) => {
  const isAppConnected = useRef(false);

  const isConnected = useCallback(() => isAppConnected.current, []);

  const socketConnectionSubscription = useMemo<DesktopAppWebSocketSubscription>(
    () => ({
      onConnected: () => {
        isAppConnected.current = true;
      },
      onDisconnected: () => {
        isAppConnected.current = false;
      },
      onMessage: noop,
    }),
    [],
  );

  const openClipInFinder = useCallback<DesktopAppConnectionProviderValue['openClipInFinder']>(
    (clip, boardId) => {
      if (isAppConnected.current) {
        DesktopAppWebSocket.sendMessage({
          type: 'open-asset',
          data: {
            assetId: clip.id,
            boardId,
            asset: { id: clip.assetId },
          },
        });
      } else {
        DesktopAppWebSocket.connect(socketConnectionSubscription);
        const searchParams = new URLSearchParams();
        searchParams.set(QueryParamNames.assetId, clip.assetId);
        searchParams.set(QueryParamNames.clipId, clip.id);
        if (!!boardId) {
          searchParams.set(QueryParamNames.boardId, boardId);
        }
        const url = `${window.location.origin}/${Routes.desktop.openDesktopFile}?${searchParams.toString()}`;
        window.open(url, NEW_TAB);
      }
    },
    [socketConnectionSubscription],
  );

  const openBoardInFinder = useCallback<DesktopAppConnectionProviderValue['openBoardInFinder']>(
    (boardId) => {
      if (isAppConnected.current) {
        DesktopAppWebSocket.sendMessage({
          type: 'open-board',
          data: {
            boardId,
          },
        });
      } else {
        DesktopAppWebSocket.connect(socketConnectionSubscription);
        const searchParams = new URLSearchParams();
        searchParams.set(QueryParamNames.boardId, boardId);
        const url = `${window.location.origin}/${Routes.desktop.openDesktopFile}?${searchParams.toString()}`;
        window.open(url, NEW_TAB);
      }
    },
    [socketConnectionSubscription],
  );

  useEffect(() => {
    DesktopAppWebSocket.connect(socketConnectionSubscription);
  }, [socketConnectionSubscription]);

  const value = useMemo<DesktopAppConnectionProviderValue>(
    () => ({
      isConnected,
      openClipInFinder,
      openBoardInFinder,
    }),
    [isConnected, openBoardInFinder, openClipInFinder],
  );

  return <DesktopAppConnectionContext.Provider value={value}>{children}</DesktopAppConnectionContext.Provider>;
});

DesktopAppConnectionProvider.displayName = 'DesktopAppConnectionProvider';

export const useDesktopAppConnectionContext = () => {
  const context = useContext(DesktopAppConnectionContext);

  if (context === defaultValue) {
    const error = 'useDesktopAppConnectionContext used outside of DesktopAppConnectionProvider';
    if (isDevOrTestStage()) {
      throw error;
    } else {
      reportErrorToBugsnag({
        error,
        context: 'Invalid usage of DesktopAppConnectionContext',
      });
    }
  }

  return context;
};
