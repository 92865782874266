import { Tasks } from '@air/api';
import { useCallback } from 'react';

import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';
import { GetBaseMetadata, ZippingTask } from '~/store/tasks/types';
import { useCreateTask } from '~/utils/taskUtils/useCreateTask';

export type CreatePrivateZippingTaskParams = GetBaseMetadata<ZippingTask>;

export const useCreatePrivateZippingTask = () => {
  const { createTask } = useCreateTask();
  const { currentWorkspace } = useCurrentWorkspace();

  const createPrivateZippingTask = useCallback(
    async ({ boardIds = [], clipIds = [] }: CreatePrivateZippingTaskParams) => {
      const workspaceId = currentWorkspace?.id;
      if (!workspaceId) {
        throw new Error('No workspace id');
      }
      return createTask({
        task: {
          metadata: {
            boardIds,
            clipIds,
            secondsRemaining: undefined,
          },
          type: 'ZippingForDownload',
          workspaceId,
        },
        createTask: () =>
          Tasks.createTask({ workspaceId, task: { type: 'ContentExporter', args: { boardIds, clipIds } } }),
      });
    },
    [createTask, currentWorkspace?.id],
  );

  return {
    createPrivateZippingTask,
  };
};
