import {
  BillingCyclePricing,
  CurrentPlanPricing,
  PricingModel,
  Subscription,
  SubscriptionBillingCycle,
  SubscriptionPlan,
} from '@air/api/types';

import { subscriptionPlanMap } from '~/utils/SubscriptionUtils';

import { enterpriseOptions } from '../EnterprisePlanModal';

type priceMap = {
  [key in SubscriptionBillingCycle]: number;
};

export interface PlanInfoType {
  id: SubscriptionPlan;
  stripePlanIds: {
    [key in SubscriptionBillingCycle]?: string;
  };
  tagline: string;
  prices: priceMap;
  priceDetail?: string;
  customPriceDescription?: string;
  optionsListHeader: string;
  options: string[];
  showRightBorder?: boolean;
  isCurrentPlan: boolean;
}

const getPlanTextMap = (
  pricingModel: PricingModel,
  fixedPricingFlag?: boolean,
): {
  [key in string]: { tagline: string; priceDetail?: string; customPriceDescription?: string; options: string[] };
} => {
  return {
    basic: {
      tagline: 'For individuals and small projects',
      priceDetail: 'Forever',
      options: [
        '5 GB per workspace',
        '2 members',
        '50 boards',
        'GDrive, Dropbox & Box import',
        'Commenting and notifications',
        'Support for all file types',
        'PDF previews',
      ],
    },
    plus: {
      tagline: 'Perfect for small teams with simple workflows',
      priceDetail: pricingModel === 'fixed' ? 'per month' : 'per member per month',
      options: [
        ...(fixedPricingFlag
          ? ['300 GB per workspace', '5 members', '250 boards']
          : ['200 GB per workspace', 'Unlimited members (pay per seat)']),
        'GDrive, Dropbox & Box import',
        'Commenting and notifications',
        'Support for all file types',
        'PDF previews',
      ],
    },
    pro: {
      tagline: 'For small teams working with external partners',
      priceDetail: pricingModel === 'fixed' ? 'per month' : 'per member per month',
      options: [
        ...(fixedPricingFlag
          ? ['3TB per workspace', 'Unlimited members & guests', '1000 boards']
          : ['2TB per workspace']),
        'Passcode-protected links',
        'Guest members',
        'Comment-only members',
      ],
    },
    enterprise: {
      tagline: 'For medium to large teams with 20+ members',
      customPriceDescription: `Let's chat!`,
      options: enterpriseOptions,
    },
  };
};

const getStripePlanIds = (billingCycles?: BillingCyclePricing[]) => {
  return {
    annual: billingCycles?.find(({ id }) => id === 'annual')?.stripeId,
    monthly: billingCycles?.find(({ id }) => id === 'monthly')?.stripeId,
    quarter: billingCycles?.find(({ id }) => id === 'quarter')?.stripeId,
    semiAnnual: billingCycles?.find(({ id }) => id === 'semiAnnual')?.stripeId,
  };
};

export const planPricingToPlanInfo = (
  plan: CurrentPlanPricing,
  currentPlan?: Subscription['plan'],
  fixedPricingFlag?: boolean,
): PlanInfoType => {
  const { id, billingCycles, isCurrentPlan, pricingModel } = plan;

  const prices: priceMap = { annual: 0, monthly: 0, quarter: 0, semiAnnual: 0 };
  const annualPrice = billingCycles?.find(({ id }) => id === 'annual');
  const monthly = billingCycles?.find(({ id }) => id === 'monthly');
  if (annualPrice) {
    prices.annual = Math.round(annualPrice.planPrice / 12);
  }
  if (monthly) {
    prices.monthly = monthly.planPrice;
  }

  const stripePlanIds = getStripePlanIds(billingCycles);
  const planTextMap = getPlanTextMap(pricingModel, fixedPricingFlag);

  const isPro = id === 'pro';
  const isEnterprise = id === 'enterprise';

  return {
    id,
    stripePlanIds,
    tagline: planTextMap[id].tagline,
    prices: prices,
    priceDetail: planTextMap[id].priceDetail,
    customPriceDescription: planTextMap[id].customPriceDescription,
    isCurrentPlan: currentPlan === 'enterprise' && isEnterprise ? true : isCurrentPlan,
    showRightBorder: id !== 'enterprise',
    optionsListHeader: isPro
      ? 'Everything in Plus, and:'
      : isEnterprise
      ? 'Everything in Pro, and:'
      : `${subscriptionPlanMap[id]} workspaces include:`,
    options: planTextMap[id].options,
  };
};
