import { FormikField } from '@air/formik-field';
import { Input } from '@air/primitive-input';
import { useField } from 'formik';

import { EXPORT_IMAGES_SUFFIX_INPUT } from '~/constants/testIDs';

import { ExportImagesFormData } from '../../../ExportImagesModal';

export const PLACEHOLDER_IMAGE_BASE_NAME = 'IMAGE-O1';

interface ImageSuffixFormFieldProps {
  setIsEditing: (isEditing: boolean) => void;
}

export const ImageSuffixFormField = ({ setIsEditing }: ImageSuffixFormFieldProps) => {
  const [{ value: suffix }] = useField<ExportImagesFormData['suffix']>('suffix');
  const [{ value: format }] = useField<ExportImagesFormData['ext']>('ext');
  const [{ value: fileName }] = useField<ExportImagesFormData['fileName']>('fileName');

  return (
    <div className="flex grow flex-col">
      <FormikField
        label="Suffix"
        name="suffix"
        id="suffix"
        component={
          <Input
            onFocus={() => setIsEditing(true)}
            onBlur={() => setIsEditing(false)}
            className="w-full"
            size="extra-large"
            type="text"
            data-testid={EXPORT_IMAGES_SUFFIX_INPUT}
          />
        }
      />
      <div className="mt-2 break-all text-12 text-grey-10">
        Preview:&nbsp;{fileName}
        {suffix}.{format}
      </div>
    </div>
  );
};
