import { Router } from 'next/router';
import { useEffect } from 'react';

export const useOnLocationChange = (callback: (asPath: string) => void) => {
  useEffect(() => {
    Router.events.on('routeChangeComplete', callback);

    return () => Router.events.off('routeChangeComplete', callback);
  }, [callback]);
};
