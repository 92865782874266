import Router from 'next/router';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { QueryParamNames } from '~/constants/search';
import { isFlattenedViewSelector } from '~/store/router/selectors';
import { getPathWithoutQuery, pushWithExistingQuery } from '~/utils/PathUtils';

export const useIsFlattenedView = () => {
  const isFlattenedView = useSelector(isFlattenedViewSelector);

  const setIsFlattenedView = useCallback(
    (isFlattened: boolean) => {
      const isFlatString = isFlattened ? 'true' : 'false';
      if (isFlatString === isFlattenedView) {
        return;
      }
      return isFlattened
        ? pushWithExistingQuery({
            path: getPathWithoutQuery(Router.asPath),
            newQuery: { [QueryParamNames.isFlattenedView]: true },
          })
        : pushWithExistingQuery({
            path: getPathWithoutQuery(Router.asPath),
            newQuery: { [QueryParamNames.isFlattenedView]: null },
          });
    },
    [isFlattenedView],
  );

  return {
    isFlattenedView: isFlattenedView === 'true',
    setIsFlattenedView,
  };
};
