import { ReadyState } from '~/types/sockets';

import { attachWsListeners } from './attach-ws-listeners';
import { sharedWebSocket } from './shared/constants';
import { CreateOrJoinArgs } from './shared/typings';

export const createSocket = async ({
  webSocketRef,
  url,
  setReadyState,
  reconnect,
  reconnectCount,
  messageQueue,
}: CreateOrJoinArgs) => {
  if (sharedWebSocket.ws === null) {
    sharedWebSocket.ws = new WebSocket(url);
    webSocketRef.current = sharedWebSocket.ws;
    setReadyState(ReadyState.CONNECTING);

    attachWsListeners({
      webSocketInstance: sharedWebSocket.ws,
      setReadyState,
      reconnect,
      reconnectCount,
      messageQueue,
    });
  } else {
    webSocketRef.current = sharedWebSocket.ws;
    setReadyState(sharedWebSocket.ws.readyState);
  }
};
