import { ClipsListResponse } from '@air/api';
import { Asset, Board, Clip, ClipAndBoardListItem, ListResponse, SortDirection, SubnavBoardSort } from '@air/api/types';
import { InfiniteData } from '@tanstack/react-query';
import produce from 'immer';
import { chunk, constant } from 'lodash';

import { boardPositionsComparator, getUpdatedBoard } from '~/utils/BoardUtils';
import { getOrderedBoards } from '~/utils/mutateUtils/getOrderedBoards';
import { isClipTableItem } from '~/utils/tableViewDataUtils';

export type UpdatedBoardBase = Pick<Board, 'id'> & Partial<Board>;
export type UpdatedBoard = UpdatedBoardBase & Pick<Board, 'parentId' | 'hasCurrentUser' | 'workspaceId'>;

export const defaultBoardsState: InfiniteData<ListResponse<Board>> = {
  pages: [
    {
      data: [],
      total: 0,
      pagination: {
        hasMore: false,
        cursor: null,
      },
    },
  ],
  pageParams: [],
};

export const getUpdatedBoards =
  (updatedBoard: UpdatedBoardBase, boardSort: SubnavBoardSort = { name: '', direction: SortDirection.desc }) =>
  (data?: InfiniteData<ListResponse<Board>>): InfiniteData<ListResponse<Board>> | undefined => {
    if (!data) return;

    return produce(data, (draft) => {
      data.pages.forEach(() => {
        const flattenedBoards = draft.pages.flatMap((b) => b.data);
        const updatedBoardIndex = flattenedBoards.findIndex((b) => b.id === updatedBoard.id);

        if (updatedBoardIndex === -1) return;

        flattenedBoards[updatedBoardIndex] = getUpdatedBoard({
          newBoard: updatedBoard,
          oldBoard: flattenedBoards[updatedBoardIndex],
        });

        const { orderedBoards } = getOrderedBoards(flattenedBoards, boardSort);
        const chunkedBoards = chunk(orderedBoards, Math.ceil(orderedBoards.length / draft?.pages.length));

        chunkedBoards.forEach((chunk, index) => {
          draft.pages[index].data = chunk;
        });
      });
    });
  };

export const getRearrangedBoards =
  (rearrangedBoards: Board[]) =>
  (data?: InfiniteData<ListResponse<Board>>): InfiniteData<ListResponse<Board>> | undefined => {
    if (!data) return;

    return produce(data, (draft) => {
      draft.pages.forEach(() => {
        const rearrangedBoardsIds = rearrangedBoards.map(({ id }) => id);
        // flatten data array and filter boards which has been rearranged - we will add them manually
        const flattenedBoards = draft.pages.flatMap((b) =>
          b.data.filter((board) => !rearrangedBoardsIds.includes(board.id)),
        );
        flattenedBoards.push(...rearrangedBoards);

        const orderedBoards = flattenedBoards.sort(boardPositionsComparator);
        const chunkedBoards = chunk(orderedBoards, Math.ceil(orderedBoards.length / draft?.pages.length));

        chunkedBoards.forEach((chunk, index) => {
          draft.pages[index].data = chunk;
        });
      });
    });
  };

export type GetGalleryDataWithoutIdsParams = {
  data?: ClipsListResponse<Clip>[];
  removeIf?: (clip: Clip) => boolean;
} & (
  | {
      clipIds: Clip['id'][];
      assetIds?: never;
    }
  | {
      clipIds?: never;
      assetIds: Asset['id'][];
    }
);

export const getGalleryDataWithoutIds = ({
  assetIds,
  clipIds,
  data,
  removeIf = constant(false),
}: GetGalleryDataWithoutIdsParams) => {
  let totalRemoved = 0;

  const newData = data?.reduce((acc, curr) => {
    const newClips = curr.data.clips.filter((item) => {
      if (clipIds?.includes(item.id) || assetIds?.includes(item.assetId) || removeIf(item)) {
        return false;
      }
      return true;
    });
    totalRemoved += curr.data.clips.length - newClips.length;

    acc.push({
      ...curr,
      data: {
        ...curr.data,
        clips: newClips,
      },
    });
    return acc;
  }, [] as ClipsListResponse<Clip>[]);

  return produce(newData, (draft) => {
    draft?.forEach((data) => (data.data.total = data.data.total - totalRemoved));
  });
};

export type GetTableDataWithoutIdsParams = {
  data?: ListResponse<ClipAndBoardListItem>[];
  removeIf?: (clip: Clip) => boolean;
} & (
  | {
      clipIds: Clip['id'][];
      assetIds?: never;
    }
  | {
      clipIds?: never;
      assetIds: Asset['id'][];
    }
);

export const getTableDataWithoutIds = ({
  assetIds,
  clipIds,
  data,
  removeIf = constant(false),
}: GetTableDataWithoutIdsParams) => {
  let totalRemoved = 0;

  const newData = data?.reduce((acc, curr) => {
    const newClips = curr.data.filter((item) => {
      if (item.type === 'board') return true;

      const clipData = item.data as Clip;

      if (clipIds?.includes(clipData.id) || assetIds?.includes(clipData.assetId) || removeIf?.(clipData)) {
        return false;
      }

      return true;
    });

    totalRemoved += curr.data.length - newClips.length;

    acc.push({
      ...curr,
      data: newClips,
    });

    return acc;
  }, [] as ListResponse<ClipAndBoardListItem>[]);

  return produce(newData, (draft) => {
    draft?.forEach((data) => (data.total = data.total - totalRemoved));
  });
};

/**
 * Use this function to override data in old clip. It overrides all fields with newClip data except customFields (taken from old version) and assetVersionCount, which is set to newVersion.version
 */
export const standardNewVersionOverride = (oldClip: Clip, newClip: Clip) => ({
  ...newClip,
  customFields: oldClip.customFields,
  assetVersionCount: newClip.version,
});

export const updateVersionInTableData = (data: ListResponse<ClipAndBoardListItem>[], newVersion: Clip) =>
  produce(data, (draft) => {
    draft.forEach((data) => {
      const itemIndex = data.data.findIndex((item) => {
        if (isClipTableItem(item)) {
          return item.data.assetId === newVersion.assetId;
        }
      });

      if (itemIndex !== -1) {
        const oldData = data.data[itemIndex].data as Clip;
        data.data[itemIndex].data = standardNewVersionOverride(oldData, newVersion);
      }
    });
  });
