import { ShortUrlResponse } from '@air/api/types';
import { memo } from 'react';

import { useUpdateShareBoardLink } from '~/components/SharePrivateBoardModal/hooks/useUpdateShareBoardLink';
import { SharePrivateContentSettings } from '~/components/SharePrivateContentModal/SharePrivateContentSettings/SharePrivateContentSettings';

interface SharePrivateBoardSettingsProps {
  boardId: string;
  shortIdInfo: ShortUrlResponse;
}

export const SharePrivateBoardSettings = memo(({ shortIdInfo, boardId }: SharePrivateBoardSettingsProps) => {
  const { onShortUrlUpdate, isLoading } = useUpdateShareBoardLink({ boardId, shortIdInfo });

  return (
    <SharePrivateContentSettings shortIdInfo={shortIdInfo} onShortUrlUpdate={onShortUrlUpdate} isLoading={isLoading} />
  );
});
SharePrivateBoardSettings.displayName = 'SharePrivateBoardSettings';
