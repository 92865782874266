import { type ShortUrlResponse } from '@air/api/types';
import { EllipsisVertical } from '@air/next-icons';
import { DropdownMenu, type DropdownMenuOption, renderDropdownItems } from '@air/primitive-dropdown-menu';
import { IconButton } from '@air/primitive-icon-button';
import { useMemo } from 'react';

import { SharePrivateContentLinkFooter } from '~/components/SharePrivateContentModal/SharePrivateContentLinkFooter';
import { PRIVATE_SHARE_LINK } from '~/constants/testIDs';
import { NEW_TAB } from '~/constants/urls';

interface SharePrivateContentLinkProps {
  createdAt?: ShortUrlResponse['createdAt'];
  createdBy?: ShortUrlResponse['createdBy'];
  shareUrl: string;
  menuActions?: DropdownMenuOption[];
}

export const SharePrivateContentLink = ({
  createdAt,
  createdBy,
  shareUrl,
  menuActions = [],
}: SharePrivateContentLinkProps) => {
  const actionsToShow = useMemo(() => {
    const actions: DropdownMenuOption[] = [
      {
        id: 'preview',
        label: 'Preview',
        onSelect: () => window.open(shareUrl, NEW_TAB),
        type: 'item',
      },
    ];

    actions.push(...menuActions);

    return actions;
  }, [menuActions, shareUrl]);

  return (
    <div className="flex h-12 flex-auto items-center justify-between rounded border border-solid border-grey-5 bg-grey-3 px-3">
      <p className="select-all truncate text-16 text-grey-12" data-testid={PRIVATE_SHARE_LINK}>
        {shareUrl}
      </p>
      {menuActions && (
        <DropdownMenu
          trigger={
            <IconButton className="-mr-0.5" icon={EllipsisVertical} color="grey" appearance="ghost" label="Open menu" />
          }
        >
          {renderDropdownItems({ options: actionsToShow })}
          {!!createdAt && !!createdBy && <SharePrivateContentLinkFooter createdAt={createdAt} createdBy={createdBy} />}
        </DropdownMenu>
      )}
    </div>
  );
};
