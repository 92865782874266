import { Board, Library } from '@air/api/types';
import { useCallback, useState } from 'react';

import { BoardSearchOption } from '../PrivateBoardSearch';

export type BoardSearchLibrary = Board['library'];

type UseSingleBoardSearchStateOptions = {
  initialBoard?: BoardSearchOption;
  initialLibrary?: BoardSearchLibrary;
};

export const useSingleBoardSearchState = ({ initialBoard, initialLibrary }: UseSingleBoardSearchStateOptions = {}) => {
  const [selectedBoard, setSelectedBoard] = useState<BoardSearchOption | null>(initialBoard || null);
  const [selectedLibrary, setSelectedLibrary] = useState<BoardSearchLibrary | undefined>(initialLibrary);

  const isBoardSelected = useCallback(
    (board: Pick<Board, 'id'>) => !!selectedBoard && board.id === selectedBoard.id,
    [selectedBoard],
  );

  const isLibrarySelected = useCallback(
    (library: Pick<Library, 'id'>) => library.id === selectedLibrary?.id,
    [selectedLibrary?.id],
  );

  const onSelectBoard = useCallback(
    (board: BoardSearchOption | null) => {
      setSelectedBoard(board);
      setSelectedLibrary(undefined);
    },
    [setSelectedBoard, setSelectedLibrary],
  );
  const onSelectLibrary = useCallback(
    (library: BoardSearchLibrary) => {
      setSelectedBoard(null);
      setSelectedLibrary((current) => (current?.id === library?.id ? undefined : library));
    },
    [setSelectedBoard, setSelectedLibrary],
  );
  const shouldShowSubBoards = useCallback(
    (boardId: Board['id']) => !!selectedBoard?.ancestors?.map((b) => b.id).includes(boardId),
    [selectedBoard],
  );

  const shouldShowLibraryBoards = useCallback(
    (libraryId: Library['id']) => !!(selectedBoard?.library?.id === libraryId || selectedLibrary?.id === libraryId),
    [selectedBoard, selectedLibrary],
  );

  return {
    selectedBoard,
    selectedLibrary,
    isBoardSelected,
    isLibrarySelected,
    onSelectBoard,
    onSelectLibrary,
    shouldShowSubBoards,
    shouldShowLibraryBoards,
  };
};
