import { useTrackAddedShareLinkExpirationDate } from '@air/analytics';
import { ShortUrlResponse } from '@air/api/types';
import { Button } from '@air/primitive-button';
import { DateInput } from '@air/primitive-date-input';
import { Switch } from '@air/primitive-switch';
import classNames from 'classnames';
import { endOfDay, isBefore, startOfToday } from 'date-fns';
import { FormEvent, useCallback, useEffect, useMemo, useState } from 'react';

import { ShareUrlResponseWithSource } from '~/components/SharePrivateClipModal/types';
import { UpsellButton } from '~/components/UpsellButton';
import {
  EXPIRATION_DATE_INPUT,
  EXPIRATION_DATE_LABEL,
  EXPIRATION_DATE_SUBMIT_BUTTON,
  EXPIRATION_DATE_TOGGLE,
} from '~/constants/testIDs';
import { useSubscriptionContext } from '~/providers/SubscriptionProvider';
import { parseAirDateToISO } from '~/utils/DateUtils';

export interface SharePrivateContentExpirationDateSettingProps {
  shortIdInfo?: ShortUrlResponse;
  onChangeExpirationDate: (expirationDate: string | null) => Promise<ShareUrlResponseWithSource>;
}

export const SharePrivateContentExpirationDateSetting = ({
  shortIdInfo,
  onChangeExpirationDate,
}: SharePrivateContentExpirationDateSettingProps) => {
  const [hasExpirationDate, setHasExpirationDate] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const expirationDate = useMemo(
    () => (shortIdInfo?.expirationDate ? parseAirDateToISO(shortIdInfo?.expirationDate) : undefined),
    [shortIdInfo?.expirationDate],
  );
  const [dateValue, setDateValue] = useState<Date | undefined>(expirationDate);
  const { data: memberSubscription } = useSubscriptionContext();
  const isOnProPlan = !!memberSubscription?.isOnProPlan;
  const shortId = useMemo(() => shortIdInfo?.id, [shortIdInfo?.id]);
  const [errorText, setErrorText] = useState('');
  const { trackAddedShareLinkExpirationDate } = useTrackAddedShareLinkExpirationDate();

  useEffect(() => {
    if (!!expirationDate) {
      setDateValue(expirationDate);
    }
  }, [expirationDate]);

  useEffect(() => {
    setHasExpirationDate(!!expirationDate);
  }, [setHasExpirationDate, expirationDate]);

  const onSave = useCallback(
    async (e: FormEvent) => {
      e.preventDefault();
      if (!shortId) return;
      try {
        setIsSubmitting(true);
        trackAddedShareLinkExpirationDate({ shortId });
        await onChangeExpirationDate(dateValue?.toISOString() || null);
        setIsSaved(true);
        setIsSubmitting(false);
      } catch (_error) {
        setIsSubmitting(false);
      }
    },
    [shortId, dateValue, onChangeExpirationDate, trackAddedShareLinkExpirationDate],
  );

  const toggleExpirationDate = async () => {
    const nextHasExpirationDate = !hasExpirationDate;
    if (!!expirationDate && !nextHasExpirationDate && shortId) {
      await onChangeExpirationDate(null);
    }
    setIsSaved(false);
    setDateValue(undefined);
    setErrorText('');
    setHasExpirationDate(nextHasExpirationDate);
  };

  const onDateChange = useCallback(
    (date?: Date) => {
      const endOfDate = date ? endOfDay(date) : date;
      setDateValue(endOfDate);
      if (endOfDate && isBefore(endOfDate, startOfToday())) {
        setErrorText('Expiration date cannot be set in the past');
      } else {
        setErrorText('');
      }
      if (endOfDate !== expirationDate) setIsSaved(false);
    },
    [expirationDate],
  );

  return (
    <div>
      <div className="flex items-center justify-between">
        <div className="text-14 text-grey-12" data-testid={EXPIRATION_DATE_LABEL}>
          Add an expiration date
        </div>
        {!isOnProPlan ? (
          <UpsellButton>Upgrade to Air Pro</UpsellButton>
        ) : (
          <Switch
            checked={hasExpirationDate}
            data-testid={EXPIRATION_DATE_TOGGLE}
            onCheckedChange={toggleExpirationDate}
          />
        )}
      </div>

      {isOnProPlan && hasExpirationDate && (
        <form className={classNames('mt-3', errorText ? 'mb-3' : 'mb-0')} onSubmit={onSave}>
          <div className="relative mt-3 flex items-center">
            <DateInput
              size="large"
              testId={EXPIRATION_DATE_INPUT}
              className="w-full"
              name="expiration-date"
              selected={dateValue}
              onChange={onDateChange}
            />
            <Button
              appearance="filled"
              className="ml-3"
              color="grey"
              data-testid={EXPIRATION_DATE_SUBMIT_BUTTON}
              disabled={isSubmitting || isSaved || !!errorText || !dateValue}
              isLoading={isSubmitting}
              size="large"
              type="submit"
            >
              {isSaved ? 'Saved' : 'Save'}
            </Button>
          </div>
          {!!errorText && (
            <p className="mt-1 text-12 text-red-9" data-testid={`${EXPIRATION_DATE_INPUT}-error`}>
              {errorText}
            </p>
          )}
        </form>
      )}
    </div>
  );
};
