import React, { memo, useRef } from 'react';

import { MentionsData } from '~/components/Discussions/DiscussionForm';
import { UserMentionTooltip } from '~/components/Discussions/UserMention/components/UserMentionTooltip';
import { useHover } from '~/hooks/useHover';

export interface UserMentionProps {
  userName: string;
  user?: MentionsData;
}

export const UserMention = memo(({ userName, user }: UserMentionProps) => {
  const textRef = useRef<HTMLElement>(null);
  const { handleHover, isHovering, handleUnhover } = useHover();

  return (
    <span onMouseEnter={handleHover} onMouseLeave={handleUnhover}>
      {isHovering && user && <UserMentionTooltip containerRef={textRef} user={user} />}
      <span className="cursor-pointer text-14 font-medium text-grey-11" ref={textRef}>
        {userName}
      </span>
    </span>
  );
});

UserMention.displayName = 'UserMention';
