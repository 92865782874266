import { ListSlackIntegrationsObject } from '@air/api/types';
import { Button } from '@air/primitive-button';
import { useAirModal } from '@air/provider-modal';
import { memo } from 'react';

import { ConnectedSlackChannel } from './ConnectedSlackChannel';
import { DeleteBoardSlackIntegrationModal } from './DeleteBoardSlackIntegrationModal';

interface ConnectedToSlackProps {
  integration: ListSlackIntegrationsObject;
  boardId: string;
  canManageSlackIntegration?: boolean;
}
export const ConnectedToSlack = memo(({ integration, boardId, canManageSlackIntegration }: ConnectedToSlackProps) => {
  const [showDeleteSlackIntegrationModal] = useAirModal(DeleteBoardSlackIntegrationModal);

  return (
    <div className="group/connected-slack-container relative mt-2">
      <ConnectedSlackChannel channelName={integration.channelName} />
      {canManageSlackIntegration && (
        <Button
          appearance="ghost"
          color="grey"
          className="absolute right-2.5 top-1/2 hidden -translate-y-1/2 group-hover/connected-slack-container:block hover:bg-grey-5"
          onClick={() => showDeleteSlackIntegrationModal({ boardId, integration })}
        >
          Disconnect
        </Button>
      )}
    </div>
  );
});

ConnectedToSlack.displayName = 'ConnectedToSlack';
