import { Meta } from '../types/docs';

const META: Meta = {
  name: 'moved_boards',
  description: 'User moved a board',
  params: [
    {
      name: 'board_ids',
      description: 'The ids of the boards that were moved',
      required: true,
      type: 'array',
      values: ['string'],
    },
    {
      name: 'new_parent_board_id',
      description: 'New board id, empty if board is moved to root',
      required: false,
      type: 'boolean',
    },
    {
      name: 'new_library_id',
      description: 'Id of the library the board was moved to',
      required: false,
      type: 'string',
    },
  ],
};

export default META;
