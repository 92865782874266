import { PublicClip } from '@air/api';
import { Clip } from '@air/api/types';

import { GalleryItemType } from '~/components/Gallery/types';

export const isAssetFileType = (asset: Pick<Clip | PublicClip, 'type'>) => asset.type === 'nonMedia';

export const getAssetGalleryItemType = (asset: Pick<Clip | PublicClip, 'type'>) =>
  isAssetFileType(asset) ? GalleryItemType.file : GalleryItemType.asset;

export const getClipTitle = (clip: Pick<Clip, 'title' | 'importedName' | 'ext'>) =>
  clip.title || clip.importedName || '';
