import { Upload } from '@air/redux-uploader';

import { PrivateUploadMetadata } from '~/store/privateUploadMetadata/types';
import { UploadingPathToBoardIdState } from '~/store/uploadingPathToBoardId/types';

/**
 *  This method returns path root id for upload in format {batchId}-{boardId}
 *
 * @param batchId batchId from upload
 * @param rootId boardId from upload object
 */
export const createPathRootId = (batchId: string, rootId = 'root') => `${batchId}-${rootId}`;

/**
 * This returns correct boardId for uploader:
 * - boardId if filepath is null - that means we upload the file directly to a board
 * - boardId from pathToBoardIdState if filepath is not null - that means we upload file in a directory
 *
 * @param upload upload object
 * @param pathToBoardIdState value of uploader.pathToBoardId reducer
 */
export const getUploadBoardIdUtil = ({
  batchId,
  uploadMetadata: { originBoard, filepath },
  pathToBoardIdState,
}: {
  batchId: Upload['batchId'];
  uploadMetadata: PrivateUploadMetadata;
  pathToBoardIdState: UploadingPathToBoardIdState;
}): string => {
  if (!filepath) return originBoard?.id || '';

  const rootBoard = createPathRootId(batchId, originBoard?.id);

  const boardIdInState = pathToBoardIdState?.[rootBoard]?.[filepath];

  return boardIdInState?.boardId || '';
};

export const getFileParentsPaths = (filepath: string): string[] => {
  const currentArray: string[] = [];
  const sections = getPathSections(filepath);
  const parents: string[] = [];
  while (sections.length) {
    const section = sections.shift();
    if (!section) break;

    currentArray.push(section);
    parents.push(currentArray.join('/'));
  }
  return parents;
};

export const getPathSections = (filepath: string): string[] => {
  return filepath.split('/').filter((x) => !!x);
};

export const getFilePath = (filepath: string): string => {
  const sections = getPathSections(filepath);
  sections.pop();
  return sections.join('/');
};
