import { Clip } from '@air/api/types';

import { ClipThumbnail } from '~/components/Shared/ClipList/ClipItem/ClipThumbail';
import { DRAG_PREVIEW_HEIGHT, DRAG_PREVIEW_WIDTH } from '~/constants/dnd';
import { ClipIconSize } from '~/types/clip-icon-size';

interface DndAssetDragPreviewProps {
  asset: Clip;
}

export const DNDAssetGalleryCardDragPreview = ({ asset }: DndAssetDragPreviewProps) => (
  <div className="relative flex flex-1">
    <ClipThumbnail
      {...asset}
      width={DRAG_PREVIEW_WIDTH}
      height={DRAG_PREVIEW_HEIGHT}
      src={asset.assets.image}
      iconSize={ClipIconSize.large}
      size="medium"
      borderRadius={4}
    />
  </div>
);
