import { useSelector } from 'react-redux';

import { useBoardPermissions } from '~/hooks/useBoardPermissions';
import { CentralizedBoardSelectors } from '~/store/centralizedBoard/selectors';

export const useCurrentBoardPermissions = () => {
  const boardId = useSelector(CentralizedBoardSelectors.boardId);

  return useBoardPermissions({ boardId });
};
