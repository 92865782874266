import { AirActionTrackingLocation, useTrackAddedClipsToBoard } from '@air/analytics';
import { Boards } from '@air/api';
import { Board, Clip } from '@air/api/types';
import { reportErrorToBugsnag } from '@air/utils-error';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';
import { addAssetsToBoardsSuccessAction } from '~/store/centralizedClip/actions';
import { getClipWithoutWorkspaceIdKey } from '~/swr-hooks/useGetClipWithNoWorkspaceId';
import { useRemoveAssetsFromUnusedAssetsView } from '~/utils/mutateUtils/GalleryAssets';

export interface AddAssetsToBoardParams {
  /** The ids of the assets you want to add */
  clipIds: Clip['id'][];
  /** The board you want to add the assets to */
  boards: Pick<Board, 'id' | 'title' | 'ancestors'>[];
  /** Location where this action started */
  trackLocation: AirActionTrackingLocation;
  /** The id of the board you are adding assets from, if any */
  parentBoardId?: Board['id'];
}

export const useAddAssetsToBoards = () => {
  const dispatch = useDispatch();
  const { removeAssetsFromUnusedAssetsView } = useRemoveAssetsFromUnusedAssetsView();
  const queryClient = useQueryClient();
  const { trackAddedClipsToBoard } = useTrackAddedClipsToBoard();
  const { currentWorkspace } = useCurrentWorkspace();
  const workspaceId = currentWorkspace?.id;

  const addAssetsToBoardMutation = useMutation({
    mutationFn: ({ boards, clipIds }: AddAssetsToBoardParams) => {
      if (!workspaceId) {
        throw new Error('No workspaceId found');
      }

      const boardIds = boards.map((b) => b.id);

      return Boards.addClips({
        boardIds: boardIds,
        clipIds: clipIds,
        workspaceId,
      });
    },

    onSuccess: (_data, { boards, clipIds, parentBoardId, trackLocation }) => {
      const boardIds = boards.map((b) => b.id);

      removeAssetsFromUnusedAssetsView({ clipIds });

      dispatch(addAssetsToBoardsSuccessAction({ boards, clipIds }));

      queryClient.invalidateQueries({
        queryKey: getClipWithoutWorkspaceIdKey({
          clipId: clipIds[0],
          options: {
            boardId: parentBoardId,
          },
        }),
      });

      trackAddedClipsToBoard({
        boardIds,
        clipIds,
        location: trackLocation,
      });
    },

    onError: (error, { clipIds, boards }) => {
      const boardIds = boards.map((b) => b.id);

      reportErrorToBugsnag({
        error,
        context: 'Failed to add assets to a board',
        metadata: { Data: { clipIds, boardIds } },
      });
    },
  });

  /**
   * @TODO Update existing locations to use mutation logic (JAN-458)
   */
  const addAssetsToBoard = useCallback(
    async ({ clipIds, boards, trackLocation, parentBoardId }: AddAssetsToBoardParams) => {
      const boardIds = boards.map((b) => b.id);

      if (!workspaceId) {
        throw new Error('No workspaceId found');
      }

      try {
        removeAssetsFromUnusedAssetsView({ clipIds });
        await Boards.addClips({ boardIds, clipIds, workspaceId });

        dispatch(addAssetsToBoardsSuccessAction({ boards, clipIds }));

        queryClient.invalidateQueries({
          queryKey: getClipWithoutWorkspaceIdKey({
            clipId: clipIds[0],
            options: {
              boardId: parentBoardId,
            },
          }),
        });

        trackAddedClipsToBoard({
          boardIds,
          clipIds,
          location: trackLocation,
        });
      } catch (error) {
        reportErrorToBugsnag({
          error,
          context: 'Failed to add assets to a board',
          metadata: { Data: { clipIds, boardIds } },
        });

        throw error;
      }
    },
    [dispatch, queryClient, removeAssetsFromUnusedAssetsView, trackAddedClipsToBoard, workspaceId],
  );

  return {
    addAssetsToBoard,
    addAssetsToBoardMutation,
  };
};
