import { tailwindMerge } from '@air/tailwind-variants';
import { ComponentProps } from 'react';

import { DRAG_PREVIEW_HEIGHT, DRAG_PREVIEW_WIDTH } from '~/constants/dnd';

export type DndDragPreviewContainer = ComponentProps<'div'>;

export const DndDragPreviewContainer = ({ className, style, ...props }: DndDragPreviewContainer) => {
  return (
    <div
      className={tailwindMerge('rounded bg-grey-1 p-1 shadow-kanban dark:border dark:border-grey-5', className)}
      style={{
        width: DRAG_PREVIEW_WIDTH,
        height: DRAG_PREVIEW_HEIGHT,
        ...style,
      }}
      {...props}
    />
  );
};
