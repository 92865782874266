/**
 * @see https://docs.google.com/spreadsheets/d/1FNN_n0FiP49GCljsiqg1CMwv99KU6wWEloWiet3t_j8/edit#gid=2143410996
 */

import { ScopedPermissions } from '@air/api';

export const canViewBoard = (boardContext: ScopedPermissions | undefined) => !!boardContext?.board.canView;

export const canDeleteBoard = (sourceContext: ScopedPermissions | undefined) => !!sourceContext?.board.canDelete;

export const canDuplicateBoard = ({
  boardContext,
  targetContext,
}: {
  boardContext: ScopedPermissions | undefined;
  targetContext: ScopedPermissions | undefined;
}) => !!boardContext?.asset.canEdit && !!targetContext?.board.canCreate;

export const canExportBoard = (boardContext: ScopedPermissions | undefined) => !!boardContext?.asset.canViewOriginal;

export const canCreateBoard = (parentContext: ScopedPermissions | undefined) => !!parentContext?.board.canCreate;

export const canAddAssetsToBoard = (boardContext: ScopedPermissions | undefined) => !!boardContext?.board.canEdit;

export const canMoveAssetsToBoard = (boardContext: ScopedPermissions | undefined) => !!boardContext?.board.canEdit;

export const canChangeBoardDetails = (boardContext: ScopedPermissions | undefined) => !!boardContext?.board.canEdit;

export const canRemoveAssetsFromBoard = (boardContext: ScopedPermissions | undefined) => !!boardContext?.board.canEdit;

export const canChangeWorkspaceAssetsOrBoardsPosition = (workspaceContext: ScopedPermissions | undefined) =>
  !!workspaceContext?.workspace.canEdit;

export const canChangeLibraryAssetsOrBoardsPosition = (libraryContext: ScopedPermissions | undefined) =>
  !!libraryContext?.library.canEdit;

export const canChangeBoardAssetsOrBoardsPosition = (boardContext: ScopedPermissions | undefined) =>
  !!boardContext?.board.canEdit;

export const canSeeBoardGuests = (boardContext: ScopedPermissions | undefined) =>
  !!boardContext?.board.canManageMembers;

export const canRemoveBoardGuests = (boardContext: ScopedPermissions | undefined) =>
  !!boardContext?.board.canManageMembers;

export const canChangeBoardGuestsRoles = (boardContext: ScopedPermissions | undefined) =>
  !!boardContext?.board.canManageMembers;

export const canInviteBoardGuests = (boardContext: ScopedPermissions | undefined) =>
  !!boardContext?.board.canManageMembers;

export const canSeeBoardCustomFields = (boardContext: ScopedPermissions | undefined) =>
  !!boardContext?.customField.canView;

export const canChangeBoardCustomFields = (boardContext: ScopedPermissions | undefined) =>
  !!boardContext?.customField.canManageApplications;

export const canSeeBoardContentCollectionForm = (boardContext: ScopedPermissions | undefined) =>
  !!boardContext?.shortUrl.canView;

export const canCreateBoardContentCollectionForm = (boardContext: ScopedPermissions | undefined) =>
  !!boardContext?.shortUrl.canCreate;

export const canEditBoardContentCollectionForm = (formContext: ScopedPermissions | undefined) =>
  !!formContext?.shortUrl.canEdit;

export const canChangeBoardIntegrationDetails = ({
  boardContext,
  workspaceContext,
}: {
  boardContext: ScopedPermissions | undefined;
  workspaceContext: ScopedPermissions | undefined;
}) => !!boardContext?.board.canEdit && !!workspaceContext?.workspace.canEdit;
