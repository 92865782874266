import { Library } from '@air/api/types';
import { Home, Images } from '@air/next-icons';

import { IconType } from '~/types/icons';

export const GeneralLibrary: Pick<Library, 'title' | 'id'> & { Icon: typeof Home } = {
  id: 'general',
  title: 'General',
  Icon: Home,
};

export const DefaultLibraryIcon = Images;

export type LibraryWithIcon = Pick<Library, 'title'> & { Icon: IconType };
