import { AirActionTrackingLocation, TrackCopiedShareLinkParams, useTrackCopiedShareLink } from '@air/analytics';
import { ShortUrlResponse } from '@air/api/types';
import { memo } from 'react';

import { CopyLinkButton } from '~/components/SharePrivateContentModal/CopyLinkButton';
import { SharePrivateContentLink } from '~/components/SharePrivateContentModal/SharePrivateContentLink';
import { getFullShareUrl } from '~/utils/shareUrl';

export type ReadonlySharePrivateLinkProps = {
  objectId: string;
  trackLocation: AirActionTrackingLocation;
  shareId: ShortUrlResponse['id'];
  objectType: TrackCopiedShareLinkParams['type'];
};

export const ReadonlySharePrivateLink = memo(
  ({ objectId, trackLocation, shareId, objectType }: ReadonlySharePrivateLinkProps) => {
    const { trackCopiedShareLink } = useTrackCopiedShareLink();

    const shareUrl = getFullShareUrl(shareId);

    const onCopied = () => {
      trackCopiedShareLink({
        id: objectId,
        shareId,
        type: objectType,
        location: trackLocation,
      });
    };

    return (
      <div className="flex gap-2">
        <SharePrivateContentLink shareUrl={shareUrl} />
        <CopyLinkButton shareUrl={shareUrl} onCopied={onCopied} />
      </div>
    );
  },
);

ReadonlySharePrivateLink.displayName = 'ReadonlySharePrivateLink';
