import { useFiltersContext } from '~/providers/FiltersProvider';

export const useAppliedFiltersCount = () => {
  const {
    filters: {
      customFields = {},
      modifiedDateEnd,
      modifiedDateStart,
      recordedDateStart,
      recordedDateEnd,
      uploadedDateStart,
      uploadedDateEnd,
      tds: takenDateStart,
      tde: takenDateEnd,
      camMake: cameraMake,
      camModel: cameraModel,
      ...restFilters
    },
  } = useFiltersContext();

  let filtersCount = Object.keys(restFilters).length + Object.keys(customFields).length;

  if (modifiedDateEnd || modifiedDateStart) filtersCount += 1;
  if (recordedDateEnd || recordedDateStart) filtersCount += 1;
  if (uploadedDateEnd || uploadedDateStart) filtersCount += 1;
  if (takenDateStart || takenDateEnd) filtersCount += 1;

  if (cameraMake || cameraModel) filtersCount += 1;

  return { filtersCount };
};
