import { Portal } from '@air/primitive-portal';
import { createPopper, Instance } from '@popperjs/core';
import { ComponentPropsWithoutRef, RefObject, useCallback, useRef } from 'react';

export type PopperOptions = Parameters<typeof createPopper>[2];

export function usePopperContainer({
  containerRef,
  options,
  usePortal,
}: {
  usePortal?: boolean;
  containerRef?: RefObject<HTMLElement>;
  options?: PopperOptions;
}) {
  const popperInstance = useRef<Instance | null>(null);

  const handlePopper = useCallback(
    (node: HTMLDivElement | null) => {
      if (containerRef?.current && node) {
        popperInstance.current = createPopper(containerRef.current, node, options);
      }
    },
    [containerRef, options],
  );

  const PopperContainer = useCallback(
    (props: ComponentPropsWithoutRef<'div'>) => {
      const content = <div ref={handlePopper} {...props} />;
      if (usePortal) {
        return <Portal>{content}</Portal>;
      }
      return content;
    },
    [handlePopper, usePortal],
  );

  return {
    popperInstance,
    PopperContainer,
  };
}
