import { WorkspaceMemberResponseWithRoles } from '@air/api/types';
import { createContext, PropsWithChildren, useContext, useMemo } from 'react';

import { useGetWorkspaceMembers } from '~/swr-hooks/members/useGetWorkspaceMembers';
import { isDevOrTestStage } from '~/swr-hooks/utils';
import { reportErrorToBugsnag } from '~/utils/ErrorUtils';

type WorkspaceMembersProviderProps = PropsWithChildren<object>;

export interface WorkspaceMembersProviderContextValue {
  data: WorkspaceMemberResponseWithRoles[] | undefined;
  error?: unknown;
}

const defaultValue: WorkspaceMembersProviderContextValue = {
  data: undefined,
};

const WorkspaceMembersProviderContext = createContext<WorkspaceMembersProviderContextValue>(defaultValue);

export const WorkspaceMembersProvider = ({ children }: WorkspaceMembersProviderProps) => {
  const { data, error } = useGetWorkspaceMembers();

  const value = useMemo(() => ({ data, error }), [data, error]);

  return <WorkspaceMembersProviderContext.Provider value={value}>{children}</WorkspaceMembersProviderContext.Provider>;
};

export const useWorkspaceMembersContext = () => {
  const context = useContext(WorkspaceMembersProviderContext);

  if (context === defaultValue) {
    const error = 'WorkspaceMembersProviderContext used outside of WorkspaceMembersProvider';

    if (isDevOrTestStage()) {
      throw error;
    } else {
      reportErrorToBugsnag({
        error,
        context: error,
      });
    }
  }

  return context;
};
