import { sizes } from '@air/provider-media-query';
import { useWindowSize } from '@react-hook/window-size';
import { useSelector } from 'react-redux';
import { createGlobalStyle } from 'styled-components';

import { viewingAssetSelector } from '~/store/router/selectors';

const GlobalStyle = createGlobalStyle<{ isShowingMedia?: boolean; isSmallWidth?: boolean }>`
  html, body { // Prevents Chrome from hiding header bar on scroll. When it is hidden, bottom tab has incorrect bottom position
    overflow-x: hidden;
    overflow: ${({ isShowingMedia, isSmallWidth }) => (isShowingMedia || isSmallWidth) && 'hidden'};
  }
`;

const OverflowHiddenGlobalStyle = () => {
  const isShowingMedia = useSelector(viewingAssetSelector);
  const [windowWidth] = useWindowSize({
    initialHeight: typeof window !== 'undefined' ? window.innerHeight : 0,
    initialWidth: typeof window !== 'undefined' ? window.innerWidth : 0,
    wait: 100,
  });

  /**
   * On mobile browsers there's a bounce effect when scrolling that causes the scroll-overflow from the bottom
   * of the screen to pull up over top of the content. `overflow: hidden;` on the body element prevents this
   * from happening.
   */
  const isSmallWidth = windowWidth < sizes.medium;

  return <GlobalStyle isShowingMedia={isShowingMedia} isSmallWidth={isSmallWidth} />;
};

export default OverflowHiddenGlobalStyle;
