import { Board } from '@air/api/types';
import { activeUploadsSelector } from '@air/redux-uploader';
import { useSelector } from 'react-redux';

import { metadataForPrivateUploadSelector } from '~/store/privateUploadMetadata/selectors';
import { useAirStore } from '~/utils/ReduxUtils';

export const useIsUploadingToSelectedBoard = (boardIds: Board['id'][]) => {
  const store = useAirStore();
  const uploadsForBoards = useSelector(activeUploadsSelector);

  const isUploadingToBoard = uploadsForBoards.find((upload) => {
    const uploadMetadata = metadataForPrivateUploadSelector(store.getState(), upload.id);
    if (!uploadMetadata) return false;
    return (
      (uploadMetadata.originBoard?.id && boardIds.includes(uploadMetadata.originBoard.id)) ||
      uploadMetadata.originBoard?.ancestors?.find((ancestor) => boardIds.includes(ancestor.id))
    );
  });

  return { isUploadingToBoard: !!isUploadingToBoard };
};
