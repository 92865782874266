import { useRouter } from 'next/router';

import { getBoardIdFromPath } from '~/utils/PathUtils';

export const useURLBoardIdSelector = () => {
  const { asPath } = useRouter();
  const boardId = getBoardIdFromPath(asPath);

  return boardId;
};
