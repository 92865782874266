import { Label } from '@air/primitive-label';
import { tailwindMerge } from '@air/tailwind-variants';
import VisuallyHidden from '@reach/visually-hidden';
import classNames from 'classnames';
import { useField } from 'formik';
import { memo, useCallback, useMemo } from 'react';

import { SelectChipOptionContainer } from '../Select/components/SelectChipOptionContainer';
import {
  ChipRenderer,
  ListItemRenderer,
  OnSelectionChangeArgs,
  orderedSortedFilterFunc,
  Select,
  SelectProps,
} from '../Select/Select';
import { DefaultChipType } from '../Select/shared/types';

export interface FormikSingleSelectProps
  extends Pick<
    SelectProps,
    | 'className'
    | 'disabled'
    | 'isLoading'
    | 'isSearchable'
    | 'onOptionsClose'
    | 'onOptionsOpen'
    | 'options'
    | 'placeholder'
    | 'size'
  > {
  onChange?: () => void;
  'data-testid'?: string;
  /**
   * An input must always have a label, but design may want it visually hidden.
   */
  isLabelHidden?: boolean;
  /**
   * The content of the label. No need for * when required - it's added automatically.
   */
  label: string;
  /**
   * Should match the relevant key name inside the Formik schema.
   */
  name: string;
  /**
   * A value to set as the max height for the dropdown.
   */
  maxDropdownHeight?: number;
}

export const FormikSingleSelect = memo<FormikSingleSelectProps>(
  ({
    className,
    'data-testid': dataTestId,
    disabled = false,
    isLabelHidden = false,
    isLoading,
    isSearchable = true,
    label,
    name,
    onChange,
    onOptionsClose,
    onOptionsOpen,
    options,
    placeholder,
    size = 'large',
    maxDropdownHeight = 300,
  }: FormikSingleSelectProps) => {
    const [field, meta, helpers] = useField<string>(name);
    const hasError = meta.touched && !!meta.error;
    const isExtraLarge = size === 'extra-large';

    const selectedOptions: DefaultChipType[] = useMemo(() => {
      const option = options.find((o) => o.value === field.value);
      return option ? [option] : [];
    }, [field, options]);

    const onSelectionChange = useCallback(
      ({ chips }: OnSelectionChangeArgs) => {
        helpers.setValue(chips[0]?.value || '');
        onChange?.();
      },
      [helpers, onChange],
    );

    const chipRenderer = useCallback<ChipRenderer<DefaultChipType>>(
      (chip) => {
        return (
          <div className={classNames('flex justify-between px-2.5', isExtraLarge ? 'my-0.5 py-1' : 'my-0 py-0.5')}>
            <div className="truncate text-16 text-grey-11">{chip.label}</div>
          </div>
        );
      },
      [isExtraLarge],
    );

    const listItemRenderer = useCallback<ListItemRenderer<DefaultChipType>>(
      (chip) => (
        <SelectChipOptionContainer>
          <div
            className={classNames('truncate text-grey-11', isExtraLarge ? 'py-1.5 text-16' : 'py-1 text-14')}
            key={chip.value}
          >
            {chip.label}
          </div>
        </SelectChipOptionContainer>
      ),
      [isExtraLarge],
    );

    return (
      <div
        className={tailwindMerge('relative flex min-w-[256px] flex-col items-start justify-center', className)}
        data-testid={dataTestId}
      >
        {isLabelHidden ? (
          <VisuallyHidden>
            <Label htmlFor={name}>{label}</Label>
          </VisuallyHidden>
        ) : (
          <Label htmlFor={name} className="mb-1">
            {label}
          </Label>
        )}

        <Select
          hasError={!!meta.touched && !!meta.error}
          chipRenderer={chipRenderer}
          listItemRenderer={listItemRenderer}
          closeMenuOnSelect={true}
          disabled={disabled}
          filterFunc={orderedSortedFilterFunc}
          isClearable={false}
          isLoading={isLoading}
          isSearchable={isSearchable}
          isSingleSelect
          onOptionsClose={onOptionsClose}
          onSelectionChange={onSelectionChange}
          options={options}
          placeholder={placeholder}
          renderAsInput
          selectedOptions={selectedOptions}
          showTriangle
          className="w-full"
          size={size}
          maxDropdownHeight={maxDropdownHeight}
          onOptionsOpen={onOptionsOpen}
        />
        {hasError && (
          <div
            data-testid={`${dataTestId}_error`}
            className="form-field-error absolute top-full w-full translate-y-0.5 text-12 text-red-9 first-letter:uppercase"
          >
            {meta.error}
          </div>
        )}
      </div>
    );
  },
);

FormikSingleSelect.displayName = 'FormikSingleSelect';
