import { AirActionTrackingLocation } from '@air/analytics';
import { ShortUrlObjectType } from '@air/api/types';
import { Modal } from '@air/primitive-modal';
import { useEffect, useMemo } from 'react';

import { NoPermissionToCreateShareLink } from '~/components/NoPermissionToCreateShareLink';
import { SharePrivateBoardTabs } from '~/components/SharePrivateBoardModal/SharePrivateBoardTabs';
import { ReadonlySharePrivateLink } from '~/components/SharePrivateContentModal/ReadonlySharePrivateLink';
import { ShareModalHeader } from '~/components/SharePrivateContentModal/ShareModalHeader';
import { SharePrivateContentSpinner } from '~/components/SharePrivateContentModal/SharePrivateContentSpinner';
import { SHARE_PRIVATE_CONTENT_MODAL } from '~/constants/testIDs';
import { useShowTurnOnShareLinkNotifications } from '~/hooks/turnOnEmailShareNotificationsToast/useShowTurnOnShareLinkNotifications';
import { useBoardPermissions } from '~/hooks/useBoardPermissions';
import { useShowSubscriptionExpiredModal } from '~/hooks/useShowSubscriptionExpiredModal';
import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';
import { useGetExistingShortIdInformation } from '~/swr-hooks/shortUrl/useGetExistingShortIdInformation';
import { useGetShortIdForBoard } from '~/swr-hooks/shortUrl/useGetShortIdForBoard';
import { canCreateShortUrl, canEditShortUrl, canViewShortUrl } from '~/utils/permissions/shortUrlPermissions';

export type SharePrivateBoardModalProps = AirModalProps<{
  boardId: string;
  trackLocation: AirActionTrackingLocation;
}>;

export const SharePrivateBoardModal = ({ boardId, onClose, trackLocation }: SharePrivateBoardModalProps) => {
  const { currentWorkspace } = useCurrentWorkspace();
  const { showingSubscriptionExpiredModal } = useShowSubscriptionExpiredModal({ onClose });
  const { boardPermissions } = useBoardPermissions({
    boardId,
  });

  const { showTurnOnShareLinkNotifications } = useShowTurnOnShareLinkNotifications();

  const canEdit = canEditShortUrl(boardPermissions);
  const canCreate = canCreateShortUrl(boardPermissions);

  const { data: shortIdInfo, isLoading: isInitialLoading } = useGetExistingShortIdInformation({
    id: boardId,
    type: ShortUrlObjectType.board,
    boardContext: undefined,
    canView: canViewShortUrl(boardPermissions),
  });

  const { getShortIdForBoard } = useGetShortIdForBoard();

  useEffect(() => {
    const workspaceId = currentWorkspace?.id;
    if (!workspaceId) {
      return;
    }
    if (!isInitialLoading && !shortIdInfo && canCreate && !getShortIdForBoard.isPending) {
      getShortIdForBoard.mutate(
        {
          workspaceId,
          id: boardId,
        },
        {
          onSuccess: () => {
            showTurnOnShareLinkNotifications({
              onManageClick: onClose,
            });
          },
        },
      );
    }
  }, [
    currentWorkspace?.id,
    boardId,
    canCreate,
    getShortIdForBoard,
    isInitialLoading,
    onClose,
    shortIdInfo,
    showTurnOnShareLinkNotifications,
  ]);

  const ModalContent = useMemo(() => {
    if (!boardPermissions) {
      return <SharePrivateContentSpinner />;
    } else if (!shortIdInfo) {
      if (canCreate) {
        return <SharePrivateContentSpinner />;
      } else {
        return <NoPermissionToCreateShareLink />;
      }
    } else {
      if (!canEdit) {
        return (
          <ReadonlySharePrivateLink
            objectType={ShortUrlObjectType.board}
            shareId={shortIdInfo.id}
            objectId={boardId}
            trackLocation={trackLocation}
          />
        );
      } else {
        return <SharePrivateBoardTabs boardId={boardId} trackLocation={trackLocation} shortIdInfo={shortIdInfo} />;
      }
    }
  }, [boardId, canCreate, canEdit, boardPermissions, shortIdInfo, trackLocation]);

  if (showingSubscriptionExpiredModal) {
    return null;
  }

  return (
    <Modal data-testid={SHARE_PRIVATE_CONTENT_MODAL} dangerouslyBypassFocusLock isOpen onDismiss={onClose}>
      <ShareModalHeader onClose={onClose} title="Share link to board" />
      {ModalContent}
    </Modal>
  );
};
