import { Members } from '@air/api';
import { NotificationSettings } from '@air/api/dist/member';
import { DeepPartial } from '@air/types';
import { convertUnknownToError, reportErrorToBugsnag } from '@air/utils-error';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { merge } from 'lodash';
import invariant from 'tiny-invariant';

import { useAccountContext } from '~/providers/AccountProvider';
import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';
import { getNotificationSettingsKey } from '~/swr-hooks/account/useNotificationSettings';

export const useUpdateNotificationSettings = () => {
  const queryClient = useQueryClient();
  const { currentWorkspace } = useCurrentWorkspace();
  const { data: account } = useAccountContext();
  const workspaceId = currentWorkspace?.id;

  const updateNotificationSettings = useMutation({
    mutationFn: (updates: DeepPartial<NotificationSettings>) => {
      invariant(workspaceId, 'Workspace id must be defined');

      return Members.updateNotificationSettings({
        workspaceId: workspaceId!,
        updates,
      });
    },
    onMutate: async (updates) => {
      const key = getNotificationSettingsKey({
        workspaceId,
        accountId: account?.id,
      });
      await queryClient.cancelQueries({ queryKey: key });

      const prevNotificationSettings = queryClient.getQueryData<NotificationSettings>(key);
      queryClient.setQueryData<NotificationSettings | undefined>(key, (old) => merge({}, old, updates));

      return {
        prevNotificationSettings,
      };
    },
    onError: (error, updates, context) => {
      reportErrorToBugsnag({
        error: convertUnknownToError(error),
        context: 'Failed to update notification settings',
        metadata: {
          updates,
          accountId: account?.id,
        },
      });

      queryClient.setQueryData(
        getNotificationSettingsKey({ workspaceId, accountId: account?.id }),
        context?.prevNotificationSettings,
      );
    },
  });

  return {
    updateNotificationSettings,
  };
};
