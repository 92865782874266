import { TabPanel, Tabs } from '@air/primitive-tabs';
import { memo } from 'react';

import { PRIVATE_CONTENT_SHARE_TABS } from '~/constants/testIDs';

interface SharePrivateContentTabsProps {
  panels: TabPanel[];
  activeTab: string;
  setActiveTab: (tab: string) => void;
}

export const SharePrivateContentTabs = memo(({ panels, setActiveTab, activeTab }: SharePrivateContentTabsProps) => {
  return (
    <Tabs
      className="-mx-6 -mt-1"
      defaultValue="link"
      listClassName="px-6"
      panels={panels}
      panelsClassName="px-8 pt-4"
      onValueChange={setActiveTab}
      value={activeTab}
      data-testid={PRIVATE_CONTENT_SHARE_TABS}
    />
  );
});

SharePrivateContentTabs.displayName = 'SharePrivateContentTabs';
