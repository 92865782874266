import { AirActionTrackingLocation, useTrackCreatedBoard } from '@air/analytics';
import { Boards } from '@air/api';
import { useCallback } from 'react';

import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';
import { useFollowBoard } from '~/swr-hooks/followedBoards/useFollowBoard';
import { reportErrorToBugsnag } from '~/utils/ErrorUtils';
import { sanitizeRestrictedPathChars } from '~/utils/FileUtils';
import { useAddBoardsToAllLists } from '~/utils/mutateUtils/AllBoards';

export type CreateNewBoardParams = Omit<Parameters<typeof Boards.create>['0'], 'workspaceId'> & {
  trackLocation: AirActionTrackingLocation;
};

export const useCreateNewBoard = () => {
  const {
    followBoard: { mutate: followBoardMutate },
  } = useFollowBoard();
  const { addBoardsToAllLists } = useAddBoardsToAllLists();
  const { trackCreatedBoard } = useTrackCreatedBoard();
  const { currentWorkspace } = useCurrentWorkspace();
  const workspaceId = currentWorkspace?.id;

  const createNewBoard = useCallback(
    async ({ board, clipIds, trackLocation, shouldFollow }: CreateNewBoardParams) => {
      if (!workspaceId) {
        throw new Error('No workspaceId found');
      }

      try {
        const { board: boardResponse } = await Boards.create({
          workspaceId,
          board: {
            ...board,
            title: sanitizeRestrictedPathChars(board.title.trim()),
            parentId: board.parentId || undefined,
          },
          clipIds,
        });

        if (shouldFollow) {
          await followBoardMutate(boardResponse.id);
        }

        addBoardsToAllLists([boardResponse], board.parentId);

        trackCreatedBoard({
          ...boardResponse,
          clipIds,
          location: trackLocation,
        });

        return boardResponse;
      } catch (error) {
        throw reportErrorToBugsnag({
          error,
          context: 'Failed to create board',
          metadata: { Data: { board, clipIds, shouldFollow, trackLocation } },
        });
      }
    },
    [workspaceId, addBoardsToAllLists, trackCreatedBoard, followBoardMutate],
  );

  return {
    createNewBoard,
  };
};
