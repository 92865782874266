import { Events, Message, SubscribeParams } from '@air/wss-sdk';
import { RefObject } from 'react';

export type EventSubscriptionArgs<T extends keyof Events> = {
  eventType: SubscribeParams<T>[0]['eventType'];
  pattern?: Omit<SubscribeParams<T>[0]['pattern'], 'workspaceId'>;
};

export interface SubscribeOptions<T extends keyof Events> {
  // Subscription options.
  onSubscribe?: () => void;
  onUnsubscribe?: () => void;
  onUpdate?: (message: Message<T>) => void;
  onError?: (error: WebSocketEventMap['error']) => void;
}

export type Subscriber<T extends keyof Events> = {
  uuid: string;
  options: RefObject<SubscribeOptions<T>>;
  onSubscriptionSuccess: (subscriptionId: string) => void;
  updateUUID: (newUUID: string) => void;
};

export interface Subscription<T extends keyof Events> {
  subscriptionId?: string;
  event: EventSubscriptionArgs<T>;
  subscribers: Set<Subscriber<T>>;
  workspaceId: string;
}

export enum ReadyState {
  UNINSTANTIATED = -1,
  CONNECTING = 0,
  OPEN = 1,
  CLOSING = 2,
  CLOSED = 3,
}
