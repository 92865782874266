import React, { memo } from 'react';

import { SharePrivateContentPermissionToggle } from '~/components/SharePrivateContentModal/shared/SharePrivateContentPermissionToggle';

export interface EnableSharedLinkToggleProps {
  enabled: boolean;
  isLoading: boolean;
  onToggle: (enabled: boolean) => void;
}

export const EnableSharedLinkToggle = memo(({ onToggle, enabled, isLoading }: EnableSharedLinkToggleProps) => (
  <SharePrivateContentPermissionToggle
    checked={enabled}
    id="enable-link"
    isLoading={isLoading}
    label="Enable public link"
    onCheckedChange={onToggle}
  />
));

EnableSharedLinkToggle.displayName = 'EnableSharedLinkToggle';
