import { PlanPricing } from '@air/api/types';

import { usePlansList } from '~/hooks/usePlansList';
import { useSubscriptionContext } from '~/providers/SubscriptionProvider';

export type Features = keyof PlanPricing['features'];

export const usePlanFeature = (featureName: Features): boolean => {
  const { data } = usePlansList();
  const plans = data?.plans ?? [];
  const { data: subscription } = useSubscriptionContext();
  const planName = subscription?.plan === 'demo' ? 'pro' : subscription?.plan;
  const relevantPlan = plans.find((plan) => plan.id === planName);
  return !!relevantPlan ? !!relevantPlan.features?.[featureName] : false;
};
