import { ModalCloseButton, ModalTitle } from '@air/primitive-modal';
import { memo } from 'react';

import { MODAL_LABEL, X_BUTTON } from '~/constants/testIDs';

interface ShareModalHeaderProps {
  onClose: AirModalProps['onClose'];
  title: string;
}

export const ShareModalHeader = memo(({ onClose, title }: ShareModalHeaderProps) => {
  return (
    <div className="mb-4 flex items-center justify-between gap-4">
      <ModalTitle data-testid={MODAL_LABEL}>{title}</ModalTitle>
      <ModalCloseButton className="shrink-0" onClick={onClose} data-testid={X_BUTTON} />
    </div>
  );
});

ShareModalHeader.displayName = 'ShareModalHeader';
