import { tailwindVariants } from '@air/tailwind-variants';
import * as RadixHoverCard from '@radix-ui/react-hover-card';
import { type ReactNode } from 'react';

const hoverCard = tailwindVariants({
  base: 'w-full overflow-y-auto rounded bg-white p-2 shadow-popover outline-none radix-side-bottom:animate-slide-up radix-side-top:animate-slide-down',
});

export type HoverCardProps = RadixHoverCard.HoverCardProps &
  RadixHoverCard.PopperContentProps & {
    trigger: ReactNode;
  };

export const HoverCard = ({
  defaultOpen,
  children,
  className,
  closeDelay,
  open,
  onOpenChange,
  openDelay,
  sideOffset = 8,
  trigger,
  ...restOfProps
}: HoverCardProps) => {
  return (
    <RadixHoverCard.Root
      defaultOpen={defaultOpen}
      closeDelay={closeDelay}
      onOpenChange={onOpenChange}
      open={open}
      openDelay={openDelay}
    >
      <RadixHoverCard.Trigger asChild data-testid="HOVER_CARD_TRIGGER">
        {trigger}
      </RadixHoverCard.Trigger>
      <RadixHoverCard.Portal>
        <RadixHoverCard.Content
          className={hoverCard({ className })}
          data-testid="HOVER_CARD"
          {...restOfProps}
          sideOffset={sideOffset}
        >
          {children}
        </RadixHoverCard.Content>
      </RadixHoverCard.Portal>
    </RadixHoverCard.Root>
  );
};
