import { Badge } from '@air/primitive-badge';
import { useAirModal } from '@air/provider-modal';
import { PropsWithChildren } from 'react';

import { PlansModal } from '~/components/Modals/PlansModal/PlansModal';
import { PASSCODE_PROTECTION_UPSELL_BUTTON } from '~/constants/testIDs';
import { useCurrentWorkspacePermissionsContext } from '~/providers/CurrentWorkspacePermissionsProvider';
import { canManageSubscription } from '~/utils/permissions/workspacePermissions';

export const UpsellButton = ({ children }: PropsWithChildren) => {
  const { data: workspacePermissions } = useCurrentWorkspacePermissionsContext();
  const canEditPlan = canManageSubscription(workspacePermissions);
  const [showPlansModal] = useAirModal(PlansModal);

  return (
    <button
      className="flex"
      data-testid={PASSCODE_PROTECTION_UPSELL_BUTTON}
      disabled={!canEditPlan}
      onClick={showPlansModal}
    >
      <Badge color="teal" size="small">
        {children}
      </Badge>
    </button>
  );
};
