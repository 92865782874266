import { Plus } from '@air/next-icons';
import { IconButton } from '@air/primitive-icon-button';
import { ComponentProps } from 'react';

export type BoardSelectListHeaderProps = ComponentProps<'h2'> & {
  onCreate?: () => void;
};

export const BoardSelectListHeader = ({ children, onCreate }: BoardSelectListHeaderProps) => {
  return (
    <h2 className="flex h-6 shrink-0 items-center justify-between gap-2 px-2 text-12 font-bold uppercase tracking-wider text-grey-9">
      {children}

      {!!onCreate && (
        <IconButton appearance="ghost" color="grey" icon={Plus} label="Create new" onClick={onCreate} size="small" />
      )}
    </h2>
  );
};
