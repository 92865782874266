import { Upload, uploadArraySelector } from '@air/redux-uploader';
import { createSelector } from 'reselect';

import { PrivateUploadMetadata } from '~/store/privateUploadMetadata/types';
import { AirState } from '~/store/types';
import { uploadingPathToBoardIdSelector } from '~/store/uploadingPathToBoardId/selector';
import { getUploadBoardIdUtil } from '~/store/uploadingPathToBoardId/utils';

export const privateUploadMetadataSelector = ({ privateUploadMetadata }: AirState) => privateUploadMetadata;
const uploadIdSelector = (_state: AirState, uploadId: Upload['id']) => uploadId;

export const metadataForPrivateUploadSelector = createSelector(
  privateUploadMetadataSelector,
  uploadIdSelector,
  (metadata, uploadId): PrivateUploadMetadata | undefined => metadata[uploadId],
);

/**
 * This selector returns a board id and title if all uploads are uploaded to the same board (or its children) - null otherwise
 */
export const uploadedDestinationSummarySelector = createSelector(
  uploadArraySelector,
  privateUploadMetadataSelector,
  (uploads, uploadsMetadata) => {
    if (uploads.length) {
      const destinationBoard = uploadsMetadata[uploads[0].id]?.originBoard;
      const uploadedToOneBoard = uploads.every((upload) => {
        const metadata = uploadsMetadata[upload.id];
        return metadata?.originBoard?.id === destinationBoard?.id;
      });
      if (uploadedToOneBoard) {
        return destinationBoard;
      }
    }
  },
);

export const privateUploadBoardIdSelector = createSelector(
  uploadArraySelector,
  privateUploadMetadataSelector,
  uploadingPathToBoardIdSelector,
  uploadIdSelector,
  (uploads, metadata, pathToBoardIdState, uploadId) => {
    const upload = uploads.find((upload) => upload.id === uploadId);
    const uploadMetadata = metadata[uploadId];
    if (!upload || !uploadMetadata) return;
    return getUploadBoardIdUtil({ batchId: upload.batchId, uploadMetadata, pathToBoardIdState });
  },
);
