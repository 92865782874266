import classNames from 'classnames';
import { ComponentProps } from 'react';

import { UPLOAD_PANE_WIDTH_CLASSNAME } from '~/constants/WorkspaceSpacing';

export const PaneContainer = ({ className, style, ...rest }: ComponentProps<'div'>) => (
  <div
    className={classNames('rounded-md bg-white shadow-md shadow-pigeon-700/40', UPLOAD_PANE_WIDTH_CLASSNAME, className)}
    style={{
      ...style,
      maxWidth: typeof window === 'undefined' ? 0 : window.innerWidth - 32,
    }}
    {...rest}
  />
);
