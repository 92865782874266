import { isWebPageSocketMessage, WebPageSocketMessage } from '~/classes/desktopAppWebsocket/messagesTypes';

const DESKTOP_APP_WEBSOCKET_URL = 'ws://localhost:51182';

export interface DesktopAppWebSocketSubscription {
  onConnected: () => void;
  onDisconnected: () => void;
  onMessage: (message: WebPageSocketMessage) => void;
}

class _DesktopAppWebSocket {
  private connection: WebSocket | null = null;
  private isConnected = false;
  private subscription: DesktopAppWebSocketSubscription | null = null;

  connect = (callback: DesktopAppWebSocketSubscription) => {
    if (this.isConnected) {
      return;
    }

    this.subscription = callback;

    this.connection = new WebSocket(DESKTOP_APP_WEBSOCKET_URL);

    this.connection.onopen = () => {
      this.isConnected = true;
      this.subscription?.onConnected();
    };

    this.connection.onmessage = (message) => {
      const event = JSON.parse(message.data.toString());
      if (isWebPageSocketMessage(event)) {
        this.subscription?.onMessage(event);
      }
    };

    this.connection.onclose = () => {
      this.isConnected = false;
      this.subscription?.onDisconnected();
    };

    this.connection.onerror = () => {
      this.isConnected = false;
      this.subscription?.onDisconnected();
    };
  };

  sendMessage = (message: WebPageSocketMessage) => {
    this.connection?.send(JSON.stringify(message));
  };
}

export const DesktopAppWebSocket = new _DesktopAppWebSocket();
