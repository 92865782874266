import { useTrackClickedAddASubBoard } from '@air/analytics';
import { useAirModal } from '@air/provider-modal';
import { useCallback } from 'react';

import { CreateBoardModal, CreatePrivateBoardModalProps } from '~/components/Modals/CreateBoardModal';

export const useCreateSubBoard = () => {
  const [showCreateBoardModal] = useAirModal(CreateBoardModal);
  const { trackClickedAddASubBoard } = useTrackClickedAddASubBoard();

  const createSubBoard = useCallback(
    (params: CreatePrivateBoardModalProps) => {
      showCreateBoardModal(params);
      trackClickedAddASubBoard({ location: params.trackLocation });
    },
    [showCreateBoardModal, trackClickedAddASubBoard],
  );

  return {
    createSubBoard,
  };
};
