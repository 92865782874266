import { BoardsSelectEmptyOption, BoardsSelectRootOption } from '~/types/BoardSearch';

export const BOARDS_SELECT_ROOT_OPTION: BoardsSelectRootOption = {
  id: 'root',
  title: 'Boards',
};

export const BOARDS_SELECT_EMPTY_OPTION: BoardsSelectEmptyOption = {
  id: '',
  title: '',
  ancestors: [],
};
