import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';
import { useWorkspaceRolesContext } from '~/providers/WorkspaceRolesProvider';

export const useIsAdminEnterpriseMember = () => {
  const { currentWorkspace } = useCurrentWorkspace();
  const { data: roles } = useWorkspaceRolesContext();

  const adminRoleIds = roles?.filter((role) => role.isAdmin).map((role) => role.id);

  const isAdminEnterpriseMember =
    (currentWorkspace?.plan === 'enterprise' || currentWorkspace?.plan === 'demo') &&
    adminRoleIds?.includes(currentWorkspace.roleId);

  return { isAdminEnterpriseMember };
};
