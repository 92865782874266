import { useConnectionStatusListener } from '@air/hook-use-connection-status-listener';
import { constant, noop, throttle } from 'lodash';
import { createContext, memo, ReactNode, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useUnmount } from 'react-use';

import { getAirApiConfig } from '~/constants/airApi';
import { PENDING_SUBSCRIPTIONS, SUBSCRIPTIONS } from '~/constants/sockets';
import { ReadyState } from '~/types/sockets';
import { reportErrorToBugsnag } from '~/utils/ErrorUtils';

import { createSocket } from './create-socket/create-socket';
import { websocketWrapper } from './proxy';
import { SendJsonMessage, WebSocketMessage } from './shared/typings';
import { useResubscribeOnWorkspaceChange } from './useResubscribeOnWorkspaceChange';

type SendMessage = (message: WebSocketMessage, keep?: boolean) => void;

const removeAllSubscriptions = () => {
  Object.keys(SUBSCRIPTIONS).forEach((key) => {
    delete SUBSCRIPTIONS[key];
  });
  Object.keys(PENDING_SUBSCRIPTIONS).forEach((key) => {
    delete PENDING_SUBSCRIPTIONS[key];
  });
};

interface SocketContextType {
  readyState: ReadyState | null;
  getWebSocket: () => WebSocket | null;
  sendJsonMessage: SendJsonMessage;
  sendMessage: SendMessage;
}

const DEFAULT_SOCKET_CONTEXT_VALUE: SocketContextType = {
  readyState: null,
  getWebSocket: constant(null),
  sendJsonMessage: noop,
  sendMessage: noop,
};

const SocketContext = createContext<SocketContextType>(DEFAULT_SOCKET_CONTEXT_VALUE);

interface SocketContextProviderProps {
  children: ReactNode;
  connect: boolean;
  /**
   * This is the token used for authenticating with Air's WS server.
   * On private routes, this would be the auth token from Cognito and on public routes, it's the shortId
   */
  getAuthToken: () => Promise<string>;
  /**
   * This is the id of the workspace the events should be listening to
   */
  currentWorkspaceId?: string;
}

export const SocketContextProvider = memo(
  ({ children, connect, getAuthToken, currentWorkspaceId }: SocketContextProviderProps) => {
    const [readyState, setReadyState] = useState<ReadyState | null>(null);
    const convertedUrl = useRef<string | null>(null);
    const webSocketRef = useRef<WebSocket | null>(null);
    const startRef = useRef<() => void>(constant(void 0));
    const webSocketProxy = useRef<WebSocket | null>(null);
    const messageQueue = useRef<WebSocketMessage[]>([]);
    const reconnectCountRef = useRef<number>(0);
    const expectCloseRef = useRef<boolean>(false);
    const connectAttemptsRef = useRef(0);

    const readyStateFromUrl: ReadyState =
      convertedUrl.current && readyState !== null
        ? readyState
        : connect
        ? ReadyState.CONNECTING
        : ReadyState.UNINSTANTIATED;

    const getWebSocket = useCallback(() => {
      if (webSocketProxy.current === null && webSocketRef.current) {
        webSocketProxy.current = websocketWrapper(webSocketRef.current, startRef);
      }

      return webSocketProxy.current;
    }, []);

    const sendMessage: SendMessage = useCallback((message, keep = true) => {
      if (webSocketRef.current?.readyState === ReadyState.OPEN) {
        webSocketRef.current.send(message);
      } else if (keep) {
        messageQueue.current.push(message);
      }
    }, []);

    const sendJsonMessage: SendJsonMessage = useCallback(
      (message, keep = true) => {
        sendMessage(JSON.stringify(message), keep);
      },
      [sendMessage],
    );

    useResubscribeOnWorkspaceChange({
      isConnected: readyStateFromUrl === ReadyState.OPEN,
      sendJsonMessage,
      currentWorkspaceId,
    });

    const close = useCallback(() => {
      webSocketRef.current?.close();
      setReadyState(ReadyState.CLOSED);
    }, []);

    const start = useCallback(() => {
      /**
       * In the event that connecting to the WSS fails, we want the client to always retry
       * so it can stay up-to-date. However, we don't want it to try repeatedly or it can flood
       * the BE with too many requests and cause issues. So we implement an exponential backoff
       * algorithm to limit the number of times the client tries to connect to the WSS.
       */

      /** We don't want the clients to be trying to connect any less than 1 hour at a time */
      const MAXIMUM_BACKOFF_TIME = 1000 * 60 * 60;

      /** Get the delay for the next connect based on the number of attempts */
      const retryDelay = connectAttemptsRef.current ** 2 * 1000;

      /**
       * If the retryDelay is more than or equal to the MAXIMUM_BACKOFF_TIME (1 hour), we just cap it at one hour because
       * we want the client to continue trying to connect every hour to the socket server even if it's failing.
       *
       * If the retryDelay is less than the MAXIMUM_BACKOFF_TIME, we just use the retryDelay as the delay.
       */
      const delayWithoutRandom = retryDelay < MAXIMUM_BACKOFF_TIME ? retryDelay : MAXIMUM_BACKOFF_TIME;

      /**
       * Should also add a random quantity to this delay to prevent all clients from reconnecting at exactly the same time
       */
      const delay = delayWithoutRandom * (Math.random() * 0.2 + 1);

      const throttledFunction = throttle(
        () => {
          if (connect && readyState !== ReadyState.CONNECTING && readyState !== ReadyState.OPEN) {
            connectAttemptsRef.current += 1;
            expectCloseRef.current = false;

            const _start = async () => {
              try {
                const authToken = await getAuthToken();
                convertedUrl.current = `${getAirApiConfig().wsUrl}?authorization=${authToken}`;

                createSocket({
                  webSocketRef,
                  url: convertedUrl.current,
                  setReadyState,
                  reconnect: startRef.current,
                  reconnectCount: reconnectCountRef,
                  messageQueue,
                });
              } catch (error) {
                reportErrorToBugsnag({ error, context: `Failed to get JWT Token and create web socket.` });
              }
            };

            startRef.current = () => {
              if (!expectCloseRef.current) {
                if (webSocketProxy.current) webSocketProxy.current = null;
                _start();
              }
            };

            _start();
          } else if (!connect) {
            close();
          }
        },
        delay,
        {
          /**
           * We need to have leading false because without it, the delay wouldn't work and it would fire immediately
           */
          leading: false,
        },
      );

      return throttledFunction();
    }, [close, connect, getAuthToken, readyState]);

    /**
     * When the user re-connects to the internet, we we want to restablish the connection
     */
    useConnectionStatusListener((isConnected) => {
      if (isConnected) {
        start();
      } else {
        close();
      }
    });

    /**
     * On mount, start the connection
     */
    useEffect(() => {
      start();

      return () => {
        expectCloseRef.current = true;
        if (webSocketProxy.current) webSocketProxy.current = null;
      };
    }, [start]);

    useUnmount(() => {
      removeAllSubscriptions();
    });

    useEffect(() => {
      /**
       * When the readyStateFromUrl changes to OPEN, we want to reset the connectAttemptsRef
       * so the delay for the throttled function isn't unnecessarily too long
       */
      if (readyStateFromUrl === ReadyState.OPEN) {
        connectAttemptsRef.current = 0;
      }
    }, [readyStateFromUrl]);

    useEffect(() => {
      if (readyStateFromUrl === ReadyState.OPEN) {
        messageQueue.current.splice(0).forEach((message) => {
          sendMessage(message);
        });
      }
    }, [readyStateFromUrl, sendMessage]);

    const socketValue = useMemo<SocketContextType>(
      () => ({
        readyState,
        getWebSocket,
        sendJsonMessage,
        sendMessage,
      }),
      [readyState, getWebSocket, sendJsonMessage, sendMessage],
    );

    return <SocketContext.Provider value={socketValue}>{children}</SocketContext.Provider>;
  },
);

SocketContextProvider.displayName = 'SocketContextProvider';

export const useWebsocketContext = () => {
  const socketContext = useContext(SocketContext);

  if (socketContext === DEFAULT_SOCKET_CONTEXT_VALUE) {
    console.error('Socket context used outside of its provider!');
  }

  return socketContext;
};
