import { useCallback } from 'react';

import { reportErrorToBugsnag } from '~/utils/ErrorUtils';

export const useRevalidateISR = () => {
  const revalidateISR = useCallback(async ({ path }: { path: string }) => {
    const encodedPath = encodeURIComponent(path);

    try {
      await fetch(`/api/revalidate?path=${encodedPath}`);
    } catch (error) {
      reportErrorToBugsnag({ error, context: 'Error revalidating ISR', metadata: { Data: path } });
    }
  }, []);

  return { revalidateISR };
};
