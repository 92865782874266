import { SelectedFilter } from '~/store/filters/types';

export const AllItemTypeFilters = ['boards', 'assets', 'files'] as const;
export type ItemTypeFilter = (typeof AllItemTypeFilters)[number];

export const isItemTypeFilter = (value: string): value is ItemTypeFilter =>
  !!AllItemTypeFilters.find((filter) => filter === value);

export interface SingleFilterProps {
  onApplyClick: () => void;
  onRemoveClick?: (filter: SelectedFilter) => void;
}
