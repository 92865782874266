import { useTrackClickedUpgradeButton, useTrackOpenIntercomChat } from '@air/analytics';
import { Subscription } from '@air/api/types';
import { Check as CheckIcon } from '@air/next-icons';
import { Button } from '@air/primitive-button';
import { Tooltip } from '@air/primitive-tooltip';
import { useAirModal, useModalContext } from '@air/provider-modal';
import Dinero from 'dinero.js';
import Router from 'next/router';
import { useMemo } from 'react';
import { useMount } from 'react-use';

import { PlanInfoType } from '~/components/Modals/PlansModal/utils';
import { Routes } from '~/constants/routes';
import { useSubscriptionContext } from '~/providers/SubscriptionProvider';
import { subscriptionPlanMap } from '~/utils/SubscriptionUtils';

import { ChilipiperModal } from '../../ChilipiperModal';
import UpgradePlanConfirmationModal from '../../UpgradePlanConfirmationModal';

export interface PlanInfoProps extends PlanInfoType, Pick<Subscription, 'subscribed'>, AirModalProps {
  tagline: string;
  options: string[];
  priceDetail?: string;
  customPriceDescription?: string;
}

/**
 * This component displays plan's data - price and features.
 * It has also 'Upgrade plan' button, which shows upgrade confirmation,
 * or goes to checkout page if subscription does not exist
 */
export const PlanInfo = ({
  subscribed,
  id,
  isCurrentPlan,
  options,
  optionsListHeader,
  tagline,
  prices,
  priceDetail,
  stripePlanIds,
  onClose,
  customPriceDescription,
}: PlanInfoProps) => {
  const { trackOpenIntercomChat } = useTrackOpenIntercomChat();
  const { data: subscription } = useSubscriptionContext();
  const [showUpgradePlanConfirmationModal] = useAirModal(UpgradePlanConfirmationModal);
  const { dispatch: modalDispatch } = useModalContext();
  const [showChilipiperModal] = useAirModal(ChilipiperModal);
  const { trackClickedUpgradeButton } = useTrackClickedUpgradeButton();

  const monthlyFormattedPrice = prices['monthly']
    ? Dinero({ amount: prices['monthly'] }).toFormat(prices['monthly'] % 100 ? '$0,0.00' : '$0,0')
    : 'Free';
  const annualFormattedPrice = prices['annual']
    ? Dinero({ amount: prices['annual'] }).toFormat(prices['annual'] % 100 ? '$0,0.00' : '$0,0')
    : 'Free';
  const isFreePlanOption = id === 'basic';
  const isNotProPlanOption = id !== 'pro';
  const isEnterprisePlanOption = id === 'enterprise';

  useMount(() => {
    Router.prefetch(Routes.workspaceAdmin.checkout);
  });

  const buttonText = useMemo(() => {
    if (isCurrentPlan || (subscription?.plan === 'enterprise' && isEnterprisePlanOption)) {
      return 'Current Plan';
    } else if (
      (subscription?.isOnProPlan && isNotProPlanOption && !isEnterprisePlanOption) ||
      (subscription?.plan === 'enterprise' && !isEnterprisePlanOption) ||
      isFreePlanOption
    ) {
      return 'Downgrade';
    } else if (isEnterprisePlanOption) {
      return 'Contact Sales';
    } else {
      return 'Upgrade';
    }
  }, [
    isCurrentPlan,
    subscription?.isOnProPlan,
    subscription?.plan,
    isNotProPlanOption,
    isFreePlanOption,
    isEnterprisePlanOption,
  ]);

  const isDowngradeButton = buttonText === 'Downgrade';
  const isContactButton = buttonText === 'Contact Sales';

  const onUpgradePlan = () => {
    trackClickedUpgradeButton({ plan: id as 'plus' | 'pro' });
    if (subscribed) {
      showUpgradePlanConfirmationModal({
        priceDetail,
        prices,
        stripePlanIds,
      });
    } else {
      onClose();

      /**
       * Since the plan modal generally overlaps another modal, only the highest level modal is closed.
       * Therefore we need to close all modals with the ModalContext before redirecting to checkout.
       */
      modalDispatch({ type: 'hide-all' });

      Router.push({
        pathname: Routes.workspaceAdmin.checkout,
        search: `planId=${id}`,
      });
    }
  };

  const onDowngrade = () => {
    trackOpenIntercomChat();
  };

  const onCancelSubscription = () => {
    const newWindow = window.open('https://tally.so/r/mJPKKw', '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  };

  const onContactUs = () => {
    showChilipiperModal({});
  };

  const renderDowngradeButton = () => {
    const button = (
      <Button
        appearance="filled"
        color="grey"
        data-testid={`PLANS_MODAL_${id.toUpperCase()}_UPGRADE_BUTTON`}
        onClick={isFreePlanOption ? onCancelSubscription : onDowngrade}
        size="large"
      >
        {buttonText}
      </Button>
    );

    return isFreePlanOption ? button : <Tooltip label="Contact support to downgrade your plan">{button}</Tooltip>;
  };

  const renderUpgradeButton = () => (
    <Button
      appearance={isContactButton ? 'outline' : 'filled'}
      color="blue"
      data-testid={`PLANS_MODAL_${id.toUpperCase()}_UPGRADE_BUTTON`}
      disabled={isCurrentPlan}
      size="large"
      onClick={isContactButton ? onContactUs : onUpgradePlan}
    >
      {buttonText}
    </Button>
  );

  return (
    <div className="relative flex-1 border-b border-grey-5 px-4 py-8 sm:border-b-0 sm:border-r sm:px-5 sm:last-of-type:border-r-0">
      <div className="flex flex-col items-start">
        <h3 className="mb-2 text-start text-24 font-semibold text-grey-12">{subscriptionPlanMap[id]}</h3>
        <p className="min-h-[36px] text-start text-14 text-grey-11">{tagline}</p>
      </div>

      <div className="relative mb-10 mt-3 flex flex-col items-start">
        {customPriceDescription ? (
          <p className="mb-5 mt-2 text-start text-32 font-medium leading-none">{customPriceDescription}</p>
        ) : (
          <>
            <div className="mb-5 mt-2 text-32 font-medium leading-none">{annualFormattedPrice}</div>
            <div className="absolute top-12">
              {isFreePlanOption && <div className="text-start text-12 font-bold text-grey-9">{priceDetail}</div>}
              {!isFreePlanOption && (
                <div className="text-12 font-bold text-grey-9" data-testid={`${id.toUpperCase()}_ANNUAL_PRICE_DETAILS`}>
                  {priceDetail ? `${priceDetail}, ` : ''}billed annually
                </div>
              )}
              {!isFreePlanOption && (
                <div className="text-12 font-bold">
                  <span>{monthlyFormattedPrice}</span>
                  <span className="text-grey-9" data-testid={`${id.toUpperCase()}_MONTHLY_PRICE_DETAILS`}>
                    {priceDetail ? ` ${priceDetail},` : ''} billed monthly
                  </span>
                </div>
              )}
            </div>
          </>
        )}
      </div>

      <div className="flex justify-start">{isDowngradeButton ? renderDowngradeButton() : renderUpgradeButton()}</div>

      <h4 className="mt-10 text-16 font-semibold">{optionsListHeader}</h4>

      <div className="mt-3">
        {options.map((option, index) => (
          <div className="mb-3 flex last-of-type:mb-0" key={index}>
            <CheckIcon className="mb-1 w-5 shrink-0 self-start text-teal-9" />
            <div className="ml-2 text-14 text-grey-11">{option}</div>
          </div>
        ))}
      </div>
    </div>
  );
};
