import { Subscription } from '~/types/sockets';

const DEFAULT_SUBSCRIPTIONS = {};
const DEFAULT_PENDING_SUBSCRIPTIONS = {};

export const SUBSCRIPTIONS: {
  [key: string]: Required<Subscription<any>>;
} = DEFAULT_SUBSCRIPTIONS;

export const PENDING_SUBSCRIPTIONS: {
  [key: string]: Subscription<any>;
} = DEFAULT_PENDING_SUBSCRIPTIONS;
