import { Boards, GetBoardResponse } from '@air/api';
import { BoardsListOptions, SortDirection, SortField } from '@air/api/types';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

import { PageLimits } from '~/constants/PageLimits';
import { getPageBoardKey } from '~/constants/react-query-keys';
import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';

type PrivateBoardsFetcherParams = Partial<Omit<BoardsListOptions, 'includeAncestors'>>;

export const usePrivateBoardsFetcher = () => {
  const queryClient = useQueryClient();
  const { currentWorkspace } = useCurrentWorkspace();
  const workspaceId = currentWorkspace?.id;

  const privateBoardsFetcher = useCallback(
    async <Params extends PrivateBoardsFetcherParams>({
      params: {
        isMemberOfBoard,
        ancestorCutoff,
        limit,
        filters,
        view,
        libraryBoards,
        libraryIds,
        minimalForSync,
        numThumbnails,
        onlyEditable,
        search,
        viewId,
      },
      cursor,
      sortFieldName,
      sortDirection,
    }: {
      params: Params;
      sortFieldName: SortField['name'];
      sortDirection: SortDirection;
      cursor: string | null;
    }) => {
      if (!workspaceId) {
        throw new Error('No workspaceId found');
      }

      const res = await Boards.list({
        isMemberOfBoard,
        ancestorCutoff,
        cursor,
        filters,
        view,
        libraryBoards,
        libraryIds,
        minimalForSync,
        onlyEditable,
        search,
        viewId,
        includeAncestors: true,
        limit: limit ? limit : cursor ? PageLimits.subBoards.more : PageLimits.subBoards.init,
        numThumbnails: numThumbnails ?? 1,
        sortField: {
          direction: sortDirection,
          name: sortFieldName,
        },
        workspaceId,
      });

      res.data.forEach((board) => {
        const cachedBoard = queryClient.getQueryCache().get<GetBoardResponse>(JSON.stringify(getPageBoardKey(board.id)))
          ?.state.data;

        if (!cachedBoard) {
          queryClient.setQueryData<GetBoardResponse>(getPageBoardKey(board.id), board);
        }
      });

      return res;
    },
    [queryClient, workspaceId],
  );

  return {
    privateBoardsFetcher,
  };
};
