import { createSelector } from 'reselect';

import { PermissionsScopes } from '~/store/permissions/types';
import { AirState } from '~/store/types';

export const permissionsStateSelector = ({ permissions }: AirState) => permissions;

export const permissionsScopeSelector = createSelector(
  [permissionsStateSelector, (_st: AirState, scope: PermissionsScopes) => scope],
  (state, scope: PermissionsScopes) => state[scope],
);

export const permissionsSelector = createSelector(
  (st: AirState, _scope: PermissionsScopes, _objectId: string) => st,
  (_st: AirState, scope: PermissionsScopes, _objectId: string) => scope,
  (_st: AirState, _scope: PermissionsScopes, objectId: string) => objectId,
  (state, scope, objectId) => state.permissions[scope][objectId],
);
