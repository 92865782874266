import { Board } from '@air/api/types';
import { Check } from '@air/next-icons';
import { Tooltip } from '@air/primitive-tooltip';
import { useAirModal } from '@air/provider-modal';
import { useCopyToClipboard } from 'react-use';

import { ChilipiperModal } from '~/components/Modals/ChilipiperModal';
import { useIsAdminEnterpriseMember } from '~/hooks/useAdminEnterpriseMember';
import { useCurrentWorkspacePermissionsContext } from '~/providers/CurrentWorkspacePermissionsProvider';
import { canManageSubscription } from '~/utils/permissions/workspacePermissions';

export type BoardIdProps = Required<Pick<Board, 'id'>>;

export const BoardId = ({ id }: BoardIdProps) => {
  const { isAdminEnterpriseMember } = useIsAdminEnterpriseMember();
  const { data: workspacePermissions } = useCurrentWorkspacePermissionsContext();
  const [showContactSalesModal] = useAirModal(ChilipiperModal);
  const [state, copyToClipboard] = useCopyToClipboard();

  const canEditPlan = canManageSubscription(workspacePermissions);

  return (
    <div className="flex flex-col items-start gap-1">
      <div className="text-12 font-medium text-grey-10">Board ID</div>

      <div className="flex gap-1">
        {isAdminEnterpriseMember ? (
          <>
            <Tooltip label="Copy to clipboard">
              <button className="select-all text-12 text-grey-9" onClick={() => copyToClipboard(id)}>
                {id}
              </button>
            </Tooltip>

            {!!state.value && <Check className="h-4 w-4 text-blue-9" />}
          </>
        ) : (
          <div className="text-12 text-grey-9">
            Upgrade to Enterprise to access API info.{' '}
            {canEditPlan ? (
              <button
                className="mr-1 inline text-grey-12 hover:underline"
                onClick={() =>
                  showContactSalesModal({
                    uiLocation: 'asset_modal_api_cta',
                  })
                }
              >
                Contact sales →
              </button>
            ) : (
              'Contact a workspace admin to upgrade.'
            )}
          </div>
        )}
      </div>
    </div>
  );
};
