import { Board } from '@air/api/types';
import { useCallback } from 'react';

import { useBoardPermissionsCache } from '~/hooks/useBoardPermissionsCache';
import { useLibraryPermissionsCache } from '~/hooks/useLibraryPermissionsCache';
import { useCurrentWorkspacePermissionsContext } from '~/providers/CurrentWorkspacePermissionsProvider';

/**
 * This hook takes a board and returns the permissions of the board's container.
 * It first checks if the board has a parentId, and if so, returns the permissions of the parent board.
 * It then checks if the board has a library (it lives in a library), and if so, returns the permissions of the library.
 * If the board has neither a parent nor a library (it's a workspace board), it returns the permissions of the current workspace.
 */
export const useGetBoardContainerPermissions = () => {
  const { data: workspacePermissions } = useCurrentWorkspacePermissionsContext();
  const { getLibraryPermissions } = useLibraryPermissionsCache();
  const { getBoardPermissions } = useBoardPermissionsCache();

  const getBoardContainerPermissions = useCallback(
    ({ board }: { board: Pick<Board, 'workspaceId' | 'library' | 'parentId'> }) =>
      board.parentId
        ? getBoardPermissions(board.parentId)
        : board.library
        ? getLibraryPermissions(board.library.id)
        : workspacePermissions,
    [getBoardPermissions, getLibraryPermissions, workspacePermissions],
  );

  return {
    getBoardContainerPermissions,
  };
};
