import { Button } from '@air/primitive-button';
import { Modal, ModalCloseButton } from '@air/primitive-modal';
import React, { memo } from 'react';

import image from '~/assets/cat-in-box.svg';
import { useFixPlanAction } from '~/hooks/useFixPlanAction';

interface AccountAnnouncementModalProps {
  title: string;
  image: string;
  description: string;
  ctaProps: Partial<ReturnType<typeof useFixPlanAction>>;
  withCloseButton?: boolean;
  'data-testid'?: string;
}

export const AccountAnnouncementModal = memo(
  ({
    onClose,
    title,
    description,
    ctaProps,
    withCloseButton,
    ...restOfProps
  }: AirModalProps<AccountAnnouncementModalProps>) => {
    return (
      <Modal isOpen onDismiss={onClose} {...restOfProps}>
        {withCloseButton && (
          <header className="flex justify-end">
            <ModalCloseButton className="shrink-0" onClick={onClose} />
          </header>
        )}
        <div className="mb-6 flex flex-col gap-2">
          <div className="flex justify-center">
            <img src={image} height="150" alt="Unlock more storage image" />
          </div>
          <p className="text-center text-24 font-semibold text-blue-10">{title}</p>
          <p className="px-6 text-center text-16 text-grey-11">{description}</p>
        </div>
        <div className="flex justify-center">
          <Button appearance="filled" color="blue" size="large" asChild data-testid="ANNOUNCEMENT_MODAL_CTA">
            <a {...ctaProps} />
          </Button>
        </div>
      </Modal>
    );
  },
);

AccountAnnouncementModal.displayName = 'AccountAnnouncementModal';
