import { WorkspaceUserRole } from '@air/api/types';
import { createContext, PropsWithChildren, useContext, useMemo } from 'react';

import { isDevOrTestStage } from '~/swr-hooks/utils';
import { useWorkspaceRoles } from '~/swr-hooks/workspaces/useWorkspaceRoles';
import { reportErrorToBugsnag } from '~/utils/ErrorUtils';

type WorkspaceRolesProviderProps = PropsWithChildren<object>;

export interface WorkspaceRolesProviderContextValue {
  data: WorkspaceUserRole[] | undefined;
  error?: unknown;
}

const defaultValue: WorkspaceRolesProviderContextValue = {
  data: undefined,
};

const WorkspaceRolesProviderContext = createContext<WorkspaceRolesProviderContextValue>(defaultValue);

export const WorkspaceRolesProvider = ({ children }: WorkspaceRolesProviderProps) => {
  const { data, error } = useWorkspaceRoles();

  const value = useMemo(() => ({ data, error }), [data, error]);

  return <WorkspaceRolesProviderContext.Provider value={value}>{children}</WorkspaceRolesProviderContext.Provider>;
};

export const useWorkspaceRolesContext = () => {
  const context = useContext(WorkspaceRolesProviderContext);

  if (context === defaultValue) {
    const error = 'WorkspaceRolesProviderContext used outside of WorkspaceRolesProvider';

    if (isDevOrTestStage()) {
      throw error;
    } else {
      reportErrorToBugsnag({
        error,
        context: error,
      });
    }
  }

  return context;
};
