import { tailwindMerge } from '@air/tailwind-variants';
import * as RadixRadioGroup from '@radix-ui/react-radio-group';
import { ComponentProps } from 'react';

export type RadioGroupItemAlign = 'start' | 'center' | 'end';
export type RadioGroupItemSide = 'top' | 'right' | 'bottom' | 'left';

const RADIO_GROUP_ITEM_ALIGN_CLASS_NAME = {
  start: 'items-start',
  center: 'items-center',
  end: 'items-end',
};

const RADIO_GROUP_ITEM_INDICATOR_ALIGN_CLASS_NAME = {
  start: 'mt-1',
  center: 'my-0',
  end: 'mb-1',
};

const RADIO_GROUP_ITEM_SIDE_CLASS_NAME: { [key in RadioGroupItemSide]: string } = {
  top: 'flex-col',
  right: 'flex-row-reverse',
  bottom: 'flex-col-reverse',
  left: 'flex-row',
};

export type RadioGroupItemProps = Omit<ComponentProps<'label'>, 'id'> &
  Pick<RadixRadioGroup.RadioGroupItemProps, 'disabled' | 'required' | 'value'> &
  Required<Pick<RadixRadioGroup.RadioGroupItemProps, 'id'>> & {
    align?: RadioGroupItemAlign;
    indicatorClassName?: string;
    side?: RadioGroupItemSide;
    sideOffset?: number;
  };

export const RadioGroupItem = ({
  align = 'center',
  children,
  className,
  disabled,
  id,
  indicatorClassName,
  required,
  side = 'left',
  sideOffset = 8,
  style,
  value,
  ...restOfProps
}: RadioGroupItemProps) => {
  return (
    <label
      className={tailwindMerge(
        'flex text-14 font-medium text-grey-12',
        disabled ? 'cursor-not-allowed' : 'cursor-pointer',
        RADIO_GROUP_ITEM_ALIGN_CLASS_NAME[align],
        RADIO_GROUP_ITEM_SIDE_CLASS_NAME[side],
        className,
      )}
      style={{
        gap: sideOffset,
        ...style,
      }}
      htmlFor={id}
      {...restOfProps}
    >
      <RadixRadioGroup.Item
        className={tailwindMerge(
          'group/radio-group-item h-4 w-4 shrink-0 rounded-full border border-grey-9 p-0.5 radix-disabled:border-grey-5 radix-state-checked:border-blue-9 hover:radix-state-checked:border-blue-10 active:radix-state-checked:border-blue-10 disabled:cursor-not-allowed disabled:!border-grey-5',
          disabled ? 'cursor-not-allowed' : 'cursor-pointer',
          RADIO_GROUP_ITEM_INDICATOR_ALIGN_CLASS_NAME[align],
          indicatorClassName,
        )}
        disabled={disabled}
        id={id}
        required={required}
        value={value}
      >
        <RadixRadioGroup.Indicator className="block h-full w-full rounded-full bg-blue-9 group-hover/radio-group-item:bg-blue-10 group-active/radio-group-item:bg-blue-10 group-disabled/radio-group-item:!bg-blue-3" />
      </RadixRadioGroup.Item>
      {children}
    </label>
  );
};
