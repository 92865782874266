import { BoardsListOptions, SortDirection, SubnavBoardSort } from '@air/api/types';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useMemo } from 'react';

import { usePrivateBoardsFetcher } from '~/swr-hooks/gallery/galleryView/usePrivateBoardsFetcher';
import { useSubnavSort } from '~/swr-hooks/subnav/useSubnavSort';

const BOARDS_LIST = 'BOARDS_LIST';

export const getRootWorkspaceBoardsKey = (params: {
  workspaceId?: string;
  sortField?: SubnavBoardSort;
  libraryBoards?: BoardsListOptions['libraryBoards'];
}) => {
  return [BOARDS_LIST, params];
};

export const useRootWorkspaceBoards = (workspaceId?: string, params?: Partial<BoardsListOptions>) => {
  const { privateBoardsFetcher } = usePrivateBoardsFetcher();
  const { data: subnavSort } = useSubnavSort();
  const sortField = subnavSort?.boardSort;
  const { libraries: librariesFlag } = useFlags();
  // if libraries feature is turned off, we should show libraries root boards in workspace boards
  const libraryBoards = librariesFlag ? 'NO' : 'ALL';

  const infiniteQuery = useInfiniteQuery({
    queryKey: getRootWorkspaceBoardsKey({
      workspaceId,
      sortField,
      libraryBoards,
    }),
    queryFn: ({ pageParam }) =>
      privateBoardsFetcher({
        params: {
          isMemberOfBoard: 'ALL',
          limit: 100,
          view: 'root',
          includeAncestors: true,
          numThumbnails: 0,
          sortField,
          libraryBoards,
          ...params,
        },
        sortFieldName: sortField?.name || 'customSort',
        sortDirection: sortField?.direction || SortDirection.desc,
        cursor: pageParam || null,
      }),
    enabled: !!workspaceId && !!sortField,
    getNextPageParam: (lastPage) => lastPage?.pagination?.cursor,
    initialPageParam: '',
  });

  const data = useMemo(() => infiniteQuery.data?.pages.flatMap((d) => d.data) ?? [], [infiniteQuery.data?.pages]);

  return {
    data,
    hasMore: !!infiniteQuery.hasNextPage,
    loadNextPage: infiniteQuery.fetchNextPage,
    isLoadingMore: infiniteQuery.isFetchingNextPage,
    isInitialLoading: infiniteQuery.isLoading,
  };
};
