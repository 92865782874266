import { AirActionTrackingLocation, useTrackRegeneratedShareLink } from '@air/analytics';
import { ShortUrl } from '@air/api';
import { Board } from '@air/api/types';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { reportErrorToBugsnag } from '~/utils/ErrorUtils';

import { getShortIdInformationKey } from './useGetExistingShortIdInformation';

export const useRegenerateShortIdForBoard = () => {
  const queryClient = useQueryClient();
  const { trackRegeneratedShareLink } = useTrackRegeneratedShareLink();

  const regenerateShortIdForBoard = useMutation({
    mutationFn: async ({
      ...rest
    }: Parameters<typeof ShortUrl.regenerateShortIdForBoard>[0] & {
      boardId: Board['id'];
      trackLocation: AirActionTrackingLocation;
    }) => ShortUrl.regenerateShortIdForBoard(rest),
    onSuccess: (shortIdInfo, { boardId, trackLocation }) => {
      queryClient.setQueryData(getShortIdInformationKey({ objectId: boardId, boardContext: undefined }), shortIdInfo);

      trackRegeneratedShareLink({ shortId: shortIdInfo.id, location: trackLocation });

      return shortIdInfo;
    },
    onError: (error, params) => {
      throw reportErrorToBugsnag({
        error,
        context: 'Failed to regenerate short id for board',
        metadata: { data: params },
      });
    },
  });

  return {
    regenerateShortIdForBoard,
  };
};
