import { AirActionTrackingLocation } from '@air/analytics';
import { ShortUrlResponse } from '@air/api/types';
import { TabPanel } from '@air/primitive-tabs';
import { useMemo, useState } from 'react';

import { SharePrivateBoardLinkInfo } from '~/components/SharePrivateBoardModal/SharePrivateBoardLinkInfo';
import { SharePrivateBoardSettings } from '~/components/SharePrivateBoardModal/SharePrivateBoardSettings';
import { SharePrivateContentTabs } from '~/components/SharePrivateContentModal/SharePrivateContentTabs';

export interface SharePrivateBoardEditProps {
  boardId: string;
  trackLocation: AirActionTrackingLocation;
  shortIdInfo: ShortUrlResponse;
}

export const SharePrivateBoardTabs = ({ boardId, trackLocation, shortIdInfo }: SharePrivateBoardEditProps) => {
  const [activeTab, setActiveTab] = useState('link');

  const panels = useMemo<TabPanel[]>(() => {
    return [
      {
        id: 'link',
        label: 'Link',
        content: (
          <SharePrivateBoardLinkInfo
            goToSettings={() => setActiveTab('settings')}
            boardId={boardId}
            shortIdInfo={shortIdInfo}
            trackLocation={trackLocation}
          />
        ),
      },
      {
        id: 'settings',
        label: 'Settings',
        content: <SharePrivateBoardSettings boardId={boardId} shortIdInfo={shortIdInfo} />,
      },
    ];
  }, [boardId, shortIdInfo, trackLocation]);

  return <SharePrivateContentTabs setActiveTab={setActiveTab} activeTab={activeTab} panels={panels} />;
};
