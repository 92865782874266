import { TrackConnectedSlackIntegrationParams } from '@air/analytics';
import { useLocalStorage } from 'react-use';

export const useSlackIntregrationProcessLocation = () => {
  const [slackIntegrationLocation, setSlackIntegrationLocation, removeSlackIntegrationLocation] = useLocalStorage<
    TrackConnectedSlackIntegrationParams['trackLocation']
  >('SLACK_INTEGRATION_PROCESS_LOCATION');

  return { slackIntegrationLocation, setSlackIntegrationLocation, removeSlackIntegrationLocation };
};
