export const PageLimits = {
  clips: {
    init: 72,
    more: 144,
  },
  files: {
    init: 72,
    more: 144,
  },
  subBoards: {
    init: 30,
    more: 40,
  },
};
