import React, { memo, ReactNode } from 'react';

export interface PrivateBoardSearchHeaderProps {
  title: string;
  rightAdornment?: ReactNode;
}

export const PrivateBoardSearchHeader = memo(({ title, rightAdornment }: PrivateBoardSearchHeaderProps) => {
  return (
    <div className="mb-2 flex h-6 content-between items-center">
      <div className="flex-1">
        <h2 className="ml-1 text-12 font-bold uppercase text-pigeon-500">{title}</h2>
      </div>
      {rightAdornment}
    </div>
  );
});

PrivateBoardSearchHeader.displayName = 'PrivateBoardSearch';
