import { WorkspaceMemberType } from '@air/api/types';
import { useMemo } from 'react';

import { useSubscriptionContext } from '~/providers/SubscriptionProvider';
import { useWorkspaceRolesContext } from '~/providers/WorkspaceRolesProvider';
import { isActiveMember, isGuest } from '~/swr-hooks/members/utils/memberUtils';

import { useCurrentWorkspaceMember } from './useCurrentWorkspaceMember';
import { useFetchMembers } from './useFetchMembers';
import { sortMembersByRole } from './utils/sortMembersByRole';

export const useWorkspaceMembers = () => {
  const { data: members } = useFetchMembers();
  const { data: roles } = useWorkspaceRolesContext();
  const { data: subscription } = useSubscriptionContext();
  const { currentWorkspaceMember } = useCurrentWorkspaceMember();

  const allMembers = useMemo(
    () =>
      members && roles
        ? sortMembersByRole(members, roles).filter(
            (member) => member.type === WorkspaceMemberType.member && !member.inactive,
          )
        : [],
    [members, roles],
  );

  const inactiveMembers = useMemo(() => members?.filter((member) => member.inactive) ?? [], [members]);

  const activeMembers = useMemo(() => allMembers?.filter((member) => isActiveMember(member)), [allMembers]);

  const pendingMembers = useMemo(() => allMembers?.filter((member) => member.pending), [allMembers]);

  const billableMembers = useMemo(
    () => allMembers?.filter((member) => member.isBilled && !member.inactive),
    [allMembers],
  );

  const allGuests = useMemo(
    () => (members && roles ? sortMembersByRole(members, roles).filter((member) => isGuest(member)) : []),
    [members, roles],
  );

  const activeGuests = useMemo(() => allGuests?.filter((member) => !member.inactive && !member.pending), [allGuests]);

  const pendingGuests = useMemo(() => allGuests?.filter((member) => member.pending), [allGuests]);

  const billableGuests = useMemo(() => allGuests?.filter((member) => member.isBilled), [allGuests]);

  const sysAdminMembers = useMemo(
    () =>
      (currentWorkspaceMember?.type !== WorkspaceMemberType.sysadmin
        ? []
        : members?.filter((member) => member.type === WorkspaceMemberType.sysadmin)) ?? [],
    [currentWorkspaceMember?.type, members],
  );

  const maxMembers = useMemo(() => subscription?.maxMembers, [subscription]);

  return {
    members: members
      ? {
          allMembers,
          activeMembers,
          inactiveMembers,
          pendingMembers,
          billableMembers,

          allGuests,
          activeGuests,
          pendingGuests,
          billableGuests,

          sysAdminMembers,
          maxMembers,
        }
      : undefined,
  };
};
