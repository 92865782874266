import { ShortUrlResponse } from '@air/api/types';
import { memo, useCallback } from 'react';

import { ShareUrlResponseWithSource } from '~/components/SharePrivateClipModal/types';
import { ShareCommentsPermissions } from '~/components/SharePrivateContentModal/SharePrivateContentSettings/ShareCommentsPermissions';
import { ShareCustomFieldsPermissions } from '~/components/SharePrivateContentModal/SharePrivateContentSettings/ShareCustomFieldsPermissions';
import { ShareSecurityToggles } from '~/components/SharePrivateContentModal/SharePrivateContentSettings/ShareSecurityToggles';
import { ShareVersionsPermissions } from '~/components/SharePrivateContentModal/SharePrivateContentSettings/ShareVersionsPermissions';
import { UpdateShortIdParams } from '~/swr-hooks/shortUrl/useUpdateShortId';

interface SharePrivateContentSettingsProps {
  onShortUrlUpdate: (
    props: DeepPartial<Omit<UpdateShortIdParams, 'id' | 'objectId' | 'boardContext' | 'shortId'>>,
  ) => Promise<ShareUrlResponseWithSource>;
  isLoading: boolean;
  shortIdInfo: ShortUrlResponse;
}

export const SharePrivateContentSettings = memo(
  ({ shortIdInfo, onShortUrlUpdate, isLoading }: SharePrivateContentSettingsProps) => {
    const onPermissionUpdated = useCallback(
      (permissions: DeepPartial<ShortUrlResponse['permissions']>) =>
        onShortUrlUpdate({
          permissions,
        }),
      [onShortUrlUpdate],
    );

    return (
      <div className="flex flex-col gap-8">
        <ShareVersionsPermissions
          isLoading={isLoading}
          shortIdInfo={shortIdInfo}
          onPermissionUpdated={onPermissionUpdated}
        />
        <ShareCommentsPermissions
          isLoading={isLoading}
          shortIdInfo={shortIdInfo}
          onPermissionUpdated={onPermissionUpdated}
        />
        <ShareCustomFieldsPermissions
          isLoading={isLoading}
          shortIdInfo={shortIdInfo}
          onPermissionUpdated={onPermissionUpdated}
        />
        <ShareSecurityToggles isLoading={isLoading} shortIdInfo={shortIdInfo} onShortUrlUpdate={onShortUrlUpdate} />
      </div>
    );
  },
);

SharePrivateContentSettings.displayName = 'SharePrivateContentSettings';
