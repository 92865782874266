import { LibraryMemberWithInfo, WorkspaceMemberResponseWithRoles, WorkspaceUserRole } from '@air/api/types';

export const sortMembersByRole = <T extends WorkspaceMemberResponseWithRoles | LibraryMemberWithInfo>(
  members: T[],
  roles: WorkspaceUserRole[],
) =>
  [...members].sort((a, b) => {
    const aRole = roles.find((r) => r.id === a.roleId);
    const bRole = roles.find((r) => r.id === b.roleId);

    return aRole && bRole ? aRole?.sortOrder - bRole?.sortOrder : 0;
  });
