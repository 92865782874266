import { ClipsListResponse } from '@air/api';
import { Clip } from '@air/api/types';
import { useQueryClient } from '@tanstack/react-query';
import produce from 'immer';
import { useCallback } from 'react';

import { getVersionsListKey } from '~/constants/react-query-keys';

export const useAddAssetVersion = () => {
  const queryClient = useQueryClient();

  const addAssetVersion = useCallback(
    (newVersion: Clip) => {
      queryClient.setQueryData<ClipsListResponse<Clip>>(getVersionsListKey({ assetId: newVersion.assetId }), (data) =>
        data
          ? produce(data, (draft) => {
              draft.data.clips.unshift({
                ...newVersion,
                assetVersionCount: draft.data.clips.length + 1,
                version: draft.data.clips.length + 1,
              });
              draft.data.total++;
            })
          : {
              data: {
                clips: [
                  {
                    ...newVersion,
                    assetVersionCount: 1,
                    version: 1,
                  },
                ],
                total: 1,
              },
              pagination: {
                cursor: null,
                hasMore: false,
              },
            },
      );
    },
    [queryClient],
  );

  return {
    addAssetVersion,
  };
};
