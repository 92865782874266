import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';
import META from './useTrackToggledAnonymousAccountRequirementOn.meta';

export interface TrackToggledAnonymousAccountRequirementOnParams {
  share_url: string;
  object_id: string;
  object_type: 'clip' | 'board';
}

export const useTrackToggledAnonymousAccountRequirementOn = () => {
  const { track } = useAnalyticsUtilities();

  const trackToggledAnonymousAccountRequirementOn = useCallback(
    (params: TrackToggledAnonymousAccountRequirementOnParams) => {
      track(META.name, params);
    },
    [track],
  );

  return {
    trackToggledAnonymousAccountRequirementOn,
  };
};
