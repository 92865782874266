import { Check } from '@air/next-icons';
import { useCallback } from 'react';

import { SelectChipOptionContainer } from '~/components/Select/components/SelectChipOptionContainer';
import { ChipRenderer, ListItemRenderer, Select, SelectProps } from '~/components/Select/Select';
import { DefaultChipType } from '~/components/Select/shared/types';
import { SINGLE_SELECT_OPTION, SINGLE_SELECT_VALUE } from '~/constants/testIDs';

export interface SingleSelectProps<T extends DefaultChipType> extends Omit<SelectProps<T>, 'selectedOptions'> {
  selectedOption?: T;
}

export const SingleSelect = <T extends DefaultChipType>({ selectedOption, ...props }: SingleSelectProps<T>) => {
  const listItemRenderer = useCallback<ListItemRenderer<T>>(
    (chip) => {
      const isSelected = selectedOption?.value === chip.value;

      return (
        <SelectChipOptionContainer>
          <div className="flex flex-1">
            <span data-testid={`${SINGLE_SELECT_OPTION}-${chip.value}`} key={chip.value} className="flex-1 p-1 text-14">
              {chip.label}
            </span>
            {isSelected && <Check width={14} />}
          </div>
        </SelectChipOptionContainer>
      );
    },
    [selectedOption],
  );

  const chipRenderer = useCallback<ChipRenderer<T>>((chip) => {
    return (
      <div className="flex w-full justify-between px-1.5 py-1">
        <div className="truncate text-14 text-grey-11" data-testid={SINGLE_SELECT_VALUE}>
          {chip.label}
        </div>
      </div>
    );
  }, []);

  return (
    <Select
      isSearchable={false}
      isSingleSelect
      renderAsInput
      showTriangle
      closeMenuOnSelect={true}
      chipRenderer={chipRenderer}
      listItemRenderer={listItemRenderer}
      filterFunc={(items) => items}
      selectedOptions={selectedOption ? [selectedOption] : []}
      {...props}
    />
  );
};
