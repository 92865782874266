import { Spinner } from '@air/primitive-spinner';
import React, { memo } from 'react';

import { PRIVATE_CONTENT_SPINNER_TEST_ID } from '~/constants/testIDs';

export const SharePrivateContentSpinner = memo(() => {
  return (
    <div data-testid={PRIVATE_CONTENT_SPINNER_TEST_ID} className="flex h-10 items-center justify-center">
      <Spinner className="mx-auto text-pigeon-300" />
    </div>
  );
});

SharePrivateContentSpinner.displayName = 'SharePrivateContentSpinner';
