import { Check } from '@air/next-icons';
import { tailwindVariants, VariantProps } from '@air/tailwind-variants';
import * as RadixCheckbox from '@radix-ui/react-checkbox';
import { forwardRef } from 'react';

export const checkbox = tailwindVariants({
  base: 'rounded-sm border border-grey-8 bg-grey-1 radix-state-checked:border-blue-9 radix-state-checked:bg-blue-9 radix-state-checked:text-white hover:radix-state-checked:bg-blue-10 disabled:border-grey-5 disabled:bg-grey-2 disabled:radix-state-checked:border-grey-7 disabled:radix-state-checked:bg-grey-7',
  variants: {
    size: {
      small: 'h-3 w-3',
      medium: 'h-4 w-4',
      large: 'h-5 w-5',
      'extra-large': 'h-6 w-6',
    },
  },
  defaultVariants: {
    size: 'medium',
  },
});

export type CheckboxVariants = VariantProps<typeof checkbox>;

export type CheckboxProps = RadixCheckbox.CheckboxProps & CheckboxVariants;

export const Checkbox = forwardRef<HTMLButtonElement, CheckboxProps>(
  ({ className, size, ...restOfProps }, forwardedRef) => {
    return (
      <RadixCheckbox.Root className={checkbox({ size, class: className })} ref={forwardedRef} {...restOfProps}>
        <RadixCheckbox.Indicator>
          <Check />
        </RadixCheckbox.Indicator>
      </RadixCheckbox.Root>
    );
  },
);

Checkbox.displayName = 'Checkbox';
