import {
  LeadFormUILocation,
  trackGTMEvent,
  useTrackCalendarBooking,
  useTrackClickedLeadFormSubmit,
} from '@air/analytics';
import { FormikField } from '@air/formik-field';
import { Button } from '@air/primitive-button';
import { Input } from '@air/primitive-input';
import { Modal, ModalCloseButton, ModalDescription, ModalTitle } from '@air/primitive-modal';
import { Formik } from 'formik';
import Head from 'next/head';
import { object, string } from 'yup';

import Form from '~/components/Form';
import { FormikSingleSelect } from '~/components/Formik/FormikSingleSelect';
import { Routes } from '~/constants/routes';

const validationSchema = object({
  firstName: string().default('').required('First name is required'),
  lastName: string().default('').required('Last name is required'),
  email: string()
    .email()
    .test(
      'Please enter a business email',
      'Please enter a business email',
      (value) =>
        !value
          ?.toLowerCase()
          ?.match(
            /[a-zA-Z0-9]{0,}([.]?[a-zA-Z0-9]{1,})[@](aol.com|comcast.net|gmail.com|gmail.co|gmail.to|gmx.com|hotmail.com|icloud.com|live.com|mac.com|mail.ck|mail.com|mailbird.com|me.com|msn.com|mymail.com|outlook.com|protonmail.com|postbox.com|rocketmail.com|sbcglobal.net|taskmonk.ai|test.com|thunderbird.com|turtlewig.com|zoho.com|yahoo.com|yandex.mail)/g,
          ),
    )
    .test(
      'Please enter a personal business email',
      'Please enter a personal business email',
      (value) => !value?.toLowerCase()?.match(/(info|hello)[@].*/g),
    )
    .required('Email address is required')
    .default(''),
  company_size: string().default('').required('Company Size is required'),
  role: string().default('').required('Role is required'),
  industry: string().default('').required('Industry is required'),
});

export interface ChilipiperModalProps {
  uiLocation?: LeadFormUILocation;
}

export const ChilipiperModal = ({ onClose, uiLocation }: AirModalProps<ChilipiperModalProps>) => {
  const { trackCalendarBooking } = useTrackCalendarBooking();
  const { trackClickedLeadFormSubmit } = useTrackClickedLeadFormSubmit();

  return (
    <Modal
      className="flex flex-col gap-4"
      dangerouslyBypassFocusLock
      dangerouslyBypassScrollLock
      isOpen
      onDismiss={onClose}
    >
      <Head>
        <script key="chilipiper" src="https://js.na.chilipiper.com/marketing.js" type="text/javascript" async />
      </Head>

      <header className="flex flex-col gap-2">
        <div className="flex justify-between gap-2">
          <ModalTitle>Let&apos;s talk</ModalTitle>
          <ModalCloseButton onClick={onClose} />
        </div>
        <ModalDescription>Schedule a free demo today. One of our experts will show you how Air works.</ModalDescription>
      </header>

      <Formik
        initialValues={validationSchema.cast(undefined)!}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          trackClickedLeadFormSubmit({ email: values.email, ui_location: uiLocation });
          const url = `${window.location.protocol}//${window.location.host}${window.location.pathname}`;

          trackGTMEvent({
            event: `Lead Form Submit`,
            eventProps: { label: 'lead form submit', category: 'enterprise', ...values },
          });

          window.ChiliPiper.submit('air-inc', 'router-11-17-2021', {
            lead: {
              ...values,
              leadsource: 'Web Form - App',
              leadsourceurl: url,
            },
            map: true,
            onSuccess: () => {
              trackCalendarBooking();
              trackGTMEvent({
                event: `Calendar Booking`,
                eventProps: {
                  label: 'calendar booking',
                  category: 'enterprise',
                  leadsource: 'Web Form - App',
                  leadsourceurl: url,
                },
              });
              setSubmitting(false);
              resetForm();
            },
          });
        }}
        validationSchema={validationSchema}
      >
        <Form className="flex flex-col gap-6">
          <div className="flex flex-col gap-4 md:flex-row">
            <FormikField
              className="w-full"
              component={
                <Input
                  autoComplete="given-name"
                  data-1p-ignore
                  placeholder="First name"
                  required
                  size="large"
                  type="text"
                />
              }
              id="firstName"
              label="First name"
              name="firstName"
            />
            <FormikField
              className="w-full"
              component={
                <Input
                  autoComplete="family-name"
                  data-1p-ignore
                  placeholder="Last name"
                  required
                  size="large"
                  type="text"
                />
              }
              id="firstName"
              label="Last name"
              name="lastName"
            />
          </div>

          <FormikField
            className="w-full"
            component={
              <Input
                autoComplete="email"
                data-1p-ignore
                placeholder="Business email"
                required
                size="large"
                type="email"
              />
            }
            id="email"
            label="Business email"
            name="email"
          />
          <FormikSingleSelect
            label="Company size *"
            name="company_size"
            options={[
              {
                label: '1-10',
                value: '1-10',
              },
              {
                label: '11-50',
                value: '11-50',
              },
              {
                label: '51-100',
                value: '51-100',
              },
              {
                label: '101-500',
                value: '101-500',
              },
              {
                label: '501-1000',
                value: '501-1000',
              },
              {
                label: '1001+',
                value: '1001+',
              },
            ]}
            placeholder="Company size"
            size="large"
          />
          <FormikSingleSelect
            label="Your role *"
            name="role"
            options={[
              {
                label: 'Marketing',
                value: 'Marketing',
              },
              {
                label: 'Creative',
                value: 'Creative',
              },
              {
                label: 'Operations',
                value: 'Operations',
              },
              {
                label: 'Executive Leadership',
                value: 'Executive Leadership',
              },
              {
                label: 'Account / Project Management ',
                value: 'Account / Project Management ',
              },
              {
                label: 'Other',
                value: 'Other',
              },
            ]}
            placeholder="Your role"
            size="large"
          />
          <FormikSingleSelect
            label="Industry *"
            name="industry"
            options={[
              {
                label: 'E-commerce / Direct to Consumer',
                value: 'E-commerce / Direct to Consumer',
              },
              {
                label: 'Agency',
                value: 'Agency',
              },
              {
                label: 'Education',
                value: 'Education',
              },
              {
                label: 'Sports and Athletics',
                value: 'Sports and Athletics',
              },
              {
                label: 'Non-Profit ',
                value: 'Non-Profit ',
              },
              {
                label: 'Media and Entertainment',
                value: 'Media and Entertainment',
              },
              {
                label: 'Other',
                value: 'Other',
              },
            ]}
            placeholder="Industry"
            size="large"
          />
          <Button appearance="filled" color="blue" size="large" type="submit">
            Submit
          </Button>
        </Form>
      </Formik>
      <p className="text-center text-14 text-grey-11">
        By submitting the form, you agree to our <a href={Routes.marketing.terms}>Terms of Service</a> and acknowledge
        our <a href={Routes.marketing.privacy}>Privacy Policy</a>.
      </p>
    </Modal>
  );
};
