import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';
import { GeneralLibrary } from '~/utils/librariesUtils';

export const useGeneralLibraryTitle = () => {
  const { currentWorkspace } = useCurrentWorkspace();

  const generalLibraryTitle = currentWorkspace ? currentWorkspace.defaultLibrary ?? GeneralLibrary.title : undefined;

  return {
    generalLibraryTitle,
  };
};
