import { Members } from '@air/api';
import { WorkspaceMemberType } from '@air/api/types';
import { useQuery } from '@tanstack/react-query';
import invariant from 'tiny-invariant';

import { useCurrentWorkspacePermissionsContext } from '~/providers/CurrentWorkspacePermissionsProvider';
import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';
import { canSeeWorkspaceMembers } from '~/utils/permissions/workspacePermissions';

export const MEMBERS_LIST = 'MEMBERS_LIST';

export const getWorkspaceMembersKey = (workspaceId?: string) => {
  return [MEMBERS_LIST, { workspaceId }];
};

export const useGetWorkspaceMembers = () => {
  const { currentWorkspace } = useCurrentWorkspace();
  const { data: permissions } = useCurrentWorkspacePermissionsContext();

  return useQuery({
    queryKey: getWorkspaceMembersKey(currentWorkspace?.id),

    queryFn: async () => {
      const workspaceId = currentWorkspace?.id;
      invariant(workspaceId, 'No workspace id');
      const { members } = await Members.list({
        workspaceId,
        options: {
          memberTypes: [WorkspaceMemberType.sysadmin, WorkspaceMemberType.member, WorkspaceMemberType.guest],
        },
      });
      return members;
    },
    staleTime: Infinity,
    enabled: !!currentWorkspace && canSeeWorkspaceMembers(permissions),
  });
};
