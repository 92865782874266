import { TURN_ON_NOTIFICATIONS_PROMPT_SHOWN_KEY } from '~/constants/localStorageKeys';

const getTurnOnNotificationsPromptShownKey = ({ email, workspaceId }: { email: string; workspaceId: string }) => {
  return `${TURN_ON_NOTIFICATIONS_PROMPT_SHOWN_KEY}-${email}-${workspaceId}`;
};

export const saveTurnOnNotificationsPromptShown = (params: { email: string; workspaceId: string }) => {
  const key = getTurnOnNotificationsPromptShownKey(params);
  localStorage.setItem(key, 'true');
};

export const wasTurnOnNotificationsPromptShown = (params: { email: string; workspaceId: string }) => {
  const key = getTurnOnNotificationsPromptShownKey(params);
  return !!localStorage.getItem(key);
};
