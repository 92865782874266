import { useEffect, useRef, useState } from 'react';

import { PENDING_SUBSCRIPTIONS, SUBSCRIPTIONS } from '~/constants/sockets';
import { convertSubscriptionsToPending } from '~/utils/sockets/convert-subscriptions-to-pending';

import { SendJsonMessage } from './shared/typings';

export interface useResubscribeOnWorkspaceChangeParams {
  isConnected: boolean;
  sendJsonMessage: SendJsonMessage;
  currentWorkspaceId: string | undefined;
}

// Because all subscriptions are workspace based, when the current workspace
// changes we need to unsubscribe from all of the events from the previous workspace
// and re-subscribe to them in the new one.
export const useResubscribeOnWorkspaceChange = ({
  isConnected,
  sendJsonMessage,
  currentWorkspaceId,
}: useResubscribeOnWorkspaceChangeParams) => {
  const [resubscribeState, setResubscribeState] = useState<'WAITING' | 'READY'>('WAITING');
  const connectedWorkspaceIdRef = useRef<string | null>(null);
  const currentWorkspaceIdRef = useRef<string | undefined>(currentWorkspaceId);

  useEffect(() => {
    currentWorkspaceIdRef.current = currentWorkspaceId;
    if (isConnected && !!currentWorkspaceId) {
      if (connectedWorkspaceIdRef.current !== currentWorkspaceId) {
        if (!connectedWorkspaceIdRef.current) {
          connectedWorkspaceIdRef.current = currentWorkspaceId;
        } else {
          setResubscribeState('READY');
        }
      }
    }
  }, [isConnected, currentWorkspaceId]);

  useEffect(() => {
    const hasPending = !!Object.keys(PENDING_SUBSCRIPTIONS).length;
    const hasActive = !!Object.keys(SUBSCRIPTIONS).length;

    if (!!currentWorkspaceIdRef.current && resubscribeState === 'READY' && (hasActive || hasPending)) {
      const newWsId = currentWorkspaceIdRef.current;
      // 1. Send unsubscribe messages for all active subscriptions.
      Object.keys(SUBSCRIPTIONS).forEach((subscriptionId) => {
        sendJsonMessage({
          method: 'unsubscribe',
          args: {
            subscriptionId: subscriptionId,
          },
        });
      });
      // 2. Convert subscriptions to pending subscriptions.
      convertSubscriptionsToPending();
      Object.keys(PENDING_SUBSCRIPTIONS).forEach((uuid) => {
        if (PENDING_SUBSCRIPTIONS[uuid]) {
          // 3. Update the workspaceId of all pending subscriptions.
          PENDING_SUBSCRIPTIONS[uuid].workspaceId = newWsId;
          // 4. Send subscribe messages for all pending subscriptions.
          sendJsonMessage({
            method: 'subscribe',
            id: uuid,
            args: {
              eventType: PENDING_SUBSCRIPTIONS[uuid].event.eventType,
              pattern: { ...PENDING_SUBSCRIPTIONS[uuid].event.pattern, workspaceId: newWsId },
            },
          });
        }
      });
      setResubscribeState('WAITING');
      if (!!currentWorkspaceIdRef.current) {
        connectedWorkspaceIdRef.current = currentWorkspaceIdRef.current;
      }
    }
  }, [sendJsonMessage, resubscribeState]);
};
