import { PENDING_SUBSCRIPTIONS, SUBSCRIPTIONS } from '~/constants/sockets';
import { Subscription } from '~/types/sockets';

const EMPTY_LIST: Subscription<any>[] = [];

export const getIterableSubscriptions = (isPending?: boolean): Subscription<any>[] => {
  const subscriptionKeys = Object.keys(isPending ? PENDING_SUBSCRIPTIONS : SUBSCRIPTIONS);

  if (!subscriptionKeys.length) {
    return EMPTY_LIST;
  }

  return subscriptionKeys.map((key) => (isPending ? PENDING_SUBSCRIPTIONS[key] : SUBSCRIPTIONS[key]));
};
