import { AirActionTrackingLocation } from '@air/analytics';
import { Board } from '@air/api/types';
import { FormikField } from '@air/formik-field';
import { Button } from '@air/primitive-button';
import { Input } from '@air/primitive-input';
import { Modal, ModalCloseButton, ModalTitle } from '@air/primitive-modal';
import { Textarea } from '@air/primitive-textarea';
import { Formik, useField } from 'formik';
import { memo } from 'react';
import * as Yup from 'yup';

import { BoardId } from '~/components/EditPrivateBoardModal/EditBoardModal/BoardId/BoardId';
import Form from '~/components/Form';
import { EDIT_BOARD_INPUT } from '~/constants/testIDs';
import { useShowSubscriptionExpiredModal } from '~/hooks/useShowSubscriptionExpiredModal';
import { useUpdateBoard } from '~/swr-hooks/boards/useUpdateBoard';
import { useBoardSlackIntegrations } from '~/swr-hooks/slack/useBoardSlackIntegrations';
import { convertUnknownToError } from '~/utils/ErrorUtils';
import { containsRestrictedPathChars } from '~/utils/FileUtils';

import { SlackIntegration } from './SlackIntegration/SlackIntegration';

export interface EditBoardModalProps {
  board: Board;
  trackLocation: AirActionTrackingLocation;
}

const EditBoardSchema = Yup.object({
  title: Yup.string()
    .trim()
    .max(255, 'Cannot be longer than 255 characters')
    .test(
      'restricted-char-validation',
      'Characters : and | cannot be used',
      (val) => !containsRestrictedPathChars(val || ''),
    )
    .required('This field is required'),
  description: Yup.string(),
});

const DescriptionTextArea = memo(() => {
  const [field] = useField('description');

  return <Textarea size="large" {...field} />;
});
DescriptionTextArea.displayName = 'DescriptionTextArea';

export const EditBoardModal = ({ board, onClose, trackLocation }: AirModalProps<EditBoardModalProps>) => {
  const { description, title } = board;

  const { data: slackIntegrationData } = useBoardSlackIntegrations(board.id);

  const { showingSubscriptionExpiredModal } = useShowSubscriptionExpiredModal({ onClose });
  const { updateBoard } = useUpdateBoard();

  if (showingSubscriptionExpiredModal) {
    return null;
  }

  return (
    <Formik
      validationSchema={EditBoardSchema}
      onSubmit={async ({ title, description }, { setErrors }) =>
        updateBoard.mutate(
          { board, values: { title, description }, trackLocation },
          {
            onError: (error) => {
              const _error = convertUnknownToError(error);

              setErrors({ title: _error.message });
            },
            onSuccess: () => {
              onClose();
            },
          },
        )
      }
      initialValues={{ title, description: description || '' }}
      render={(FormikProps) => (
        <Modal
          className="flex flex-col gap-4"
          dangerouslyBypassFocusLock
          dangerouslyBypassScrollLock
          data-testid="EDIT_BOARD_DETAILS_MODAL"
          isOpen
          onDismiss={onClose}
        >
          <header className="flex justify-between gap-2">
            <ModalTitle>Edit details</ModalTitle>
            <ModalCloseButton onClick={onClose} />
          </header>

          <Form className="flex flex-col gap-6" id="edit-board" data-testid={EDIT_BOARD_INPUT}>
            <FormikField component={<Input autoFocus size="large" />} id="title" label="Board name" name="title" />

            <FormikField component={<Textarea />} id="description" label="Description (Optional)" name="description" />
          </Form>
          {!!slackIntegrationData && <SlackIntegration integration={slackIntegrationData[0]} board={board} />}

          {!!board.id && <BoardId id={board.id} />}
          <footer className="flex items-center justify-end gap-2">
            <Button appearance="ghost" color="grey" onClick={onClose} size="large">
              Cancel
            </Button>
            <Button
              appearance="filled"
              color="blue"
              data-testid="EDIT_BOARD_MODAL_SAVE_BUTTON"
              disabled={!FormikProps.isValid}
              form="edit-board"
              isLoading={updateBoard.isPending}
              size="large"
              type="submit"
            >
              Save
            </Button>
          </footer>
        </Modal>
      )}
    />
  );
};
