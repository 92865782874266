import { AirActionTrackingLocation } from '@air/analytics';
import { ShortUrlObjectType, ShortUrlResponse } from '@air/api/types';
import { useAirModal } from '@air/provider-modal';
import { formatFullName } from '@air/utilities';
import { memo, useCallback } from 'react';

import { SharePrivateBoardModal } from '~/components/SharePrivateBoardModal/SharePrivateBoardModal';
import { SharePrivateBoardRegenerate } from '~/components/SharePrivateBoardModal/SharePrivateBoardRegenerate';
import { EditableSharePrivateLink } from '~/components/SharePrivateContentModal/EditableSharePrivateLink';
import { useShowTurnOnShareLinkNotifications } from '~/hooks/turnOnEmailShareNotificationsToast/useShowTurnOnShareLinkNotifications';
import { useWorkspaceMembers } from '~/swr-hooks/members/useWorkspaceMembers';

interface EditableBoardSharePrivateLinkProps {
  shortIdInfo: ShortUrlResponse;
  boardId: string;
  trackLocation: AirActionTrackingLocation;
}

export const EditableBoardSharePrivateLink = memo(
  ({ boardId, trackLocation, shortIdInfo }: EditableBoardSharePrivateLinkProps) => {
    const [showRegenerateModal] = useAirModal(SharePrivateBoardRegenerate);
    const [showShareBoardModal, hideModal] = useAirModal(SharePrivateBoardModal);
    const { showTurnOnShareLinkNotifications } = useShowTurnOnShareLinkNotifications();
    const { members } = useWorkspaceMembers();
    const createdBy = members?.allMembers.find((member) => member.accountId === shortIdInfo.createdBy);

    const onRegenerateClick = useCallback(() => {
      hideModal();

      const showSharePrivateBoardModal = () => {
        showShareBoardModal({
          boardId,
          trackLocation,
        });
      };

      showRegenerateModal({
        boardId,
        shortIdInfo,
        trackLocation,
        onRegenerated: () => {
          showSharePrivateBoardModal();
          showTurnOnShareLinkNotifications({
            onManageClick: hideModal,
          });
        },
        onCancel: showSharePrivateBoardModal,
      });
    }, [
      boardId,
      hideModal,
      shortIdInfo,
      showRegenerateModal,
      showShareBoardModal,
      showTurnOnShareLinkNotifications,
      trackLocation,
    ]);

    return (
      <EditableSharePrivateLink
        createdAt={shortIdInfo.createdAt}
        createdBy={createdBy && formatFullName(createdBy.firstName, createdBy.lastName)}
        objectId={boardId}
        trackLocation={trackLocation}
        shareId={shortIdInfo.id}
        objectType={ShortUrlObjectType.board}
        onRegenerate={onRegenerateClick}
      />
    );
  },
);

EditableBoardSharePrivateLink.displayName = 'EditableBoardSharePrivateLink';
