import { Button } from '@air/primitive-button';
import { memo } from 'react';

interface RegenerateShareLinkButtonsProps {
  onClose: () => void;
  onRegenerate: () => void;
  isLoading: boolean;
}

export const RegenerateShareLinkButtons = memo(
  ({ onRegenerate, onClose, isLoading }: RegenerateShareLinkButtonsProps) => {
    return (
      <div className="flex justify-end gap-3">
        <Button appearance="ghost" color="grey" size="large" onClick={onClose} disabled={isLoading}>
          Cancel
        </Button>
        <Button appearance="filled" isLoading={isLoading} color="blue" size="large" onClick={onRegenerate}>
          Confirm
        </Button>
      </div>
    );
  },
);

RegenerateShareLinkButtons.displayName = 'RegenerateShareLinkButtons';
