import { Slack as SlackIcon } from '@air/next-icons';
import { memo } from 'react';

import { displaySlackChannelName } from '../utils/displaySlackChannelName';

interface ConnectedSlackChannelProps {
  channelName: string;
}

export const ConnectedSlackChannel = memo(({ channelName }: ConnectedSlackChannelProps) => {
  return (
    <div className="flex h-12 flex-col justify-center border-0 bg-grey-3 p-3 text-grey-11">
      <div className="flex items-center">
        <SlackIcon className="mr-1 mt-0.5 h-4 w-4" />
        <div className="text-14 font-normal text-inherit">Connected to Slack</div>
      </div>
      <div className="ml-5 text-12 font-normal text-grey-10">{displaySlackChannelName(channelName)}</div>
    </div>
  );
});

ConnectedSlackChannel.displayName = 'ConnectedSlackChannel';
