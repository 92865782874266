import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';
import META from './useTrackToggledAnonymousAccountRequirementOff.meta';

export interface TrackToggledAnonymousAccountRequirementOffParams {
  share_url: string;
  object_id: string;
  object_type: 'clip' | 'board';
}

export const useTrackToggledAnonymousAccountRequirementOff = () => {
  const { track } = useAnalyticsUtilities();

  const trackToggledAnonymousAccountRequirementOff = useCallback(
    (params: TrackToggledAnonymousAccountRequirementOffParams) => {
      track(META.name, params);
    },
    [track],
  );

  return {
    trackToggledAnonymousAccountRequirementOff,
  };
};
