import { Clip } from '@air/api/types';
import { TXProp } from '@air/zephyr';
import { CSSProperties, memo, MouseEvent as ReactMouseEvent, useState } from 'react';

import { FileExtensionPreviewSize } from '~/components/FileExtensionPreview';
import { ImgixImage } from '~/components/ImgixImage';
import { getCloudFrontUrlFromImgixUrl } from '~/constants/cloudfront';
import { ClipIconSize } from '~/types/clip-icon-size';
import ClipUtils, { getAssetPreviewType, WebClipType } from '~/utils/ClipUtils';

import { NO_PREVIEW_AVAILABLE, NoPreviewImage } from '../../../../UI/NoPreviewImage';
import { Container } from './ui';

interface GetNoPreviewTextOptions {
  ext: Clip['ext'] | null;
  status: Clip['status'] | null;
  webClipType?: WebClipType;
  shouldTruncate?: boolean;
}

/**
 * Get the appropriate preview text for a clip based on its fields
 */
const getNoPreviewText = ({ ext, status, webClipType, shouldTruncate = false }: GetNoPreviewTextOptions) => {
  const getNonMediaPreviewText = (ext: Clip['ext'] | null) => ext || '';

  const truncatePreviewText = (text: string) => {
    return text.length < 8 ? text : '…';
  };

  if (webClipType === 'file' || ClipUtils.isNonTranscodable(status)) {
    const previewText = getNonMediaPreviewText(ext);
    return shouldTruncate ? truncatePreviewText(previewText) : previewText;
  }

  if (ClipUtils.isFailed(status)) {
    return NO_PREVIEW_AVAILABLE;
  }

  return '';
};

interface ClipThumbnailProps extends Pick<Clip, 'status' | 'type' | 'ext' | 'description' | 'importedName'> {
  /** URI for the image to be displayed in the clip thumbnail */
  src: string;
  /** URI for the animated image to be displayed on hover for a clip e.g an animated preview on video hover */
  animatedSrc?: string;

  /** Size of the icon to be used in the NoPreviewImage fallback component */
  iconSize?: ClipIconSize;
  /** Border radius to be used in the NoPreviewImage fallback component */
  borderRadius?: number;
  /** Denotes whether or not the NoPreviewImage fallback component should include a text display */
  hideText?: boolean;
  style?: CSSProperties;
  size?: FileExtensionPreviewSize;
  index?: number;
  onClick?: (e: ReactMouseEvent) => void;
  height?: number;
  width?: number;
  tx?: TXProp;
  videoUrl?: Clip['assets']['video'];
}

/** Displays preview image for a clip.
 * If no preview is available a NoPreviewImage component is rendered with styles and metadata appropriate for the clip's WebClipType
 * */
export const ClipThumbnail = memo(
  ({
    status,
    src,
    animatedSrc,
    type,
    ext,
    description,
    borderRadius = 6,
    iconSize = 48,
    hideText = false,
    size,
    height = 400,
    width = 400,
    ...rest
  }: ClipThumbnailProps) => {
    const [isLoaded, setLoaded] = useState(false);
    const alt = description || '';
    const processing = ClipUtils.isProcessing(status);
    const webClipType = getAssetPreviewType({ type, ext });

    if (!src && (ClipUtils.isTranscodeFinished(status) || ClipUtils.isProcessing(status))) {
      return (
        <NoPreviewImage
          processing={processing}
          iconSize={iconSize}
          borderRadius={borderRadius}
          hideText={hideText || (processing && iconSize <= ClipIconSize.small)}
          type={webClipType}
          customText={getNoPreviewText({ ext, status, webClipType, shouldTruncate: true })}
          ext={ext}
          size={size}
          color={processing ? 'gray' : 'color'}
          {...rest}
        />
      );
    }

    const fallbackUrl = getCloudFrontUrlFromImgixUrl(src);

    return (
      <Container role="button" isLoaded={isLoaded} borderRadius={borderRadius} {...rest}>
        {animatedSrc ? (
          // eslint-disable-next-line @next/next/no-img-element
          <img alt={alt} src={animatedSrc} />
        ) : (
          <ImgixImage
            alt={alt}
            fallbackUrl={fallbackUrl}
            src={src}
            imgixParams={{
              auto: 'compress',
              fit: 'crop',
            }}
            width={width}
            height={height}
            onLoad={() => setLoaded(true)}
          />
        )}
      </Container>
    );
  },
);

ClipThumbnail.displayName = 'ClipThumbnail';
