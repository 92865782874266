import { VisibleColumnType } from '@air/api/types';
import { Upload } from '@air/redux-uploader';

import { DndItemType, DndSortableKanbanItemData } from '~/types/DndKit';

export const convertUploadToKanbanItemData = (upload: Upload): DndSortableKanbanItemData => ({
  sortableKanbanItemKey: `kanban-upload-${upload.id}`,
  dndType: DndItemType.kanbanUpload,
  itemId: upload.id,
  currentKanbanColumnId: VisibleColumnType.unassignedCustomFieldValue,
  apiData: {
    id: upload.id,
    type: 'asset',
    data: upload,
  },
});
