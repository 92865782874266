import { type WorkspaceListResponse } from '@air/api/types';
import { useSysadmin } from '@air/sysadmin';
import { isEqual } from 'lodash';
import { createContext, MutableRefObject, ReactNode, useContext, useMemo, useRef } from 'react';

import { isDevOrTestStage } from '~/swr-hooks/utils';
import { useWorkspace } from '~/swr-hooks/workspaces/useWorkspace';
import { useWorkspaces } from '~/swr-hooks/workspaces/useWorkspaces';
import { reportErrorToBugsnag } from '~/utils/ErrorUtils';
import { getLastUsedWorkspace } from '~/utils/WorkspaceUtils';

interface CurrentWorkspaceProviderProps {
  children: ReactNode;
}

type CurrentWorkspaceProviderContextValue = {
  currentWorkspace: WorkspaceListResponse[0] | undefined;
  currentWorkspaceRef: MutableRefObject<WorkspaceListResponse[0] | undefined | undefined>;
};

const defaultValue: CurrentWorkspaceProviderContextValue = {
  currentWorkspace: undefined,
  currentWorkspaceRef: { current: undefined },
};

const CurrentWorkspaceProviderContext = createContext(defaultValue);

export function CurrentWorkspaceProvider({ children }: CurrentWorkspaceProviderProps) {
  const { isInternal, workspaceId } = useSysadmin();
  const { data: workspaces = [], isLoading } = useWorkspaces();
  const { data: workspace } = useWorkspace({ id: workspaceId });
  const currentWorkspaceRef = useRef<WorkspaceListResponse[0] | undefined>();

  const currentWorkspace = useMemo(() => {
    if (isInternal) {
      currentWorkspaceRef.current = workspace;
    } else {
      if (workspaces.length && !isLoading) {
        const currentWorkspace = getLastUsedWorkspace(workspaces);

        // whenever useWorkspaces revalidates and gets updated data back from the server
        // the current workspace object that comes down in the arry is not referentially equal (think ===) to the one we're currently in even if it has the exact same values.
        // this means that SWR is not reusing what it has in memory to preserve referential equality.
        // thus, we need to do a deep check to see if it actually changes. This prevents all of the context subscribers (components/hooks using useCurrentWorkspace) from unnecessary re-renders
        if (!isEqual(currentWorkspaceRef.current, currentWorkspace)) {
          currentWorkspaceRef.current = currentWorkspace;
        }
      }
    }

    return currentWorkspaceRef.current;
  }, [isInternal, workspace, workspaces, isLoading]);

  const value = useMemo<CurrentWorkspaceProviderContextValue>(
    () => ({ currentWorkspace, currentWorkspaceRef }),
    [currentWorkspace],
  );

  return <CurrentWorkspaceProviderContext.Provider value={value}>{children}</CurrentWorkspaceProviderContext.Provider>;
}

export function useCurrentWorkspace() {
  const context = useContext(CurrentWorkspaceProviderContext);

  if (context === defaultValue) {
    const error = 'CurrentWorkspaceProviderContext used outside of CurrentWorkspaceProvider';
    if (isDevOrTestStage()) {
      throw error;
    } else {
      reportErrorToBugsnag({
        error,
        context: error,
      });
    }
  }

  return context;
}
