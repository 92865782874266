import { Discussion } from '@air/api/types';
import { toNumber } from 'lodash';
import { createSelector } from 'reselect';

import { Routes } from '~/constants/routes';
import { QueryParamNames } from '~/constants/search';
import { AirState } from '~/store/types';

export const routerSelector = ({ router }: AirState) => router.location;
export const routeSelector = (_: AirState, route: string) => route;

export const pathnameSelector = createSelector(routerSelector, ({ pathname }) => pathname);

export const basePathNameSelector = createSelector(pathnameSelector, (pathname) => pathname.split('/')[1]);

export const isUrlActiveRouteSelector = createSelector(
  routerSelector,
  (_: AirState, url: string) => url,
  (router, url) => router.href.includes(url),
);

export const isImportRouteSelector = createSelector(routerSelector, (router) => router.href.includes('/import/'));

export const viewingMyAccountSelector = createSelector(routerSelector, (router) =>
  router.href.includes(Routes.account.myAccount),
);

export const viewingChangePasswordSelector = createSelector(routerSelector, (router) =>
  router.href.includes(Routes.account.changePassword),
);

export const viewingCheckoutPageSelector = createSelector(routerSelector, (router) =>
  router.href.includes(Routes.workspaceAdmin.checkout),
);

export const viewingNotificationSettingsSelector = createSelector(routerSelector, (router) =>
  router.href.includes(Routes.account.notificationSettings),
);

export const viewingFavoritesSelector = createSelector(routerSelector, (router) =>
  router.href.includes(Routes.media.favoriteItems),
);

export const viewingRecentlyModifiedSelector = createSelector(routerSelector, (router) =>
  router.href.includes(Routes.media.recentlyModified),
);

export const viewingUnusedItemsSelector = createSelector(routerSelector, (router) =>
  router.href.includes(Routes.media.unusedItems),
);

export const viewingUntaggedItemsSelector = createSelector(routerSelector, (router) =>
  router.href.includes(Routes.media.untaggedItems),
);

export const isOnRouteSelector = createSelector(routerSelector, routeSelector, ({ pathname }, route) =>
  pathname.includes(route),
);

export const viewingAssetSelector = createSelector(routerSelector, (router) => router.href.includes('/c/'));

export const viewingAllAssetsAssetModalSelector = createSelector(routerSelector, ({ href }) =>
  href.includes(`${Routes.media.all}/c/`),
);

export const isFlattenedViewSelector = createSelector(
  [routerSelector],
  ({ search }) => new URLSearchParams(search).get(QueryParamNames.isFlattenedView) || '',
);

export const activeTimestampParamSelector = createSelector(routerSelector, ({ search }) => {
  const timestampString = new URLSearchParams(search).get(QueryParamNames.timestamp);
  if (timestampString) {
    const timestamp = toNumber(timestampString);

    if (!isNaN(timestamp)) {
      return timestamp;
    }
  }
});

export const activeDiscussionIdParamSelector = createSelector(routerSelector, ({ search }) =>
  new URLSearchParams(search).get(QueryParamNames.discussionId),
);

export const isViewingAnnotatedCommentSelector = createSelector(
  activeDiscussionIdParamSelector,
  (discussionId) => !!discussionId,
);

const discussionIdSelector = (_: AirState, discussionId: Discussion['id']) => discussionId;

export const isViewingDiscussionWithId = createSelector(
  activeDiscussionIdParamSelector,
  discussionIdSelector,
  (activeDiscussionId, discissionId) => activeDiscussionId === discissionId,
);

export const workspaceIdQueryParamSelector = createSelector(
  routerSelector,
  ({ search }) => new URLSearchParams(search).get(QueryParamNames.workspaceId) || '',
);

export const planIdParamSelector = createSelector(
  [routerSelector],
  ({ search }) => new URLSearchParams(search).get(QueryParamNames.planId) || '',
);

export const couponCodeParamSelector = createSelector(
  [routerSelector],
  ({ search }) => new URLSearchParams(search).get(QueryParamNames.couponCode) || '',
);
