import { parseISO } from 'date-fns';

export const sortByLastUsedDesc = <I extends { lastUsed: string }>(workspaces: I[]): I[] =>
  [...workspaces].sort((a, b) => parseISO(b.lastUsed).getTime() - parseISO(a.lastUsed).getTime());

export const getLastUsedWorkspace = <I extends { lastUsed: string }>(workspaces: I[]): I =>
  sortByLastUsedDesc(workspaces)[0];

export const getUsedStoragePercentage = (usedStorage: number | undefined, maxStorage: number | null | undefined) => {
  if (!usedStorage) {
    return 0;
  }

  const rawPercent = !!maxStorage ? (usedStorage / maxStorage) * 100 : 0;
  const percentOfStorageUsed = Math.round((rawPercent + Number.EPSILON) * 100) / 100;

  return percentOfStorageUsed;
};
