import { Close } from '@air/next-icons';
import pluralize from 'pluralize';
import React, { memo, useCallback, useEffect, useRef } from 'react';

import { PaneButton } from '~/components/FileStatusTrackingPane/PaneButton';
import { PaneItemTW } from '~/components/FileStatusTrackingPane/PaneItemTW';
import { GetCompletedMetadata, ZippingTask } from '~/store/tasks/types';
import { DownloadFile, isS3DownloadUrlActive } from '~/utils/DownloadFile';

import { CompletedAvatar } from '../FileStatusTrackingPane/FileStatusTrackingPaneHeader/StatusAvatar';
import { StatusTrackingSubtitle } from '../FileStatusTrackingPane/StatusTrackingSubtitle';
import { StatusTrackingTitle } from '../FileStatusTrackingPane/StatusTrackingTitle';

export type ZippingTaskCompletedPanelItemProps = {
  metadata: GetCompletedMetadata<ZippingTask>;
  onClear: () => void;
  onHandleExpiredLink: () => void;
};

export const ZippingTaskCompletedPanelItem = memo(
  ({
    metadata: { url, clipIds = [], boardIds = [] },
    onClear,
    onHandleExpiredLink,
  }: ZippingTaskCompletedPanelItemProps) => {
    const downloadedRef = useRef(false);
    /**
     * By being in ref, it prevents the `download` function from changing
     * and thus not triggering the download `useEffect` below.
     */
    const handleExpiredLinkRef = useRef(onHandleExpiredLink);
    handleExpiredLinkRef.current = onHandleExpiredLink;

    const download = useCallback((url: string) => {
      /**
       * This ref is to ensure the user doesn't start automically downloading the zip again.
       * After they start the download, we don't clear the downloaded item because it's possible
       * their download never started but we don't want to potentially automatically download again
       */
      if (isS3DownloadUrlActive(url) && !downloadedRef.current) {
        DownloadFile(url);
        downloadedRef.current = true;
      } else {
        handleExpiredLinkRef.current();
      }
    }, []);

    useEffect(() => {
      if (url) {
        download(url);
      }
    }, [download, url]);

    return (
      <PaneItemTW
        avatar={<CompletedAvatar />}
        title={
          <StatusTrackingTitle data-testid="ZIPPING_PANEL_COMPLETED_TITLE">{`Zipped ${pluralize(
            'item',
            clipIds.length + boardIds.length,
            true,
          )}`}</StatusTrackingTitle>
        }
        subtitle={
          <StatusTrackingSubtitle>
            {
              <>
                {`Download didn't start? `}
                <button
                  className="text-12 text-blue-9 hover:text-blue-11 hover:underline"
                  onClick={() => {
                    if (url) {
                      download(url);
                    }
                  }}
                >
                  Click here
                </button>
              </>
            }
          </StatusTrackingSubtitle>
        }
        buttons={<PaneButton label="Clear" Icon={Close} onClick={() => onClear()} />}
      />
    );
  },
);

ZippingTaskCompletedPanelItem.displayName = 'ZippingCompletedPanelItem';
