import type { Board } from '@air/api/types';
import { useCallback } from 'react';

import { useAnalyticsUtilities } from '../hooks/useAnalyticsUtilities';
import META from './useTrackMovedBoard.meta';

export type UseTrackMovedBoardParams = {
  board_ids: Board['id'][];
  new_parent_board_id?: string | null;
  new_library_id?: string;
};

export const useTrackMovedBoards = () => {
  const { track } = useAnalyticsUtilities();

  const trackMovedBoards = useCallback(
    (params: UseTrackMovedBoardParams) => {
      track(META.name, params);
    },
    [track],
  );

  return { trackMovedBoards };
};
