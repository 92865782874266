import { ShortUrl } from '@air/api';
import { ShortUrlObjectType } from '@air/api/types';
import { matchesAirror, NOT_FOUND } from '@air/errors';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

import { useRevalidateISR } from '~/hooks/useRevalidateISR';
import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';
import { getShortIdInformationKey } from '~/swr-hooks/shortUrl/useGetExistingShortIdInformation';
import { reportErrorToBugsnag } from '~/utils/ErrorUtils';

/**
 * This hook returns a function that you can pass a board id to that will,
 * if it exists, revalidate the short url for that board. You want to call this
 * function if any changes are made to the board and you want those changes to be
 * reflected in the public board. This function under the hood
 * kicks off a regeneration of the static files for the public board.
 */
export const useRevalidatePublicBoard = () => {
  const { currentWorkspace } = useCurrentWorkspace();
  const { revalidateISR } = useRevalidateISR();
  const client = useQueryClient();

  const getPublicBoardShortId = useCallback(
    async (boardId: string) => {
      const workspaceId = currentWorkspace?.id;
      if (!workspaceId) {
        throw new Error('No workspace id');
      }
      try {
        return await client.ensureQueryData({
          queryKey: getShortIdInformationKey({ objectId: boardId, boardContext: undefined }),
          queryFn: () =>
            ShortUrl.getExistingShortIdForObject({ workspaceId, id: boardId, type: ShortUrlObjectType.board }),
        });
      } catch (error) {
        // if short url doesn't exist, just return null - we don't need to revalidate
        if (matchesAirror(error, NOT_FOUND)) {
          return null;
        }
        throw error;
      }
    },
    [currentWorkspace?.id, client],
  );

  const revalidatePublicBoard = useCallback(
    async (boardId: string) => {
      try {
        const response = await getPublicBoardShortId(boardId);

        if (!response) {
          // if there is no short url for this board, we don't need to revalidate
          return;
        }

        const shortPath = `/a/${response.id}`;
        const fullPath = `/a/${response.id}/b/${boardId}`;

        await Promise.all([
          revalidateISR({
            path: shortPath,
          }),
          /**
           * We need to revalidate the full board path since we have redirect code
           * setup for a short urls for board to go to the full board path
           */
          revalidateISR({
            path: fullPath,
          }),
        ]);
      } catch (error) {
        reportErrorToBugsnag({ error, context: 'Failed to revalidate public board', metadata: { boardId } });
      }
    },
    [getPublicBoardShortId, revalidateISR],
  );

  return {
    revalidatePublicBoard,
  };
};
