import { AirActionTrackingLocation } from '@air/analytics';
import { ShortUrlResponse } from '@air/api/types';
import { Modal, ModalDescription } from '@air/primitive-modal';
import { useCallback } from 'react';

import { RegenerateShareLinkButtons } from '~/components/SharePrivateContentModal/RegenerateLink/RegenerateShareLinkButtons';
import { ShareModalHeader } from '~/components/SharePrivateContentModal/ShareModalHeader';
import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';
import { useRegenerateShortIdForBoard } from '~/swr-hooks/shortUrl/useRegenerateShortIdForBoard';

export type SharePrivateBoardRegenerateProps = {
  boardId: string;
  trackLocation: AirActionTrackingLocation;
  shortIdInfo: ShortUrlResponse;
  onRegenerated: () => void;
  onCancel: () => void;
};

export const SharePrivateBoardRegenerate = ({
  boardId,
  trackLocation,
  shortIdInfo,
  onClose,
  onCancel,
  onRegenerated,
}: AirModalProps<SharePrivateBoardRegenerateProps>) => {
  const { currentWorkspace } = useCurrentWorkspace();
  const { regenerateShortIdForBoard } = useRegenerateShortIdForBoard();

  const onRegenerate = useCallback(() => {
    const workspaceId = currentWorkspace?.id;
    if (!workspaceId) {
      throw new Error('No workspace id');
    }
    regenerateShortIdForBoard.mutate(
      {
        workspaceId,
        boardId,
        shortId: shortIdInfo.id,
        permissions: shortIdInfo.permissions,
        boardContext: undefined,
        trackLocation,
      },
      {
        onSuccess: () => {
          onClose();
          onRegenerated();
        },
      },
    );
  }, [
    currentWorkspace?.id,
    boardId,
    onClose,
    onRegenerated,
    regenerateShortIdForBoard,
    shortIdInfo.id,
    shortIdInfo.permissions,
    trackLocation,
  ]);

  return (
    <Modal isOpen onDismiss={onClose}>
      <ShareModalHeader onClose={onClose} title="Generate new link?" />
      <ModalDescription>
        This will break the current URL and people with the old link will no longer have access to the board. Are you
        sure you want to generate a new URL?
      </ModalDescription>
      <RegenerateShareLinkButtons
        isLoading={regenerateShortIdForBoard.isPending}
        onRegenerate={onRegenerate}
        onClose={() => {
          onClose();
          onCancel();
        }}
      />
    </Modal>
  );
};
