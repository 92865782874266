import { Meta } from '../types/docs';

const META: Meta = {
  name: 'toggled_anonymous_account_requirement_on',
  description: 'User turned on "Require email" toggle on share link',
  params: [
    {
      name: 'object_id',
      description: 'Id of an object that share link is generated for',
      required: true,
      type: 'string',
    },
    {
      name: 'object_type',
      description: 'Type of an object that share link is generated for',
      required: true,
      type: 'enum',
      values: ['board', 'clip'],
    },
    {
      name: 'share_url',
      description: 'Generated share url',
      required: true,
      type: 'string',
    },
  ],
};

export default META;
