import { Events } from '@air/wss-sdk';
import { isUndefined } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';
import isEqual from 'react-fast-compare';
import { v4 as uuid } from 'uuid';

import { PENDING_SUBSCRIPTIONS, SUBSCRIPTIONS } from '~/constants/sockets';
import { useWebsocketContext } from '~/providers/SocketContext/SocketContext';
import { EventSubscriptionArgs, ReadyState, SubscribeOptions, Subscription } from '~/types/sockets';

interface UseEventSubscriptionArgs<T extends keyof Events> {
  event: EventSubscriptionArgs<T> | undefined;
  options: SubscribeOptions<T>;
  currentWorkspaceId: string | undefined;
}

export const useEventSubscription = <T extends keyof Events>({
  options,
  event,
  currentWorkspaceId,
}: UseEventSubscriptionArgs<T>) => {
  const currentWorkspaceIdRef = useRef<string | undefined>();
  const [isSubscribed, setIsSubscribed] = useState(false);
  const { sendJsonMessage, readyState } = useWebsocketContext();
  const optionsRef = useRef<SubscribeOptions<T>>(options);
  const eventRef = useRef<EventSubscriptionArgs<T> | undefined>(event);
  const subscriptionIdRef = useRef<string | null>(null);
  const idRef = useRef<string | null>(null);
  const readyStateRef = useRef<ReadyState | null>(readyState);

  const isWorkspaceLoaded = !isUndefined(currentWorkspaceId);
  const eventChanged = !isEqual(eventRef.current, event);

  currentWorkspaceIdRef.current = currentWorkspaceId;
  readyStateRef.current = readyState;
  eventRef.current = event;
  optionsRef.current = options;

  const subscribe = useCallback(() => {
    if (eventRef.current && !!currentWorkspaceIdRef.current && !idRef.current && !subscriptionIdRef.current) {
      // This id is sent to the websocket server and used by the client to identify which
      // subscribe success message belongs to which pending subscription. Afterwards,
      // the "subscriptionId" returned by the server is used to identify subscriptions.
      const id = uuid();

      const newSubscription: Subscription<T> = {
        event: eventRef.current,
        workspaceId: currentWorkspaceIdRef.current,
        subscribers: new Set([
          {
            onSubscriptionSuccess: (subscriptionId) => {
              subscriptionIdRef.current = subscriptionId;
              setIsSubscribed(true);
            },
            options: optionsRef,
            uuid: id,
            updateUUID: (newUUID) => {
              idRef.current = newUUID;
            },
          },
        ]),
      };

      idRef.current = id;

      PENDING_SUBSCRIPTIONS[id] = newSubscription;

      const pattern = eventRef.current.pattern;

      const patternPreparedForStringify = pattern
        ? (Object.keys(pattern) as Array<keyof EventSubscriptionArgs<T>['pattern']>).reduce((acc, key) => {
            const data = pattern[key];

            //@ts-ignore
            if (data instanceof RegExp) {
              //@ts-ignore
              acc[key] = { $like: String(data).slice(1, -1) };
            } else {
              acc[key] = data;
            }

            return acc;
          }, {})
        : undefined;

      const subscribeMessage = {
        method: 'subscribe',
        id: id,
        args: {
          eventType: eventRef.current.eventType,
          pattern: { ...patternPreparedForStringify, workspaceId: currentWorkspaceIdRef.current },
        },
      };

      /**
       * When the socket opens it will already send a message for each pending subscription. So we dont need to
       * send a message here unless the socket is already open.
       */
      if (readyStateRef.current === ReadyState.OPEN) {
        sendJsonMessage(subscribeMessage);
      }
    }
  }, [sendJsonMessage]);

  const unsubscribe = useCallback(() => {
    if (!!idRef.current && !subscriptionIdRef.current && !!PENDING_SUBSCRIPTIONS[idRef.current]) {
      // debugger;
      // If we have a pending subscription that hasn't subscribed yet, just delete the
      // pending sub.
      optionsRef.current.onUnsubscribe?.();
      delete PENDING_SUBSCRIPTIONS[idRef.current];
      idRef.current = null;
      return;
    }

    if (!!subscriptionIdRef.current && !!idRef.current && !!SUBSCRIPTIONS[subscriptionIdRef.current]) {
      // If the subscription has more than 1 subscriber we dont want to un-subscribe, but remove this specific
      // subscriber instead.
      if (SUBSCRIPTIONS[subscriptionIdRef.current].subscribers.size > 1) {
        SUBSCRIPTIONS[subscriptionIdRef.current].subscribers.forEach((subscriber) => {
          if (subscriber.uuid === idRef.current && !!subscriptionIdRef.current) {
            subscriber.options.current?.onUnsubscribe?.();
            SUBSCRIPTIONS[subscriptionIdRef.current].subscribers.delete(subscriber);
          }
        });
      } else {
        sendJsonMessage({
          method: 'unsubscribe',
          args: {
            subscriptionId: subscriptionIdRef.current,
          },
        });

        if (SUBSCRIPTIONS[subscriptionIdRef.current]) {
          SUBSCRIPTIONS[subscriptionIdRef.current].subscribers.forEach((subscriber) => {
            subscriber.options.current?.onUnsubscribe?.();
          });
          delete SUBSCRIPTIONS[subscriptionIdRef.current];
        }
        if (PENDING_SUBSCRIPTIONS[idRef.current]) {
          PENDING_SUBSCRIPTIONS[idRef.current].subscribers.forEach((subscriber) => {
            subscriber.options.current?.onUnsubscribe?.();
          });
          delete PENDING_SUBSCRIPTIONS[idRef.current];
        }
      }
      subscriptionIdRef.current = null;
      idRef.current = null;
      setIsSubscribed(false);
    }
  }, [sendJsonMessage]);

  useEffect(() => {
    subscribe();
  }, [subscribe, isWorkspaceLoaded, readyState]);

  useEffect(() => {
    if (eventChanged) {
      unsubscribe();
      subscribe();
    }
  }, [eventChanged, subscribe, unsubscribe]);

  /**
   * When this hook unmounts, we unsubscribe
   */
  useEffect(() => {
    return () => {
      unsubscribe();
    };
  }, [unsubscribe]);

  return {
    isSubscribed,
    unsubscribe,
  };
};

// An Example Of How To Use This Hook

// export default function Sockets() {
//   const { isSubscribed, unsubscribe } = useEventSubscription<'System.Notification.created'>({
//     event: {
//       type: 'System.Notification.created',
//     },
//     options: {
//       onSubscribe: () => console.log('SUBSCRIBED!'),
//       onUnsubscribe: () => console.log('UNSUBSCRIBED!'),
//       onUpdate: (message) => {
//         console.log('RECIEVED MESSAGE');
//         console.log(message);
//       },
//     },
//   });

//   return (
//     <div>
//       {`Socket is ${isSubscribed ? 'subscribed' : 'unsubscribed'}.`}
//       <Button disabled={!isSubscribed} onClick={() => unsubscribe()}>
//         Unsub
//       </Button>
//     </div>
//   );
// }

// Sockets.getLayout = getLayout;
