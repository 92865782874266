import { Task } from '~/store/tasks/types';

const TASKS_KEY = 'tasks';

export const LOCAL_TASK_ID_PREFIX = 'local-task-id';

export const createLocalTaskId = () => `${LOCAL_TASK_ID_PREFIX}-${Math.random().toString()}`;

const saveTasksToLocalStorage = (tasks: Task[]) => {
  const stringified = JSON.stringify(tasks);

  window.localStorage.setItem(TASKS_KEY, stringified);
};

export const getTasksFromLocalStorage = (): Task[] => {
  const item = window.localStorage.getItem(TASKS_KEY);

  if (item) {
    const parsedValue = JSON.parse(item) as Task[];

    return parsedValue;
  } else {
    return [];
  }
};

export const addTasksToLocalStorage = (tasks: Task[]) => {
  const existingTasks = getTasksFromLocalStorage();

  const newTasks: Task[] = [...existingTasks, ...tasks];

  saveTasksToLocalStorage(newTasks);
};

export const removeTasksFromLocalStorage = (localTaskIds: Task['localTaskId'][]) => {
  const existingTasks = getTasksFromLocalStorage();

  const newTasks = existingTasks.filter(({ localTaskId }) => !localTaskIds.includes(localTaskId));

  saveTasksToLocalStorage(newTasks);
};

export const updateTaskInLocalStorage = <T extends Task>(task: T) => {
  const localStorageTasks = getTasksFromLocalStorage();

  const index = localStorageTasks.findIndex(({ localTaskId }) => task.localTaskId === localTaskId);

  if (index > -1) {
    localStorageTasks[index] = task;
    saveTasksToLocalStorage(localStorageTasks);
  }
};

export const clearTasksFromLocalStorage = () => {
  window.localStorage.removeItem(TASKS_KEY);
};
