import { tailwindMerge } from '@air/tailwind-variants';
import classNames from 'classnames';
import { ReactNode } from 'react';

export interface PaneItemTWProps {
  className?: string;
  title: ReactNode;
  subtitle?: ReactNode;
  avatar: ReactNode;
  buttons: ReactNode;
  truncate?: boolean;
  previewLoader?: ReactNode;
}

export const PaneItemTW = ({
  className,
  title,
  subtitle,
  avatar,
  buttons,
  truncate = true,
  previewLoader,
}: PaneItemTWProps) => (
  <div className={tailwindMerge('m-0 flex content-between p-4 text-left', className)}>
    <div className="shrink-0">{previewLoader}</div>
    <div className="flex min-w-0 grow items-center">
      <div>{avatar}</div>
      <div className={classNames('min-w-0 flex-1', truncate && '[&>*]:truncate')}>
        {title}
        {subtitle}
      </div>
    </div>
    <div className="ml-2 flex shrink-0 content-end gap-1">{buttons}</div>
  </div>
);
