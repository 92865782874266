import { Board, Library, ListResponse, SortDirection, SubnavBoardSort } from '@air/api/types';
import { InfiniteData, QueryKey, useQueryClient } from '@tanstack/react-query';
import produce from 'immer';
import { chunk } from 'lodash';
import { useCallback } from 'react';

import {
  getLibraryRootBoardsKey,
  LIBRARY_BOARDS_LIST,
} from '~/components/LibraryBeta/hooks/queries/useLibraryRootBoards';
import { getFavoriteBoardsKey } from '~/swr-hooks/boards/useFavoriteBoardsList';
import { getRootWorkspaceBoardsKey } from '~/swr-hooks/boards/useRootWorkspaceBoards';
import { BOARD_SUBBOARDS, getBoardSubBoardsKey } from '~/swr-hooks/boards/useSubBoardsList';
import { useGetSubnavSortValue } from '~/swr-hooks/subnav/useSubnavSort';
import { insertIf } from '~/utils/insertIf';
import { getOrderedBoards } from '~/utils/mutateUtils/getOrderedBoards';
import { defaultBoardsState, getRearrangedBoards, getUpdatedBoards, UpdatedBoard } from '~/utils/mutateUtils/mutators';

interface SetSideNavQueryDataParams {
  cacheKeys: QueryKey[];
  updater: (data?: InfiniteData<ListResponse<Board>>) => InfiniteData<ListResponse<Board>> | undefined;
  revalidate?: boolean;
}

const useSetSideNavQueryData = () => {
  const queryClient = useQueryClient();

  const setSideNavQueryData = useCallback(
    ({ cacheKeys, updater, revalidate }: SetSideNavQueryDataParams) => {
      cacheKeys.forEach((key) => {
        queryClient.setQueriesData<InfiniteData<ListResponse<Board>> | undefined>({ queryKey: key }, updater);
      });

      if (revalidate) {
        return Promise.all(cacheKeys.map(async (key) => queryClient.invalidateQueries({ queryKey: key })));
      }
    },
    [queryClient],
  );

  return {
    setSideNavQueryData,
  };
};

export const removeBoards =
  (boardIds: string[]) =>
  (data?: InfiniteData<ListResponse<Board>>): InfiniteData<ListResponse<Board>> | undefined => {
    if (!data) return;

    return produce(data, (draft) => {
      draft.pages.forEach((page) => {
        page.data = page.data.filter((b) => !boardIds.includes(b.id));
      });
    });
  };

export const addBoards =
  (boards: Board[], boardSort: SubnavBoardSort = { name: '', direction: SortDirection.asc }) =>
  (data?: InfiniteData<ListResponse<Board>>): InfiniteData<ListResponse<Board>> | undefined => {
    return produce(data || defaultBoardsState, (draft) => {
      const flattenedBoards = draft.pages.flatMap((b) => b.data);
      flattenedBoards.push(...boards);

      const { orderedBoards } = getOrderedBoards(flattenedBoards, boardSort);
      const chunkedBoards = chunk(orderedBoards, Math.ceil(orderedBoards.length / draft?.pages.length));

      chunkedBoards.forEach((chunk, index) => {
        draft.pages[index].data = chunk;
      });
    });
  };

export const useUpdateSideNavBoard = () => {
  const { setSideNavQueryData } = useSetSideNavQueryData();
  const { getSubNavSortValue } = useGetSubnavSortValue();

  /**
   * Use this method to update board in side nav
   */
  const updateSideNavBoard = useCallback(
    (updatedBoard: UpdatedBoard, subnavSortBy?: SubnavBoardSort) => {
      try {
        const subNavSort = getSubNavSortValue();

        const cacheKeys = [
          // update favorite boards if board is in favorites
          ...insertIf(updatedBoard.hasCurrentUser, () => [getFavoriteBoardsKey(updatedBoard.workspaceId)]),
          // update workspace boards if board has no parentId
          ...insertIf(!updatedBoard.parentId, () => [
            getRootWorkspaceBoardsKey({ workspaceId: updatedBoard.workspaceId, sortField: subNavSort?.boardSort }),
          ]),
          // update subboards if board has parentId
          ...insertIf(!!updatedBoard.parentId, () => [
            getBoardSubBoardsKey(updatedBoard.parentId!, subNavSort?.boardSort),
          ]),
          // update library board if board has library
          ...insertIf(!!updatedBoard?.library?.id, () => [
            getLibraryRootBoardsKey({
              workspaceId: updatedBoard.workspaceId,
              libraryId: updatedBoard.library!.id,
              sortField: subNavSort?.boardSort,
            }),
          ]),
        ];
        return setSideNavQueryData({ cacheKeys, updater: getUpdatedBoards(updatedBoard, subnavSortBy) });
      } catch (error) {
        console.error({ error });
      }
    },
    [getSubNavSortValue, setSideNavQueryData],
  );

  return {
    updateSideNavBoard,
  };
};

export const useRemoveBoardsFromSideNav = () => {
  const { setSideNavQueryData } = useSetSideNavQueryData();

  /**
   * Use this method to remove boards from side nav
   */
  const removeBoardsFromSideNav = useCallback(
    ({
      workspaceId,
      boardIds,
      parentId,
      libraryId,
      navSortParams,
    }: {
      workspaceId: string;
      boardIds: string[];
      /*
       Pass '*' to remove board from all places in sidenav, regardless of parentId or libraryId
       */
      parentId?: string | null | '*';
      navSortParams?: SubnavBoardSort;
      libraryId?: Library['id'];
    }) => {
      const cacheKeys = [
        getFavoriteBoardsKey(workspaceId),
        ...insertIf((!parentId && !libraryId) || parentId === '*', () => [
          getRootWorkspaceBoardsKey({ workspaceId, sortField: navSortParams }),
        ]),
        ...insertIf((!parentId || parentId === '*') && !!libraryId, () => [
          getLibraryRootBoardsKey({ libraryId, workspaceId, sortField: navSortParams }),
        ]),
        ...insertIf(parentId === '*' && !libraryId, () => [[LIBRARY_BOARDS_LIST]]),
        parentId && parentId !== '*' ? getBoardSubBoardsKey(parentId, navSortParams) : [BOARD_SUBBOARDS],
      ];

      return setSideNavQueryData({ cacheKeys, updater: removeBoards(boardIds) });
    },
    [setSideNavQueryData],
  );

  return {
    removeBoardsFromSideNav,
  };
};

export const useToggleFavoritedSideNavBoard = () => {
  const { setSideNavQueryData } = useSetSideNavQueryData();

  const toggleFavoritedSideNavBoard = useCallback(
    (board: Board) => {
      const { workspaceId, hasCurrentUser, id } = board;

      if (hasCurrentUser) {
        setSideNavQueryData({ cacheKeys: [getFavoriteBoardsKey(workspaceId)], updater: addBoards([board]) });
      } else {
        setSideNavQueryData({ cacheKeys: [getFavoriteBoardsKey(workspaceId)], updater: removeBoards([id]) });
      }

      const keysToUpdate = [
        getRootWorkspaceBoardsKey({ workspaceId }),
        // TODO check if getLibraryRootBoardsKey should be added
        // by passing '*' we mutate all board subboards cache keys and don't need parentId
        [BOARD_SUBBOARDS],
      ];

      return setSideNavQueryData({ cacheKeys: keysToUpdate, updater: getUpdatedBoards({ id, hasCurrentUser }) });
    },
    [setSideNavQueryData],
  );

  return {
    toggleFavoritedSideNavBoard,
  };
};

export const useAddBoardsToSideNav = () => {
  const { setSideNavQueryData } = useSetSideNavQueryData();

  const addBoardsToSideNav = useCallback(
    (boards: Board[], boardSort?: SubnavBoardSort) => {
      const cacheKeys = [];
      const libraryId = boards[0].library?.id;

      // currently it is only possible to add multiple boards at once to one parent (by duplicating them)
      if (!boards[0].parentId && libraryId) {
        cacheKeys.push(
          getLibraryRootBoardsKey({
            workspaceId: boards[0].workspaceId,
            sortField: boardSort,
            libraryId: boards[0].library!.id,
          }),
        );
      } else if (!boards[0].parentId) {
        cacheKeys.push(
          getRootWorkspaceBoardsKey({
            workspaceId: boards[0].workspaceId,
            sortField: boardSort,
          }),
        );
      } else {
        cacheKeys.push(getBoardSubBoardsKey(boards[0].parentId, boardSort));
      }

      return setSideNavQueryData({ cacheKeys, updater: addBoards(boards, boardSort) });
    },
    [setSideNavQueryData],
  );

  return {
    addBoardsToSideNav,
  };
};

export const useChangeSideBoardsParent = () => {
  const { setSideNavQueryData } = useSetSideNavQueryData();
  const { getSubNavSortValue } = useGetSubnavSortValue();

  const changeSideBoardsParent = useCallback(
    async (
      workspaceId: string,
      newBoards: Board[],
      oldParentId?: string | null,
      oldLibraryId: string | undefined = undefined,
    ) => {
      try {
        const boardSort = getSubNavSortValue()?.boardSort;
        const newParentId = newBoards[0].parentId;
        const newLibraryId = newBoards[0].library?.id;
        let keyToRemove, keyToAdd;

        if (oldParentId) {
          keyToRemove = getBoardSubBoardsKey(oldParentId, boardSort);
        } else if (oldLibraryId) {
          keyToRemove = getLibraryRootBoardsKey({ workspaceId, sortField: boardSort, libraryId: oldLibraryId });
        } else {
          keyToRemove = getRootWorkspaceBoardsKey({ workspaceId, sortField: boardSort });
        }

        if (newParentId) {
          keyToAdd = getBoardSubBoardsKey(newParentId, boardSort);
        } else if (newLibraryId) {
          keyToAdd = getLibraryRootBoardsKey({ workspaceId, sortField: boardSort, libraryId: newLibraryId });
        } else {
          keyToAdd = getRootWorkspaceBoardsKey({ workspaceId, sortField: boardSort });
        }

        return await Promise.all([
          setSideNavQueryData({ cacheKeys: [keyToRemove], updater: removeBoards(newBoards.map((board) => board.id)) }),
          setSideNavQueryData({ cacheKeys: [keyToAdd], updater: addBoards(newBoards) }),
        ]);
      } catch (error) {
        console.error({ error });
      }
    },
    [getSubNavSortValue, setSideNavQueryData],
  );

  return {
    changeSideBoardsParent,
  };
};

export const useRearrangeBoardsInSideNav = () => {
  const { setSideNavQueryData } = useSetSideNavQueryData();
  const { getSubNavSortValue } = useGetSubnavSortValue();

  const rearrangeBoardsInSideNav = useCallback(
    (rearrangedBoards: Board[]) => {
      const boardSort = getSubNavSortValue()?.boardSort;

      const newParentId = rearrangedBoards[0].parentId;
      const newLibraryId = rearrangedBoards[0].library?.id;
      let keyToUpdate;

      if (newParentId) {
        keyToUpdate = getBoardSubBoardsKey(newParentId, boardSort);
      } else if (newLibraryId) {
        keyToUpdate = getLibraryRootBoardsKey({
          workspaceId: rearrangedBoards[0].workspaceId,
          sortField: boardSort,
          libraryId: newLibraryId,
        });
      } else {
        keyToUpdate = getRootWorkspaceBoardsKey({ workspaceId: rearrangedBoards[0].workspaceId, sortField: boardSort });
      }

      return setSideNavQueryData({ cacheKeys: [keyToUpdate], updater: getRearrangedBoards(rearrangedBoards) });
    },
    [getSubNavSortValue, setSideNavQueryData],
  );

  return {
    rearrangeBoardsInSideNav,
  };
};

export const useRearrangeFavoriteBoardsInSideNav = () => {
  const { setSideNavQueryData } = useSetSideNavQueryData();

  const rearrangeFavoriteBoardsInSideNav = useCallback(
    (rearrangedBoards: Board[]) =>
      setSideNavQueryData({
        cacheKeys: [getFavoriteBoardsKey(rearrangedBoards[0].workspaceId)],
        updater: getRearrangedBoards(rearrangedBoards),
      }),
    [setSideNavQueryData],
  );

  return {
    rearrangeFavoriteBoardsInSideNav,
  };
};

export const useRefreshSideNavBoards = () => {
  const queryClient = useQueryClient();
  const { getSubNavSortValue } = useGetSubnavSortValue();

  const refreshRootSideNavBoards = useCallback(
    (workspaceId: string) => {
      const subNavSort = getSubNavSortValue();
      return queryClient.invalidateQueries({
        queryKey: getRootWorkspaceBoardsKey({ workspaceId, sortField: subNavSort?.boardSort }),
      });
    },
    [getSubNavSortValue, queryClient],
  );

  const refreshSideNavSubBoards = useCallback(
    (boardId: string) => {
      const subNavSort = getSubNavSortValue();
      queryClient.invalidateQueries({ queryKey: getBoardSubBoardsKey(boardId, subNavSort?.boardSort) });
    },
    [getSubNavSortValue, queryClient],
  );

  return {
    refreshRootSideNavBoards,
    refreshSideNavSubBoards,
  };
};
