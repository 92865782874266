import { useAirModal } from '@air/provider-modal';

import { DeleteBoardModal } from '~/components/Modals/DeleteBoards/DeleteBoardsModal';

export const useDeleteBoardsModal = () => {
  const [showDeleteBoardsModal] = useAirModal(DeleteBoardModal);

  return {
    showDeleteBoardsModal,
  };
};
