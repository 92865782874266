import { memo, PropsWithChildren } from 'react';

export interface SharePermissionSectionProps {
  title: string;
}

export const SharePermissionSection = memo(({ children, title }: PropsWithChildren<SharePermissionSectionProps>) => (
  <div className="flex flex-col gap-3">
    <p className="text-12 font-semibold text-grey-10">{title}</p>
    {children}
  </div>
));

SharePermissionSection.displayName = 'SharePermissionSection';
