import { AirActionTrackingLocation, TrackCopiedShareLinkParams, useTrackCopiedShareLink } from '@air/analytics';
import { ShortUrlResponse } from '@air/api/types';
import { type DropdownMenuOption } from '@air/primitive-dropdown-menu';
import { memo, useMemo } from 'react';

import { CopyLinkButton } from '~/components/SharePrivateContentModal/CopyLinkButton';
import { SharePrivateContentLink } from '~/components/SharePrivateContentModal/SharePrivateContentLink';
import { PRIVATE_SHARE_EDITABLE_LINK } from '~/constants/testIDs';
import { getFullShareUrl } from '~/utils/shareUrl';

export type EditableSharePrivateLinkProps = {
  createdAt?: ShortUrlResponse['createdAt'];
  createdBy?: ShortUrlResponse['createdBy'];
  objectId: string;
  objectType: TrackCopiedShareLinkParams['type'];
  onRegenerate: () => void;
  searchParams?: URLSearchParams;
  shareId: ShortUrlResponse['id'];
  trackLocation: AirActionTrackingLocation;
};

export const EditableSharePrivateLink = memo(
  ({
    createdAt,
    createdBy,
    objectId,
    trackLocation,
    shareId,
    searchParams,
    objectType,
    onRegenerate,
  }: EditableSharePrivateLinkProps) => {
    const { trackCopiedShareLink } = useTrackCopiedShareLink();

    const shareUrl = getFullShareUrl(shareId, searchParams);

    const onCopied = () => {
      trackCopiedShareLink({
        id: objectId,
        shareId,
        type: objectType,
        location: trackLocation,
      });
    };

    const actions = useMemo<DropdownMenuOption[]>(
      () => [
        {
          id: 'regenerate',
          label: 'Generate a new link',
          type: 'item',
          onSelect: () => onRegenerate(),
        },
      ],
      [onRegenerate],
    );

    return (
      <div data-testid={PRIVATE_SHARE_EDITABLE_LINK} className="flex flex-col gap-4">
        <SharePrivateContentLink
          createdAt={createdAt}
          createdBy={createdBy}
          shareUrl={shareUrl}
          menuActions={actions}
        />
        <CopyLinkButton shareUrl={shareUrl} onCopied={onCopied} />
      </div>
    );
  },
);

EditableSharePrivateLink.displayName = 'EditableSharePrivateLink';
