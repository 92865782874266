import { useAppliedFiltersCount } from '~/hooks/filters/useAppliedFiltersCount';
import { useSearchParams } from '~/hooks/search/useSearchParams';

export const useIsSearchActive = () => {
  const { hasSearchParams } = useSearchParams();

  const { filtersCount } = useAppliedFiltersCount();

  const hasFilters = filtersCount > 0;

  return {
    isSearchActive: hasSearchParams || hasFilters,
  };
};
