import { useFlags } from 'launchdarkly-react-client-sdk';
import { memo, useState } from 'react';

import { BoardSelect, BoardSelectProps } from '~/components/BoardSelect/BoardSelect';
import { useBoardSelectSearch } from '~/components/BoardSelect/useBoardSelectSearch';
import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';

export type PrivateBoardSelectProps = Pick<
  BoardSelectProps,
  | 'children'
  | 'getBoardDisabledMessage'
  | 'getLibraryDisabledMessage'
  | 'initialBoard'
  | 'isBoardDisabled'
  | 'isBoardSelected'
  | 'isLibraryDisabled'
  | 'isLibrarySelected'
  | 'onCreateBoard'
  | 'onSelectBoard'
  | 'onSelectLibrary'
  | 'shouldShowSubBoards'
>;

export const PrivateBoardSelect = memo(
  ({
    children,
    getBoardDisabledMessage,
    getLibraryDisabledMessage,
    initialBoard,
    isBoardDisabled,
    isBoardSelected,
    isLibraryDisabled,
    isLibrarySelected,
    onCreateBoard,
    onSelectBoard,
    onSelectLibrary,
    shouldShowSubBoards,
  }: PrivateBoardSelectProps) => {
    const [search, setSearch] = useState('');
    const { libraries: librariesFlag } = useFlags();
    const { currentWorkspace } = useCurrentWorkspace();
    const { boards, libraries } = useBoardSelectSearch({
      search,
      hasLibraries: librariesFlag,
      workspaceId: currentWorkspace?.id,
    });

    return (
      <BoardSelect
        boards={boards}
        getBoardDisabledMessage={getBoardDisabledMessage}
        getLibraryDisabledMessage={getLibraryDisabledMessage}
        hasLibraries={librariesFlag}
        initialBoard={initialBoard}
        isBoardDisabled={isBoardDisabled}
        isBoardSelected={isBoardSelected}
        isLibraryDisabled={isLibraryDisabled}
        isLibrarySelected={isLibrarySelected}
        libraries={libraries}
        onCreateBoard={onCreateBoard}
        onSearchChange={setSearch}
        onSelectBoard={onSelectBoard}
        onSelectLibrary={onSelectLibrary}
        search={search}
        shouldShowSubBoards={shouldShowSubBoards}
      >
        {children}
      </BoardSelect>
    );
  },
);

PrivateBoardSelect.displayName = 'PrivateBoardSelect';
