import { useTrackGeneratedShareUrl } from '@air/analytics';
import { ShortUrl } from '@air/api';
import { ShortUrlObjectType } from '@air/api/types';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { reportErrorToBugsnag } from '~/utils/ErrorUtils';

import { getShortIdInformationKey } from './useGetExistingShortIdInformation';

export const useGetShortIdForBoard = () => {
  const queryClient = useQueryClient();
  const { trackGeneratedShareUrl } = useTrackGeneratedShareUrl();

  const getShortIdForBoard = useMutation({
    mutationFn: (params: Parameters<typeof ShortUrl.getShortIdForBoard>[0]) => ShortUrl.getShortIdForBoard(params),
    onSuccess: (data, params) => {
      queryClient.setQueryData(getShortIdInformationKey({ objectId: params.id, boardContext: undefined }), data);

      trackGeneratedShareUrl({
        type: ShortUrlObjectType.board,
        id: data.id,
      });
    },
    onError: (error, params) => {
      throw reportErrorToBugsnag({
        error,
        context: 'Failed to get short id for board',
        metadata: { data: { params } },
      });
    },
  });

  return {
    getShortIdForBoard,
  };
};
