import { AirActionTrackingLocation, useTrackUnfavoritedBoard } from '@air/analytics';
import { Boards } from '@air/api';
import { Board } from '@air/api/types';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { useAccountContext } from '~/providers/AccountProvider';
import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';
import { addUserToBoardAction, removeUserFromBoardAction } from '~/store/centralizedBoard/actions';
import { reportErrorToBugsnag } from '~/utils/ErrorUtils';
import { useFavoriteBoardInAllLists, useUnfavoriteBoardInAllLists } from '~/utils/mutateUtils/AllBoards';

import { useActiveSubscriptionCallback } from '../subscriptions/useActiveSubscriptionCallback';

/**
 * This hook gives you a function to unfavorite boards
 */
export const useUnfavoriteBoards = () => {
  const dispatch = useDispatch();
  const { activeSubscriptionCallback } = useActiveSubscriptionCallback();
  const { data: user } = useAccountContext();
  const { favoriteBoardInAllLists } = useFavoriteBoardInAllLists();
  const { unfavoriteBoardInAllLists } = useUnfavoriteBoardInAllLists();
  const { trackUnfavoritedBoard } = useTrackUnfavoritedBoard();
  const { currentWorkspace } = useCurrentWorkspace();
  const workspaceId = currentWorkspace?.id;

  const unfavoriteBoards = useCallback(
    ({ boards, trackLocation }: { boards: Board[]; trackLocation: AirActionTrackingLocation }) =>
      activeSubscriptionCallback(async () => {
        if (!user?.id) {
          throw new Error(`User doesn't have an id`);
        }

        if (!workspaceId) {
          throw new Error('No workspaceId found');
        }

        return Promise.all(
          boards.map((board) => {
            try {
              unfavoriteBoardInAllLists(board);

              dispatch(
                removeUserFromBoardAction({
                  userId: user.id,
                  boardId: board.id,
                }),
              );

              trackUnfavoritedBoard({ boardId: board.id, boardTitle: board.title, location: trackLocation });

              return Boards.removeMembers({ boardId: board.id, accountIds: [user?.id], workspaceId });
            } catch (error) {
              favoriteBoardInAllLists(board);
              dispatch(addUserToBoardAction({ user: user, boardId: board.id, moveToTop: false }));

              throw reportErrorToBugsnag({ error, context: `Failed to unfavorite a board`, metadata: { board } });
            }
          }),
        );
      }),
    [
      activeSubscriptionCallback,
      user,
      workspaceId,
      unfavoriteBoardInAllLists,
      dispatch,
      trackUnfavoritedBoard,
      favoriteBoardInAllLists,
    ],
  );

  return {
    unfavoriteBoards,
  };
};
