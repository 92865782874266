import { EntityLookupResponse } from '@air/api';
import { FilterLookupsResponse, SearchFilterDate } from '@air/api/types';
import { CustomFieldWithValues } from '@air/types/src/customFields';
import { differenceInDays } from 'date-fns';
import { isNil, keyBy } from 'lodash';

import { FilterParamNames } from '~/constants/search';
import { useFilterParams } from '~/hooks/filters/useFilterParams';
import {
  DateFilter,
  Filters,
  ImportedKeywordsFilterType,
  PeopleFilterType,
  TagsFilterType,
} from '~/store/filters/types';
import { isDefined } from '~/utils/isDefined';

export const getTagsStateFromParams = (
  tagsParam: ReturnType<typeof useFilterParams>['tagsParam'],
  tagOptions: EntityLookupResponse['tags'],
) => {
  const tags: TagsFilterType = { tags: [], logic: 'and' };
  let tagsToAdd: string[] = [];
  if (!!tagsParam?.and?.length) {
    tags.logic = 'and';
    tagsToAdd = tagsParam?.and;
  } else if (!!tagsParam?.or?.length) {
    tags.logic = 'or';
    tagsToAdd = tagsParam?.or;
  } else if (!!tagsParam?.not?.length) {
    tags.logic = 'not';
    tagsToAdd = tagsParam?.not;
  }

  const tagOptionsByLabel = keyBy(tagOptions, 'label');

  tags.tags = tagsToAdd.filter((label) => !!tagOptionsByLabel[label]).map((label) => tagOptionsByLabel[label]);

  return tags;
};

export const getPeopleStateFromParams = (
  peopleParam: ReturnType<typeof useFilterParams>['peopleParam'],
  peopleOptions: EntityLookupResponse['people'],
) => {
  const people: PeopleFilterType = { people: [], logic: 'and' };

  let peopleToAdd: string[] = [];
  if (!!peopleParam?.and?.length) {
    people.logic = 'and';
    peopleToAdd = peopleParam?.and;
  } else if (!!peopleParam?.or?.length) {
    people.logic = 'or';
    peopleToAdd = peopleParam?.or;
  }

  const peopleOptionsById = keyBy(peopleOptions, 'id');
  people.people = peopleToAdd.filter((id) => !!peopleOptionsById[id]).map((id) => peopleOptionsById[id]);
  return people;
};

export const getKeywordsStateFromParams = (
  keywordsParam: ReturnType<typeof useFilterParams>['importedKeywordsParam'],
) => {
  const keywords: ImportedKeywordsFilterType = { keywords: [], logic: 'and' };
  if (!!keywordsParam?.and?.length) {
    keywords.logic = 'and';
    keywords.keywords = keywordsParam?.and;
  } else if (!!keywordsParam?.or?.length) {
    keywords.logic = 'or';
    keywords.keywords = keywordsParam?.or;
  } else if (!!keywordsParam?.not?.length) {
    keywords.logic = 'not';
    keywords.keywords = keywordsParam?.not;
  }

  return keywords;
};

export const getUploadersStateFromParams = (
  uploadersParam: ReturnType<typeof useFilterParams>['uploadersParam'],
  uploaders: FilterLookupsResponse['uploaders'],
): Filters['uploaders'] => {
  const uploadersByValue = keyBy(uploaders, 'value');

  return uploadersParam?.or?.map((uploaderId) => uploadersByValue[uploaderId]).filter(isDefined) ?? [];
};

export const getColorsStateFromParams = (
  colorsParam: ReturnType<typeof useFilterParams>['colorsParam'],
): Filters['colors'] => {
  return colorsParam?.or?.map((color) => color).filter(isDefined) ?? [];
};

export const getOthersStateFromParams = ({
  bookmarked,
  hasVersions,
  hasComments,
  untagged,
  isOnBoards,
}: {
  bookmarked: ReturnType<typeof useFilterParams>['bookmarkedParam'];
  isOnBoards: ReturnType<typeof useFilterParams>['isOnBoardsParam'];
  hasVersions: ReturnType<typeof useFilterParams>['hasVersionsParam'];
  hasComments: ReturnType<typeof useFilterParams>['hasOpenCommentsParam'];
  untagged: ReturnType<typeof useFilterParams>['untaggedParam'];
}): Filters['others'] => {
  const otherFilters: Filters['others'] = [];

  if (!isNil(bookmarked?.is)) {
    otherFilters.push('is-favorited');
  }

  if (!isNil(isOnBoards?.is)) {
    otherFilters.push('is-on-boards');
  }

  if (!isNil(hasVersions?.is)) {
    otherFilters.push('has-versions');
  }

  if (!isNil(hasComments)) {
    otherFilters.push('has-open-comments');
  }

  if (!isNil(untagged)) {
    otherFilters.push('is-untagged');
  }

  return otherFilters;
};

export const getTypesStateFromParams = (
  typesParam: ReturnType<typeof useFilterParams>['typesParam'],
): Filters['types'] => {
  return typesParam;
};

export const getImportedKeywordsStateFromParams = (
  importedKeywordsParam: ReturnType<typeof useFilterParams>['importedKeywordsParam'],
): Filters['importedKeywords'] => {
  const keywords: ImportedKeywordsFilterType = { keywords: [], logic: 'and' };
  let keywordsToAdd: string[] = [];
  if (!!importedKeywordsParam?.and?.length) {
    keywords.logic = 'and';
    keywordsToAdd = importedKeywordsParam?.and;
  } else if (!!importedKeywordsParam?.or?.length) {
    keywords.logic = 'or';
    keywordsToAdd = importedKeywordsParam?.or;
  } else if (!!importedKeywordsParam?.not?.length) {
    keywords.logic = 'not';
    keywordsToAdd = importedKeywordsParam?.not;
  }

  keywords.keywords = keywordsToAdd;

  return keywords;
};

export const getLocationStateFromParams = (locationParam: ReturnType<typeof useFilterParams>['locationParam']) => {
  const cities = locationParam?.city?.or ?? [];
  const states = locationParam?.state?.or ?? [];
  const countries = locationParam?.country?.or ?? [];

  return {
    cities,
    states,
    countries,
  };
};

export const getDateStateFromParams = (dates: SearchFilterDate | null): DateFilter | null => {
  if (!dates) {
    return null;
  }
  const { end, start } = dates;
  if (!!start && !end) {
    const diffInDays = differenceInDays(new Date(), new Date(start));
    switch (diffInDays) {
      case 7:
        return {
          type: 'last-week',
        };
      case 30: {
        return {
          type: 'last-month',
        };
      }
      case 365: {
        return {
          type: 'last-year',
        };
      }
      default: {
        return {
          type: 'custom-range',
          startDate: start,
          endDate: end,
        };
      }
    }
  } else if (!!start && !!end) {
    const diffInDays = differenceInDays(new Date(), new Date(start));
    switch (diffInDays) {
      case 0:
        return {
          type: 'today',
        };
      case 1:
        return {
          type: 'yesterday',
        };
      default:
        return {
          type: 'custom-range',
          startDate: start,
          endDate: end,
        };
    }
  }
  return null;
};

export const getCustomFieldsStateFromParams = (
  customFieldsParam: ReturnType<typeof useFilterParams>['customFieldsParam'],
  customFieldsById: { [key: string]: CustomFieldWithValues },
): Filters['customFields'] => {
  return customFieldsParam.reduce<Filters['customFields']>((acc, curr) => {
    const values = customFieldsById[curr.id]?.values ?? [];

    const valuesById = keyBy(values, 'id');

    if (!!curr.filter?.and?.length) {
      const values = curr.filter.and.map((valueId) => valuesById[valueId]).filter((value) => !!value);
      if (values?.length > 0) {
        acc.push({
          id: curr.id,
          logic: 'and',
          values: values.map((val) => valuesById[val.id]),
        });
      }
    } else if (!!curr.filter?.or?.length) {
      const values = curr.filter.or.map((valueId) => valuesById[valueId]).filter((value) => !!value);
      if (values?.length > 0) {
        acc.push({
          id: curr.id,
          logic: 'or',
          values: values.map((val) => valuesById[val.id]),
        });
      }
    } else if (!!curr.filter?.not?.length) {
      const values = curr.filter.not.map((valueId) => valuesById[valueId]).filter((value) => !!value);
      if (values?.length > 0) {
        acc.push({
          id: curr.id,
          logic: 'not',
          values: values.map((val) => valuesById[val.id]),
        });
      }
    }

    return acc;
  }, []);
};

export const customFieldIdFromUrlParam = (paramName: string) => {
  if (paramName.startsWith(FilterParamNames.cfIdAnd)) {
    const [, cfId] = paramName.split(FilterParamNames.cfIdAnd);
    return cfId;
  } else if (paramName.startsWith(FilterParamNames.cfIdOr)) {
    const [, cfId] = paramName.split(FilterParamNames.cfIdOr);
    return cfId;
  } else if (paramName.startsWith(FilterParamNames.cfIdNot)) {
    const [, cfId] = paramName.split(FilterParamNames.cfIdNot);
    return cfId;
  }
};
