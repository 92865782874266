import type { VariantProps } from '@air/tailwind-variants';
import { tailwindVariants } from '@air/tailwind-variants';
import type { ComponentPropsWithoutRef, ReactNode } from 'react';

export const badge = tailwindVariants({
  base: 'inline-flex items-center justify-between gap-1 px-1.5 font-bold uppercase tracking-wide',
  variants: {
    appearance: {
      pill: 'rounded-full',
      square: 'rounded-sm',
    },
    color: {
      blue: 'bg-blue-8 text-blue-1',
      grey: 'bg-grey-4 text-grey-10',
      'light-blue': 'bg-blue-2 text-blue-9',
      red: 'bg-red-10 text-red-1',
      teal: 'bg-teal-2 text-teal-10',
      yellow: 'bg-yellow-3 text-orange-11',
    },
    size: {
      small: 'h-4 text-10',
      medium: 'h-5 text-12',
    },
  },
  defaultVariants: {
    appearance: 'square',
    color: 'grey',
    size: 'small',
  },
});

type BadgeVariants = VariantProps<typeof badge>;

export type BadgeProps = Omit<ComponentPropsWithoutRef<'div'>, 'color' | 'prefix' | 'size'> &
  BadgeVariants & {
    prefix?: ReactNode;
    suffix?: ReactNode;
  };

export const Badge = ({
  appearance,
  children,
  className,
  color = 'grey',
  prefix,
  size = 'small',
  suffix,
  ...restOfProps
}: BadgeProps) => {
  return (
    <div className={badge({ appearance, color, size, className })} {...restOfProps}>
      {!!prefix && <div className="shrink-0">{prefix}</div>}

      {children}

      {!!suffix && <div className="shrink-0">{suffix}</div>}
    </div>
  );
};
