import { Board } from '@air/api/types';

import BoardThumbnail from '~/components/BoardThumbnail/BoardThumbnail';
import { DRAG_PREVIEW_HEIGHT, DRAG_PREVIEW_WIDTH } from '~/constants/dnd';

interface DndBoardDragPreviewProps {
  board: Board;
}

export const DNDBoardGalleryCardDragPreview = ({ board }: DndBoardDragPreviewProps) => (
  <div className="relative flex grow">
    <BoardThumbnail board={board} sizes="228px" width={DRAG_PREVIEW_WIDTH - 8} height={DRAG_PREVIEW_HEIGHT - 8} />
    <div className="absolute inset-0 flex items-end bg-black/30">
      <div className="truncate p-2 text-24 font-semibold text-white">{board.title}</div>
    </div>
  </div>
);
