import { AirActionTrackingLocation } from '@air/analytics';
import { Board } from '@air/api/types';
import { FormikField } from '@air/formik-field';
import { Button } from '@air/primitive-button';
import { Input } from '@air/primitive-input';
import { Modal, ModalCloseButton, ModalTitle } from '@air/primitive-modal';
import { useToasts } from '@air/provider-toast';
import { Form, Formik, FormikValues } from 'formik';
import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';

import { PrivateBoardSearch, PrivateBoardSearchProps } from '~/components/BoardSearch/PrivateBoardSearch';
import { BoardSearchLibrary, useSingleBoardSearchState } from '~/components/BoardSearch/shared/hooks';
import { FormPageIsValidFunction } from '~/components/Modals/sharedTypes';
import { ToastLink } from '~/components/UI/ToastLink';
import { CREATED_BOARD_LINK } from '~/constants/testIDs';
import { useBoardPermissionsCache } from '~/hooks/useBoardPermissionsCache';
import { useGoToBoardPage } from '~/hooks/useGoToBoardPage';
import { useLibraryPermissionsCache } from '~/hooks/useLibraryPermissionsCache';
import { useShowSubscriptionExpiredModal } from '~/hooks/useShowSubscriptionExpiredModal';
import { centralizedBoardSelector } from '~/store/centralizedBoard/selectors';
import { selectedClipIdsSelector } from '~/store/selectedItems/selectors';
import { useCreateNewBoard } from '~/swr-hooks/boards/useCreateNewBoard';
import { InitialBoardOption } from '~/types/BoardSearch';
import { containsRestrictedPathChars } from '~/utils/FileUtils';
import { canCreateBoard } from '~/utils/permissions/boardPermissions';

export interface CreateBoardFormFields extends FormikValues {
  title: string;
}

const CreateBoardSchema = Yup.object({
  title: Yup.string()
    .trim()
    .max(255, 'Cannot be longer than 255 characters')
    .test(
      'restricted-char-validation',
      'Characters : and | cannot be used',
      (val) => !containsRestrictedPathChars(val || ''),
    )
    .required('Please enter a board name'),
});

const pageIsValid: FormPageIsValidFunction<CreateBoardFormFields> = (errors, { title }) => !!title && !errors.title;

export interface CreatePrivateBoardModalProps {
  title?: Board['title'];
  trackLocation: AirActionTrackingLocation;
  selectedParentBoard?: InitialBoardOption;
  initialLibrary?: BoardSearchLibrary;
}

export const CreateBoardModal = ({
  title,
  trackLocation,
  onClose,
  selectedParentBoard,
  initialLibrary,
}: AirModalProps<CreatePrivateBoardModalProps>) => {
  const { goToBoardPage } = useGoToBoardPage();
  const { createNewBoard } = useCreateNewBoard();
  const { showToast } = useToasts();
  const clipIdsToBeAdded: string[] = useSelector(selectedClipIdsSelector);
  const currentBoard = useSelector(centralizedBoardSelector);
  const [isLoading, setLoading] = useState(false);

  const { showingSubscriptionExpiredModal } = useShowSubscriptionExpiredModal({ onClose });

  const {
    selectedBoard: parentBoard,
    isBoardSelected,
    isLibrarySelected,
    onSelectBoard,
    onSelectLibrary,
    selectedLibrary,
    shouldShowSubBoards,
    shouldShowLibraryBoards,
  } = useSingleBoardSearchState({
    initialBoard: !initialLibrary ? selectedParentBoard || currentBoard : undefined,
    initialLibrary,
  });

  const onBoardSelectChange: PrivateBoardSearchProps['onBoardSelectChange'] = useCallback(
    ({ board, selected }) => {
      onSelectBoard(selected ? board : null);
    },
    [onSelectBoard],
  );

  const { getBoardPermissions } = useBoardPermissionsCache();
  const { getLibraryPermissions } = useLibraryPermissionsCache();

  const getIsBoardDisabled: Required<PrivateBoardSearchProps>['getIsBoardDisabled'] = useCallback(
    (board) => {
      const boardPermissions = getBoardPermissions(board.id);

      if (!canCreateBoard(boardPermissions)) {
        return {
          isDisabled: true,
          message: 'You do not have permission create boards in this board',
        };
      }
    },
    [getBoardPermissions],
  );

  const getIsLibraryDisabled: Required<PrivateBoardSearchProps>['getIsLibraryDisabled'] = useCallback(
    (library) => {
      const libraryPermissions = getLibraryPermissions(library.id);

      if (!canCreateBoard(libraryPermissions)) {
        return {
          isDisabled: true,
          message: 'You do not have permission create boards in this library',
        };
      }
    },
    [getLibraryPermissions],
  );

  if (showingSubscriptionExpiredModal) {
    return null;
  }

  const onCreate = async ({
    title,
    parentId,
    libraryId,
  }: {
    title: string;
    parentId: string | undefined;
    libraryId: string | undefined;
  }) => {
    setLoading(true);

    try {
      const board = await createNewBoard({
        board: {
          title,
          description: '',
          parentId: parentId !== 'root' ? parentId : undefined,
          libraryId,
        },
        clipIds: clipIdsToBeAdded,
        trackLocation,
        shouldFollow: true,
      });

      showToast(
        <div className="flex w-full items-center justify-center overflow-hidden">
          {'Created board '}
          <ToastLink
            data-testid={CREATED_BOARD_LINK}
            className="flex-1 pl-1.5"
            onClick={() => goToBoardPage({ board, trackLocation: 'created-board-toast' })}
          >
            {title}
          </ToastLink>
        </div>,
      );

      onClose();
    } catch (_error) {
      showToast('An error occurred - please try again later');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Formik<CreateBoardFormFields>
      validationSchema={CreateBoardSchema}
      onSubmit={({ title }) => {
        onCreate({
          title: title.trim(),
          parentId: parentBoard?.id,
          libraryId: selectedLibrary?.id || parentBoard?.library?.id,
        });
      }}
      initialValues={{ title: title || '' }}
    >
      {({ values, errors }) => (
        <Modal isOpen onDismiss={onClose} data-testid="CREATE_BOARD_MODAL">
          <header className="mb-4 flex justify-between gap-4">
            <ModalTitle>Create a board</ModalTitle>
            <ModalCloseButton className="shrink-0" onClick={onClose} />
          </header>
          <Form id="create-board-form">
            <label className="mb-4 inline-block text-grey-10">
              Boards keep groups of files organized in one place.
            </label>

            <div className="mb-8">
              <FormikField
                label="Name"
                name="title"
                id="title"
                component={<Input size="extra-large" autoFocus autoComplete="off" />}
              />
            </div>

            <div className="mb-6">
              <label className="mb-1.5 block text-14 font-semibold text-grey-11">Location</label>
              <PrivateBoardSearch
                isBoardSelected={isBoardSelected}
                onBoardSelectChange={onBoardSelectChange}
                shouldShowSubBoards={shouldShowSubBoards}
                shouldShowLibraryBoards={shouldShowLibraryBoards}
                initialBoardId={parentBoard?.id}
                className="[&>.workspace-boards]:h-[200px]"
                onSelectLibrary={onSelectLibrary}
                isLibrarySelected={isLibrarySelected}
                getIsBoardDisabled={getIsBoardDisabled}
                getIsLibraryDisabled={getIsLibraryDisabled}
              />
            </div>
            <footer className="flex justify-end gap-3">
              <Button appearance="ghost" color="grey" onClick={onClose} size="large">
                Cancel
              </Button>
              <Button
                data-testid="CREATE_BOARD_MODAL_SAVE"
                isLoading={isLoading}
                disabled={isLoading || !pageIsValid(errors, values)}
                appearance="filled"
                color="blue"
                size="large"
                type="submit"
              >
                Save
              </Button>
            </footer>
          </Form>
        </Modal>
      )}
    </Formik>
  );
};
