import { ClipsListResponse, PublicClip } from '@air/api';
import { Board, Clip, ClipAndBoardListItem, ListResponse, PublicBoard } from '@air/api/types';
import { Upload } from '@air/redux-uploader';

import { SearchClipsAndBoardsResponse } from '~/types/KanbanData';

interface UseMixedGalleryParams {
  type: ClipAndBoardListItem['type'];
}

export interface UseGalleryMixedAsset<C> extends UseMixedGalleryParams {
  type: 'asset' | 'file';
  item: C;
}

export interface UseGalleryMixedBoard<B> extends UseMixedGalleryParams {
  type: 'board';
  item: B;
}

export type UseGalleryMixedItem = UseGalleryMixedBoard<Board | PublicBoard> | UseGalleryMixedAsset<Clip | PublicClip>;

export const itemIsBoard = (item: UseGalleryMixedItem): item is UseGalleryMixedBoard<Board> => item.type === 'board';
export const itemIsPublicBoard = (item: UseGalleryMixedItem): item is UseGalleryMixedBoard<PublicBoard> =>
  item.type === 'board';

export const itemIsAsset = (item: UseGalleryMixedItem): item is UseGalleryMixedAsset<Clip> => item.type === 'asset';
export const itemIsPublicAsset = (item: UseGalleryMixedItem): item is UseGalleryMixedAsset<PublicClip> =>
  item.type === 'asset';

export const itemIsFile = (item: UseGalleryMixedItem): item is UseGalleryMixedAsset<Clip> => item.type === 'file';
export const itemIsPublicFile = (item: UseGalleryMixedItem): item is UseGalleryMixedAsset<PublicClip> =>
  item.type === 'file';

export interface UseGalleryMixedData<B, C> {
  data: (UseGalleryMixedBoard<B> | UseGalleryMixedAsset<C>)[];
  isLoading: boolean;
  isLoadingMore: boolean;
  total: number;
  hasMore: boolean;
  loadNextPage: () => void;
  isEmpty: boolean;
}

export interface UsePrivateGalleryMixedData extends UseGalleryMixedData<Board, Clip> {
  uploads: Upload[];
}

export interface UseGalleryDataInfo<T> {
  items: T[];
  isLoading: boolean;
  total: number;
  hasMore: boolean;
}

export interface UseGalleryViewItemsReturn<B, C> {
  data: {
    boards: UseGalleryDataInfo<B>;
    clips: UseGalleryDataInfo<C>;
    files: UseGalleryDataInfo<C>;
  };
  isLoading: boolean;
  loadNextPage: () => void;
  isEmpty: boolean;
}

export interface UseGalleryViewItemsInfoParams<T> {
  data?: T;
  loadNextPage: () => void;
  isValidating: boolean;
  isVisible: boolean;
  hasMore: boolean;
}

export interface UseGalleryViewItemsParams<B, C> {
  boards: UseGalleryViewItemsInfoParams<ListResponse<B>[]>;
  clips: UseGalleryViewItemsInfoParams<ClipsListResponse<C>[]>;
  files: UseGalleryViewItemsInfoParams<ClipsListResponse<C>[]>;
}

export const defaultBoardsData = {
  data: [],
  total: 0,
  pagination: {
    cursor: null,
    hasMore: false,
  },
};

export const defaultAssetsData = {
  data: {
    clips: [],
    total: 0,
  },
  pagination: {
    cursor: null,
    hasMore: false,
  },
};

export const defaultTableViewData: SearchClipsAndBoardsResponse = {
  data: [],
  pagination: {
    cursor: null,
    hasMore: false,
  },
  total: 0,
};
