import { Board } from '@air/api/types';
import { Slack as SlackIcon } from '@air/next-icons';
import { Button } from '@air/primitive-button';
import { useToasts } from '@air/provider-toast';
import { memo } from 'react';

import { useConnectToSlack } from '../hooks/useConnectToSlack';

interface ConnectToSlackProps {
  board: Board;
}

export const ConnectToSlack = memo(({ board }: ConnectToSlackProps) => {
  const { connectToSlack } = useConnectToSlack();
  const { showToast } = useToasts();

  const onConnectToSlack = () => {
    try {
      connectToSlack({ board, trackLocation: 'edit-board-details-modal' });
    } catch (_error) {
      showToast('There was an error with slack authentication. Please try again later', {
        timeBeforeAutoDismiss: null,
        type: 'assertive',
        withCloseButton: true,
      });
    }
  };

  return (
    <Button
      appearance="filled"
      color="grey"
      size="extra-large"
      className="mt-2 w-full justify-start font-normal"
      prefix={<SlackIcon className="h-4 w-4" />}
      onClick={onConnectToSlack}
    >
      Connect to Slack
    </Button>
  );
});

ConnectToSlack.displayName = 'ConnectToSlack';
