import { GetBoardResponse } from '@air/api';
import { Board } from '@air/api/types';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

import { getPageBoardKey } from '~/constants/react-query-keys';
import { useGetSubnavSortValue } from '~/swr-hooks/subnav/useSubnavSort';
import {
  useAddBoardsToAllViews,
  useRearrangeBoardsInGallery,
  useUpdateBoardInAllViews,
} from '~/utils/mutateUtils/GalleryBoards';
import { UpdatedBoard } from '~/utils/mutateUtils/mutators';
import {
  useAddBoardsToSideNav,
  useRearrangeBoardsInSideNav,
  useToggleFavoritedSideNavBoard,
  useUpdateSideNavBoard,
} from '~/utils/mutateUtils/SideNavBoards';

export const useUpdateBoardInAllLists = () => {
  const { updateBoardInAllViews } = useUpdateBoardInAllViews();
  const { updateSideNavBoard } = useUpdateSideNavBoard();
  const { getSubNavSortValue } = useGetSubnavSortValue();
  const queryClient = useQueryClient();

  /**
   * Use this method to update boards in all lists:
   * - side nav
   * - gallery
   * - board page
   */
  const updateBoardInAllLists = useCallback(
    (updatedBoard: UpdatedBoard) => {
      const boardSort = getSubNavSortValue()?.boardSort;
      updateSideNavBoard(updatedBoard, boardSort);
      updateBoardInAllViews(updatedBoard);
      queryClient.setQueryData<GetBoardResponse | undefined>(getPageBoardKey(updatedBoard.id), (oldData) => {
        if (!oldData) return;
        return {
          ...oldData,
          ...updatedBoard,
        };
      });
    },
    [getSubNavSortValue, updateSideNavBoard, updateBoardInAllViews, queryClient],
  );

  return {
    updateBoardInAllLists,
  };
};

export const useFavoriteBoardInAllLists = () => {
  const { toggleFavoritedSideNavBoard } = useToggleFavoritedSideNavBoard();
  const { updateBoardInAllViews } = useUpdateBoardInAllViews();

  /**
   * Use this method to update favorited boards in all lists:
   * - side nav
   * - gallery
   */
  const favoriteBoardInAllLists = useCallback(
    (board: Board) => {
      const newBoard = { ...board, hasCurrentUser: true };
      toggleFavoritedSideNavBoard(newBoard);
      updateBoardInAllViews(newBoard);
    },
    [toggleFavoritedSideNavBoard, updateBoardInAllViews],
  );

  return {
    favoriteBoardInAllLists,
  };
};

export const useUnfavoriteBoardInAllLists = () => {
  const { toggleFavoritedSideNavBoard } = useToggleFavoritedSideNavBoard();
  const { updateBoardInAllViews } = useUpdateBoardInAllViews();

  /**
   * Use this method to update favorited boards in all lists:
   * - side nav
   * - gallery
   */
  const unfavoriteBoardInAllLists = useCallback(
    (board: Board) => {
      const newBoard = { ...board, hasCurrentUser: false };
      toggleFavoritedSideNavBoard(newBoard);
      updateBoardInAllViews(newBoard);
    },
    [toggleFavoritedSideNavBoard, updateBoardInAllViews],
  );

  return {
    unfavoriteBoardInAllLists,
  };
};

export const useRearrangeBoardsInAllLists = () => {
  const { rearrangeBoardsInGallery } = useRearrangeBoardsInGallery();
  const { rearrangeBoardsInSideNav } = useRearrangeBoardsInSideNav();

  /**
   * Use this method to rearrange boards in all lists:
   * - side nav
   * - gallery
   */
  const rearrangeBoardsInAllLists = useCallback(
    (rearrangedBoards: Board[]) => {
      const parentBoardId = rearrangedBoards[0].parentId;
      const libraryId = rearrangedBoards[0].library?.id;
      rearrangeBoardsInGallery({ parentBoardId: parentBoardId ?? undefined, rearrangedBoards, libraryId });
      rearrangeBoardsInSideNav(rearrangedBoards);
    },
    [rearrangeBoardsInGallery, rearrangeBoardsInSideNav],
  );

  return {
    rearrangeBoardsInAllLists,
  };
};

export const useAddBoardsToAllLists = () => {
  const { addBoardsToAllViews } = useAddBoardsToAllViews();
  const { addBoardsToSideNav } = useAddBoardsToSideNav();
  const { getSubNavSortValue } = useGetSubnavSortValue();

  const addBoardsToAllLists = useCallback(
    async (boards: Board[], parentId?: Board['id'] | null) => {
      const boardSort = getSubNavSortValue()?.boardSort;
      const libraryId = boards[0].library?.id;

      await Promise.all([
        addBoardsToSideNav(boards, boardSort),
        addBoardsToAllViews({
          boardsToAdd: boards,
          parentBoardId: parentId ?? undefined,
          // if we are adding board to another board, we do not want to refresh library page
          libraryId: parentId ? undefined : libraryId,
        }),
      ]);
    },
    [addBoardsToAllViews, addBoardsToSideNav, getSubNavSortValue],
  );

  return {
    addBoardsToAllLists,
  };
};
