import { ListSlackIntegrationsObject } from '@air/api/types';
import { ConfirmationModal } from '@air/component-confirmation-modal';

import { useRemoveBoardSlackIntegration } from '~/swr-hooks/slack/useRemoveBoardSlackIntegration';

export interface DeleteSlackIntegrationModalProps extends AirModalProps {
  integration: ListSlackIntegrationsObject;
  boardId: string;
}

export const DeleteBoardSlackIntegrationModal = ({
  onClose,
  integration,
  boardId,
}: DeleteSlackIntegrationModalProps) => {
  const { removeBoardSlackIntegration } = useRemoveBoardSlackIntegration();

  const onClick = async () => {
    removeBoardSlackIntegration({
      integrationId: integration.integrationId,
      receiverId: integration.receiverId,
      boardId,
      channelName: integration.channelName,
    });
    onClose();
  };

  const modalDescription = (
    <p className="text-14 text-grey-11">
      Are you sure you want to disconnect this Slack integration? Any other integrations you’ve created will remain
      active.
    </p>
  );

  return (
    <ConfirmationModal
      isOpen
      id="delete-slack-integration-modal"
      title="Disconnect Slack channel?"
      description={modalDescription}
      onDismiss={onClose}
      ctaPrimaryText="Disconnect"
      onConfirm={onClick}
      ctaSecondaryText="Cancel"
      isDangerous
    />
  );
};
