import { Tasks } from '@air/api';
import { useCallback } from 'react';

import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';
import { DuplicateBoardTask, GetBaseMetadata } from '~/store/tasks/types';
import { useCreateTask } from '~/utils/taskUtils/useCreateTask';

export type CreateDuplicateBoardTaskParams = GetBaseMetadata<DuplicateBoardTask>;

export const useCreateDuplicateBoardTask = () => {
  const { createTask } = useCreateTask();
  const { currentWorkspace } = useCurrentWorkspace();

  const createDuplicateBoardTask = useCallback(
    async ({
      sourceBoardIds,
      prefix,
      targetBoardId,
      sourceBoardName,
      trackLocation,
    }: CreateDuplicateBoardTaskParams) => {
      const workspaceId = currentWorkspace?.id;
      if (!workspaceId) {
        throw new Error('No workspace id');
      }

      return createTask({
        task: {
          type: 'DuplicateBoard',
          metadata: { sourceBoardIds, prefix, targetBoardId, sourceBoardName, trackLocation },
          workspaceId,
        },
        createTask: () => {
          return Tasks.createTask({
            workspaceId,
            task: {
              type: 'ContentDuplicator',
              args: { sourceBoardIds, prefix, targetBoardId },
            },
          });
        },
      });
    },
    [createTask, currentWorkspace?.id],
  );

  return {
    createDuplicateBoardTask,
  };
};
