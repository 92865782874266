import { tailwindMerge } from '@air/tailwind-variants';
import { ComponentProps, forwardRef, memo } from 'react';

export type TreeItemButtonProps = ComponentProps<'button'>;

export const TreeItemButton = memo(
  forwardRef<HTMLButtonElement, TreeItemButtonProps>(({ className, children, ...restOfProps }, forwardedRef) => {
    return (
      <button
        data-testid="TREE_ITEM_BUTTON"
        className={tailwindMerge(
          'border-box disabled:opacity-45 flex h-full w-full min-w-0 items-center justify-start text-14 after:absolute after:inset-0 after:-z-1 after:h-full after:w-full after:content-[""] disabled:cursor-not-allowed',
          className,
        )}
        ref={forwardedRef}
        type="button"
        {...restOfProps}
      >
        <span className="truncate">{children}</span>
      </button>
    );
  }),
);

TreeItemButton.displayName = 'TreeItemButton';
