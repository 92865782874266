import { GetTaskResponse, TaskType as RemoteTaskType } from '@air/api/types';
import { useCallback, useRef } from 'react';
import { batch, useDispatch } from 'react-redux';

import { useEventSubscription } from '~/hooks/useEventSubscription';
import {
  markTaskAsCompletedAction,
  markTaskAsFailedAction,
  setTaskAction,
  updateTaskMetadataAction,
} from '~/store/tasks/actions';
import {
  GetCompletedMetadata,
  GetInProgressMetadata,
  GetTaskByType,
  TaskType as LocalTaskType,
  TaskType,
} from '~/store/tasks/types';
import { AirState } from '~/store/types';
import { reportErrorToBugsnag } from '~/utils/ErrorUtils';
import { useAirStore } from '~/utils/ReduxUtils';

import { getTasksFromLocalStorage, removeTasksFromLocalStorage, updateTaskInLocalStorage } from './storage';

type TasksSelector<T extends TaskType> = ({ tasks }: AirState) => GetTaskByType<T>[];

type GetTaskResponseByType<T extends RemoteTaskType> = Extract<GetTaskResponse, { type: T }>;

type NormalizedTaskFromServer<T extends RemoteTaskType> = Pick<
  GetTaskResponseByType<T>,
  'type' | 'id' | 'attempts' | 'completedAt'
> & {
  data?: GetTaskResponseByType<T>['data'];
};

export interface SharedSyncTasksParams<LocalType extends LocalTaskType, RemoteType extends RemoteTaskType> {
  localType: LocalType;
  remoteType: RemoteType;
  onComplete?: (params: {
    task: NormalizedTaskFromServer<RemoteType>;
    localTask: GetTaskByType<LocalType>;
    enrichMetadata: (metadata: Partial<GetCompletedMetadata<GetTaskByType<LocalType>, false>>) => void;
  }) => Promise<void> | void;
  onUpdate?: (params: {
    task: NormalizedTaskFromServer<RemoteType>;
    localTask: GetTaskByType<LocalType>;
    enrichMetadata: (metadata: Partial<GetInProgressMetadata<GetTaskByType<LocalType>>>) => void;
  }) => Promise<void> | void;
  onError?: (params: { task: NormalizedTaskFromServer<RemoteType>; localTask: GetTaskByType<LocalType> }) => void;
  tasksSelector: TasksSelector<LocalType>;
  getTask: (taskId: string) => Promise<GetTaskResponse>;
  workspaceId?: string;
}

export const useSharedSyncTasks = <
  LocalType extends LocalTaskType = LocalTaskType,
  RemoteType extends RemoteTaskType = RemoteTaskType,
>({
  localType,
  remoteType,
  tasksSelector,
  getTask,
  workspaceId,
  onComplete,
  onUpdate,
  onError,
}: SharedSyncTasksParams<LocalType, RemoteType>) => {
  type CurrentTaskType = GetTaskByType<LocalType>;

  const dispatch = useDispatch();
  const store = useAirStore();

  const onCompleteRef = useRef(onComplete);
  onCompleteRef.current = onComplete;

  const onUpdateRef = useRef(onUpdate);
  onUpdateRef.current = onUpdate;

  const onErrorRef = useRef(onError);
  onErrorRef.current = onError;

  const syncLocalTasks = useCallback(() => {
    const tasks = tasksSelector(store.getState());

    tasks.forEach((localTask) => {
      const { localTaskId, remoteTaskId } = localTask;
      if (!remoteTaskId) {
        return;
      }

      getTask(remoteTaskId)
        .then(async (remoteTask) => {
          if (remoteTask.type !== remoteType) {
            return;
          }

          batch(async () => {
            dispatch(
              updateTaskMetadataAction({
                localTaskId,
                metadata: {
                  ...remoteTask.data,
                },
              }),
            );

            switch (remoteTask.status) {
              case 'exception':
              case 'failed':
                dispatch(
                  markTaskAsFailedAction({
                    localTaskId,
                  }),
                );
                onErrorRef.current?.({
                  task: remoteTask as unknown as NormalizedTaskFromServer<RemoteType>,
                  localTask,
                });
                break;

              case 'succeeded': {
                const enricher = makeTaskMetadataEnricher<GetCompletedMetadata<CurrentTaskType, false>>();

                await onCompleteRef.current?.({
                  task: remoteTask as unknown as NormalizedTaskFromServer<RemoteType>,
                  localTask,
                  enrichMetadata: enricher.set,
                });
                removeTasksFromLocalStorage([localTaskId]);

                dispatch(
                  markTaskAsCompletedAction({
                    localTaskId,
                    metadata: enricher.get() ?? {},
                  }),
                );
              }
            }
          });
        })
        .catch((error) => {
          reportErrorToBugsnag({
            error,
            context: 'Failed to get task useSharedSyncTasks',
          });
        });
    });
  }, [dispatch, getTask, remoteType, store, tasksSelector]);

  const loadFromStorage = useCallback(() => {
    const tasks = getTasksFromLocalStorage().filter((task) => task.type === localType);
    batch(() => {
      tasks.forEach((localStorageTask) => {
        dispatch(setTaskAction(localStorageTask));
      });
    });
    syncLocalTasks();
  }, [dispatch, syncLocalTasks, localType]);

  useEventSubscription({
    currentWorkspaceId: workspaceId,
    event: {
      eventType: 'System.Task.updated',
      pattern: {
        type: remoteType,
      },
    },
    options: {
      onUpdate: async (message) => {
        const taskId = message.data.id;
        const tasks = tasksSelector(store.getState());

        const localTask = tasks.find(
          (taskCandidate): taskCandidate is CurrentTaskType => taskCandidate.remoteTaskId === taskId,
        );

        if (!localTask) {
          return;
        }

        const enricher = makeTaskMetadataEnricher<GetInProgressMetadata<CurrentTaskType>>();

        await onUpdateRef.current?.({
          task: message.data as unknown as NormalizedTaskFromServer<RemoteType>,
          localTask,
          enrichMetadata: enricher.set,
        });

        const metadata =
          message.data.data || enricher.get()
            ? {
                ...message.data.data,
                ...enricher.get(),
              }
            : undefined;

        if (metadata) {
          updateTaskInLocalStorage<CurrentTaskType>({
            ...localTask,
            status: 'in-progress',
            metadata: {
              ...localTask.metadata,
              ...message.data.data,
              ...metadata,
            },
          });

          dispatch(
            updateTaskMetadataAction({
              localTaskId: localTask.localTaskId,
              metadata,
            }),
          );
        }
      },
    },
  });

  useEventSubscription({
    currentWorkspaceId: workspaceId,
    event: {
      eventType: 'System.Task.completed',
      pattern: {
        type: remoteType,
      },
    },
    options: {
      onUpdate: async (message) => {
        const tasks = tasksSelector(store.getState());

        const remoteTask = message.data as unknown as NormalizedTaskFromServer<RemoteType>;

        const localTask = tasks.find(({ remoteTaskId }) => remoteTaskId === message.data.id);

        if (localTask) {
          const { localTaskId } = localTask;

          removeTasksFromLocalStorage([localTaskId]);

          if (message.data.status === 'failed' || message.data.status === 'exception') {
            dispatch(
              markTaskAsFailedAction({
                localTaskId,
              }),
            );
            onErrorRef.current?.({ task: remoteTask, localTask });
          } else {
            const enricher = makeTaskMetadataEnricher<GetCompletedMetadata<CurrentTaskType, false>>();
            await onCompleteRef.current?.({
              task: remoteTask,
              localTask,
              enrichMetadata: enricher.set,
            });

            const metadata = {
              ...remoteTask.data,
              ...enricher.get(),
            };

            dispatch(
              markTaskAsCompletedAction({
                localTaskId: localTask.localTaskId,
                metadata,
              }),
            );
          }
        }
      },
    },
  });

  return { syncLocalTasks, loadFromStorage };
};

function makeTaskMetadataEnricher<T>() {
  const value = {
    current: undefined as Partial<T> | undefined,
  };

  return {
    set: (metadata: Partial<T>) => {
      value.current = metadata;
    },
    get: () => value.current,
  };
}
