import { Box } from '@air/zephyr';
import styled from 'styled-components';
interface ContainerProps {
  isLoaded: boolean;
  borderRadius: number;
}

export const Container = styled(Box)<ContainerProps>`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  background: ${({ theme, isLoaded }) => (isLoaded ? theme.colors.pigeon025 : theme.colors.pigeon200)};
  border-radius: ${({ borderRadius }) => `${borderRadius}px`};
  pointer-events: none;
  overflow: hidden;

  > img {
    display: block;
    max-width: 100%;
    height: auto;
    opacity: ${({ isLoaded }) => (isLoaded ? 1 : 0)};
    border-radius: ${({ borderRadius }) => `${borderRadius}px`};
  }
`;
