import { ShortUrl } from '@air/api';
import { Board, Clip, Form, ShortUrlResponse } from '@air/api/types';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { merge } from 'lodash';

import { reportErrorToBugsnag } from '~/utils/ErrorUtils';

import { getShortIdInformationKey } from './useGetExistingShortIdInformation';

export type UpdateShortIdParams = Parameters<typeof ShortUrl.update>[0] & {
  objectId: Board['id'] | Clip['id'] | Form['id'];
  boardContext: Board['id'] | undefined;
  permissions: ShortUrlResponse['permissions'];
};

export const useUpdateShortId = () => {
  const queryClient = useQueryClient();

  const updateShortId = useMutation({
    mutationFn: (params: UpdateShortIdParams) => {
      const { permissions, ...rest } = params;

      return ShortUrl.update({
        ...rest,
        permissions: {
          asset: permissions.asset,
          customField: permissions.customField,
        },
      });
    },
    onMutate: ({ objectId, ...rest }) => {
      const previousShortInfo = queryClient.getQueryData<ShortUrlResponse>(
        getShortIdInformationKey({ objectId, boardContext: rest.boardContext }),
      );

      if (previousShortInfo) {
        queryClient.setQueryData<ShortUrlResponse>(
          getShortIdInformationKey({ objectId, boardContext: rest.boardContext }),
          merge({}, previousShortInfo, rest),
        );
      }

      return { previousShortInfo };
    },
    onSuccess: (data, { objectId, boardContext }) => {
      const previousShortInfo = queryClient.getQueryData<ShortUrlResponse>(
        getShortIdInformationKey({ objectId, boardContext }),
      );

      if (previousShortInfo) {
        queryClient.setQueryData<ShortUrlResponse>(
          getShortIdInformationKey({ objectId, boardContext }),
          merge({}, previousShortInfo, data),
        );
      }
    },
    onError: (error, { objectId, ...rest }, context) => {
      reportErrorToBugsnag({
        error,
        context: 'Failed to update shortId ',
        metadata: { data: { objectId, ...rest } },
      });

      if (context?.previousShortInfo) {
        queryClient.setQueryData<ShortUrlResponse>(
          getShortIdInformationKey({ objectId, boardContext: rest.boardContext }),
          context?.previousShortInfo,
        );
      }
    },
  });

  return {
    updateShortId,
  };
};
