/**
 * @see https://docs.google.com/spreadsheets/d/1FNN_n0FiP49GCljsiqg1CMwv99KU6wWEloWiet3t_j8/edit#gid=583702960
 */

import { ScopedPermissions } from '@air/api';

export const canViewShortUrl = (sourceContext: ScopedPermissions | undefined) => !!sourceContext?.shortUrl.canView;

export const canCreateShortUrl = (sourceContext: ScopedPermissions | undefined) => !!sourceContext?.shortUrl.canCreate;

export const canEditShortUrl = (shortUrlContext: ScopedPermissions | undefined) => !!shortUrlContext?.shortUrl.canEdit;

export const canDeleteShortUrl = (shortUrlContext: ScopedPermissions | undefined) =>
  !!shortUrlContext?.shortUrl.canDelete;
