import { useAirModal } from '@air/provider-modal';

import { DuplicateBoardModal } from '~/components/Modals/DuplicateBoard';

export const useDuplicateBoard = () => {
  const [showDuplicateBoardsModal] = useAirModal(DuplicateBoardModal);

  return {
    showDuplicateBoardsModal,
  };
};
