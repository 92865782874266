import { useTrackDisconnectedSlackIntegration } from '@air/analytics';
import { Slack } from '@air/api';
import { Board, ListSlackIntegrationsObject, ListSlackIntegrationsResponse } from '@air/api/types';
import { useToasts } from '@air/provider-toast';
import { reportErrorToBugsnag } from '@air/utils-error';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';
import { centralizedBoardTitleSelector } from '~/store/centralizedBoard/selectors';

import { getBoardSlackIntegrationsKey } from './useBoardSlackIntegrations';

interface RemoveBoardSlackIntegrationProps {
  integrationId: ListSlackIntegrationsObject['integrationId'];
  receiverId: ListSlackIntegrationsObject['receiverId'];
  channelName: string;
  boardId?: Board['id'];
}

export const useRemoveBoardSlackIntegration = () => {
  const { currentWorkspace } = useCurrentWorkspace();
  const queryClient = useQueryClient();
  const { showToast } = useToasts();
  const boardTitle = useSelector(centralizedBoardTitleSelector);

  const { trackDisconnectedSlackIntegration } = useTrackDisconnectedSlackIntegration();

  const mutation = useMutation<
    void,
    unknown,
    RemoveBoardSlackIntegrationProps,
    { prevIntegrations: ListSlackIntegrationsResponse | undefined }
  >({
    mutationFn: async ({ integrationId, receiverId, boardId, channelName }) => {
      const workspaceId = currentWorkspace?.id;
      if (!workspaceId) {
        throw new Error('No workspace id');
      }
      if (boardId) {
        await Slack.delIntegrationForBoard({
          workspaceId,
          integration: { integrationId, receiverId },
          boardId,
        });
      }
      trackDisconnectedSlackIntegration({
        boardTitle: boardTitle || '',
        trackLocation: 'edit-board-details-modal',
        channelName,
      });
    },

    onMutate: async (props) => {
      const key = getBoardSlackIntegrationsKey(props.boardId!);

      const prevIntegrations = queryClient.getQueryData<ListSlackIntegrationsResponse | undefined>(key);

      queryClient.setQueryData<ListSlackIntegrationsResponse | undefined>(
        key,
        (integrations) => integrations?.filter((f) => f.integrationId !== props.integrationId),
      );

      return { prevIntegrations };
    },

    onError: (error, { integrationId, receiverId, boardId }, context) => {
      showToast('Failed to remove slack integration for board. Please try again.');
      reportErrorToBugsnag({
        error,
        context: 'Failed to delete board slack integration',
        metadata: {
          integrationId,
          receiverId,
          boardId,
          error,
        },
      });
      if (boardId) {
        queryClient.setQueryData(getBoardSlackIntegrationsKey(boardId), context?.prevIntegrations);
      }
    },
  });

  const removeBoardSlackIntegration = useCallback(
    async (removeIntegrationParams: RemoveBoardSlackIntegrationProps) => mutation.mutate(removeIntegrationParams),
    [mutation],
  );

  return {
    removeBoardSlackIntegration,
  };
};
