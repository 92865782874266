import { useAirModal } from '@air/provider-modal';

import { MoveItemsModal } from '~/components/Modals/MoveItemsModal/MoveItemsModal';

export const useMoveItemsModal = () => {
  const [showMoveItemsModal] = useAirModal(MoveItemsModal);

  return {
    showMoveItemsModal,
  };
};
