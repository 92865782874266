import { AirActionTrackingLocation, useTrackDownloadedItems } from '@air/analytics';
import { Board } from '@air/api/types';
import { useCallback } from 'react';

import { useCreatePrivateZippingTask } from '~/components/ZippingTasks/hooks/useCreatePrivateZippingTask';

export interface DownloadPrivateBoardParams {
  /** Board id of the board you're downloading */
  boardId: Board['id'];
  /** Where in the UI this function was trigger from */
  trackLocation: AirActionTrackingLocation;
}

/**
 * This hook returns a function that downloads a single private board
 * If you are trying to download multiple private boards, @see useDownloadPrivateItems
 */
export function useDownloadPrivateBoard() {
  const { trackDownloadedItems } = useTrackDownloadedItems();
  const { createPrivateZippingTask } = useCreatePrivateZippingTask();

  const downloadPrivateBoard = useCallback(
    ({ boardId, trackLocation }: DownloadPrivateBoardParams) => {
      createPrivateZippingTask({
        boardIds: [boardId],
        clipIds: undefined,
      });

      trackDownloadedItems({
        boardIds: [boardId],
        location: trackLocation,
      });
    },
    [createPrivateZippingTask, trackDownloadedItems],
  );

  return {
    downloadPrivateBoard,
  };
}
