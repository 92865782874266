import { Libraries } from '@air/api';
import type { LibraryListOptions, WorkspaceInfo } from '@air/api/types';
import type { QueryObserverOptions } from '@tanstack/react-query';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { isObject } from 'lodash';

import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';
import { getFetchParamsFromKey } from '~/swr-hooks/utils';

export const LIBRARIES_KEY = 'LIBRARIES';

export const LIBRARIES_DEFAULT_OPTIONS: Omit<LibraryListOptions, 'cursor'> = {
  limit: 100,
  isMemberOf: 'YES',
};

export type LibrariesKey = [
  typeof LIBRARIES_KEY,
  Omit<LibraryListOptions, 'cursor'> & {
    workspaceId?: WorkspaceInfo['id'];
  },
];

export const getLibrariesKey = (
  workspaceId?: WorkspaceInfo['id'],
  options?: Omit<LibraryListOptions, 'cursor'>,
): LibrariesKey => [
  LIBRARIES_KEY,
  {
    workspaceId,
    ...LIBRARIES_DEFAULT_OPTIONS,
    ...options,
  },
];

export const isLibariesKey = (key: unknown): key is LibrariesKey => {
  return Array.isArray(key) && key[0] === LIBRARIES_KEY && isObject(key[1]);
};

export type UseLibrariesParams = Pick<QueryObserverOptions, 'enabled'> & {
  key?: LibrariesKey;
  options?: Omit<LibraryListOptions, 'cursor'>;
};

export const useLibraries = ({ enabled = true, options }: UseLibrariesParams = {}) => {
  const { libraries: librariesFlag } = useFlags();
  const { currentWorkspace } = useCurrentWorkspace();

  const key = getLibrariesKey(currentWorkspace?.id, options);
  const _enabled = enabled && !!currentWorkspace?.id && !!librariesFlag;

  return useInfiniteQuery({
    queryKey: key,
    queryFn: ({ pageParam }) => {
      const workspaceId = currentWorkspace?.id;
      if (!workspaceId) {
        throw new Error('No workspace id');
      }
      const { cursor } = getFetchParamsFromKey(pageParam ? `?cursor=${pageParam}` : '');
      return Libraries.list({
        workspaceId,
        cursor: cursor || undefined,
        ...LIBRARIES_DEFAULT_OPTIONS,
        ...options,
      });
    },
    enabled: _enabled,
    getNextPageParam: (lastPage) => lastPage.pagination.cursor,
    refetchOnWindowFocus: true,
    initialPageParam: '',
  });
};
