import { NoPlanUpgradePermissionModal } from '~/components/Modals/PlansModal/NoPlanUpgradePermissionModal';
import { UpgradePlanModal } from '~/components/Modals/PlansModal/UpgradePlanModal';
import { useCurrentWorkspacePermissionsContext } from '~/providers/CurrentWorkspacePermissionsProvider';
import { useSubscriptionContext } from '~/providers/SubscriptionProvider';
import { useCurrentWorkspaceMember } from '~/swr-hooks/members/useCurrentWorkspaceMember';
import { useWorkspaceMembers } from '~/swr-hooks/members/useWorkspaceMembers';
import { canManageSubscription } from '~/utils/permissions/workspacePermissions';

/**
 * This component displays info about paid plans fetched from usePlansList hook
 */
export const PlansModal = ({ onClose }: AirModalProps) => {
  const { members } = useWorkspaceMembers();
  const { currentWorkspaceMember } = useCurrentWorkspaceMember();
  const { data: subscription } = useSubscriptionContext();

  const { data: permissions } = useCurrentWorkspacePermissionsContext();

  if (!members || !subscription || !currentWorkspaceMember) {
    return null;
  }

  const canEditPlan = canManageSubscription(permissions);

  if (!canEditPlan) {
    return <NoPlanUpgradePermissionModal onClose={onClose} />;
  }

  return <UpgradePlanModal subscription={subscription} onClose={onClose} />;
};
