import { useEffect, useRef } from 'react';

import { useWebsocketContext } from '~/providers/SocketContext/SocketContext';
import { ReadyState } from '~/types/sockets';

export interface UseSocketConnectionChangeParams {
  onChange: (readyState: ReadyState | null) => void;
}

export const useSocketConnectionChange = ({ onChange }: UseSocketConnectionChangeParams) => {
  const { readyState } = useWebsocketContext();
  const onChangeRef = useRef(onChange);
  onChangeRef.current = onChange;

  useEffect(() => {
    onChangeRef.current(readyState);
  }, [readyState]);
};
