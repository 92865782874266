import { AirActionTrackingLocation, useTrackEditedBoard } from '@air/analytics';
import { Boards } from '@air/api';
import { Board, BoardInput } from '@air/api/types';
import { useToasts } from '@air/provider-toast';
import { useMutation } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';

import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';
import { editBoardSuccessAction } from '~/store/centralizedBoard/actions';
import { reportErrorToBugsnag } from '~/utils/ErrorUtils';
import { useUpdateBoardInAllLists } from '~/utils/mutateUtils/AllBoards';

export const useUpdateBoard = () => {
  const dispatch = useDispatch();
  const { updateBoardInAllLists } = useUpdateBoardInAllLists();
  const { showToast } = useToasts();
  const { trackEditedBoard } = useTrackEditedBoard();
  const { currentWorkspace } = useCurrentWorkspace();
  const workspaceId = currentWorkspace?.id;

  const updateBoard = useMutation({
    mutationFn: ({
      board,
      values,
    }: {
      board: Board;
      trackLocation: AirActionTrackingLocation;
      values: Partial<BoardInput>;
    }) => {
      if (!workspaceId) {
        throw new Error('No workspaceId found');
      }

      return Boards.update({
        id: board.id,
        props: { ...values, title: values.title?.trim() || board.title },
        workspaceId,
      });
    },
    onMutate: ({ board, values, trackLocation }) => {
      const updatedBoard: Board = { ...board, ...values, title: values.title?.trim() || board.title };

      updateBoardInAllLists(updatedBoard);

      dispatch(editBoardSuccessAction({ board: updatedBoard }));

      trackEditedBoard({ boardId: board.id, location: trackLocation });
    },
    onError: (error, { board, values }) => {
      showToast('There was an error editing the board.');

      throw reportErrorToBugsnag({
        error,
        context: 'Failed to update board',
        metadata: { data: { board, values } },
      });
    },
  });

  return {
    updateBoard,
  };
};
