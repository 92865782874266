import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { removeTasksAction } from '~/store/tasks/actions';
import { removeTasksFromLocalStorage } from '~/utils/taskUtils/storage';

export const useCancelTask = () => {
  const dispatch = useDispatch();

  const cancelTask = useCallback(
    (localTaskId: string) => {
      dispatch(removeTasksAction({ taskIds: [localTaskId] }));
      removeTasksFromLocalStorage([localTaskId]);
    },
    [dispatch],
  );

  return {
    cancelTask,
  };
};
