import { v4 as uuid } from 'uuid';

import { PENDING_SUBSCRIPTIONS, SUBSCRIPTIONS } from '~/constants/sockets';

import { getIterableSubscriptions } from './get-iterable-subscriptions';

// This util is used to convert any active subscription to new pending ones.
// This is needed when 1) the socket disconnects and we have to resubscribe, or
// b) when the workspaceId changes and we have to resubscribe with the new ID.
export const convertSubscriptionsToPending = () => {
  const newPendingSubscriptions = getIterableSubscriptions().map((subscription) => {
    return {
      ...subscription,
      subscriptionId: undefined,
    };
  });
  newPendingSubscriptions.forEach((subscription) => {
    const newUUID = uuid();

    subscription.subscribers.forEach((subscriber) => subscriber.updateUUID(newUUID));

    PENDING_SUBSCRIPTIONS[newUUID] = subscription;
  });

  Object.keys(SUBSCRIPTIONS).forEach((key) => {
    delete SUBSCRIPTIONS[key];
  });
};
