import { SubscriptionPlan } from '@air/api/types';

type SubscriptionPlanDisplayName = 'Free' | 'Plus' | 'Pro' | 'Enterprise' | 'Demo' | 'Plus Pilot' | 'Pro Pilot';

export const subscriptionPlanMap: { [key in SubscriptionPlan]: SubscriptionPlanDisplayName } = {
  basic: 'Free',
  plus: 'Plus',
  pro: 'Pro',
  enterprise: 'Enterprise',
  demo: 'Demo',
};
