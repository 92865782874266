import { Board, SortDirection, SubnavBoardSort } from '@air/api/types';
import { orderBy } from 'lodash';

export const getOrderedBoards = (
  flattenedBoards: Board[],
  boardSort: SubnavBoardSort = { name: '', direction: SortDirection.asc },
) => {
  let orderByField = '';
  switch (boardSort.name) {
    case 'name':
      orderByField = 'title';
      break;
    case 'dateCreated':
      orderByField = 'createdAt';
      break;
    case 'customSort':
      orderByField = 'pos';
      break;
    default:
      break;
  }

  const orderedBoards = orderBy(
    flattenedBoards,
    orderByField === 'title'
      ? (board) => board.title.toLowerCase()
      : orderByField === 'pos'
      ? (board) => board.pos || '' // [Note 1]
      : [orderByField],
    [orderByField === 'pos' ? SortDirection.desc : boardSort.direction],
  );

  // Note 1: Got a failure in SidenavBoards unit test related to `null` being put
  // first and didn't want to introduce a regression.

  return { orderedBoards };
};
