import { TreeList, type TreeListProps } from '@air/component-tree';

export type BoardSelectListProps<Data> = Pick<
  TreeListProps<Data>,
  'initialIndex' | 'items' | 'renderListItem' | 'scrollElement'
> & {
  onCreate?: () => void;
  title?: string;
};

export const BoardSelectList = <Data,>({
  initialIndex,
  items,
  renderListItem,
  scrollElement,
}: BoardSelectListProps<Data>) => {
  return (
    <section className="flex flex-col">
      <TreeList
        initialIndex={initialIndex}
        items={items}
        getEstimateSize={() => 32}
        renderListItem={renderListItem}
        scrollElement={scrollElement}
      />
    </section>
  );
};
