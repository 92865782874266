import { GetBoardResponse } from '@air/api';
import { Board } from '@air/api/types';
import { useQueryClient } from '@tanstack/react-query';
import Router from 'next/router';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { getPageBoardKey } from '~/constants/react-query-keys';
import { createBoardClipRoute } from '~/constants/routes';
import { AssetModalPanel } from '~/providers/AssetModalPanelContextProvider';
import { setCentralizedBoardAction, setCentralizedBoardIdAndTitleAction } from '~/store/centralizedBoard/actions';
import { setCentralizedClipAction } from '~/store/centralizedClip/actions';
import { LocationBoard } from '~/store/router/types';
import { getBoardIdFromPath } from '~/utils/PathUtils';

import { GoToAssetPageProps } from './useGoToAssetPage';

export function useGoToBoardAssetPage() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const goToBoardAssetPage = useCallback(
    ({
      asset,
      board,
      tab,
    }: {
      board: LocationBoard | Board;
      asset: GoToAssetPageProps['asset'];
      tab?: AssetModalPanel;
    }) => {
      const centralizedBoardId = getBoardIdFromPath(window.location.pathname);

      if (centralizedBoardId !== board.id) {
        const cachedBoard = queryClient.getQueryData<GetBoardResponse>(getPageBoardKey(board.id));

        if (cachedBoard) {
          dispatch(setCentralizedBoardAction({ board: cachedBoard }));
        } else {
          dispatch(setCentralizedBoardIdAndTitleAction({ board }));
        }
      }

      dispatch(setCentralizedClipAction({ clip: asset }));
      Router.push(
        {
          pathname: createBoardClipRoute({ clipId: asset.id, boardTitle: board.title, boardId: board.id }),
          query: tab ? { tab } : undefined,
        },
        undefined,
        { shallow: true },
      );
    },
    [dispatch, queryClient],
  );

  return {
    goToBoardAssetPage,
  };
}
