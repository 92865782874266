import { Board } from '@air/api/types';
import Router from 'next/router';

import { createBoardRoute } from '~/constants/routes';
import { LocationBoard } from '~/store/router/types';

export interface BoardPosition {
  id: Board['id'];
  pos: Board['pos'];
}

export const defaultBoard: Board = {
  id: '',
  creatorId: '',
  parentId: '',
  workspaceId: '',
  title: '',
  description: '',
  createdAt: '',
  updatedAt: '',
  permissions: {
    canAdd: true,
    canViewClips: true,
    canContribute: true,
    canShare: true,
    canEdit: true,
    canDelete: true,
    canDeleteChildren: true,
    canDownloadClips: true,
    canView: true,
    canFavorite: true,
    canCreate: true,
    canManageMembers: true,
  },
  // @ts-ignore - https://github.com/AirLabsTeam/next/pull/244#discussion_r502839412
  hasCurrentUser: null,
};

/**
 * This method calculates temporary pos value for custom sorting (original value will be calculated on backend)
 * This method uses simpler met
 *
 * @param previousBoard board just before moved boards
 * @param nextBoard board just after moved boards
 * @param boardsToMove boards we want to move
 */
export const calculateNewBoardsPositions = ({
  previousPosition,
  nextPosition,
  boardsToMove,
}: {
  previousPosition?: number;
  nextPosition?: number;
  boardsToMove: string[];
}): BoardPosition[] => {
  // if previous board is not present, we want to put boards at the beginning of the list (nextBoard is passed)
  const prevPos = previousPosition ? previousPosition : (nextPosition ?? 0) - 1;
  // if next board is not present, we want to put boards at the end of the list (previousBoard is passed)
  const nextPos = nextPosition ? nextPosition : (previousPosition ?? 0) + 1;
  let newPos = (prevPos + nextPos) / 2;

  return boardsToMove.reduce((acc, boardId) => {
    acc.push({
      id: boardId,
      pos: newPos,
    });

    if (!nextPosition || !previousPosition) {
      newPos = (newPos + prevPos) / 2;
    } else {
      newPos = (newPos + nextPos) / 2;
    }

    return acc;
  }, [] as BoardPosition[]);
};

// TODO: request returns incorrect value of hasCurrentUser - it's always true
// Adding ignoreHasCurrentUser will prevent using this board's value for hasCurrentUser
// https://air-labs-team.slack.com/archives/CCS4C8ALT/p1612476953164300
export const getUpdatedBoard = ({
  newBoard,
  oldBoard,
  ignoreHasCurrentUser,
}: {
  newBoard: Partial<Board>;
  oldBoard?: Board;
  ignoreHasCurrentUser?: boolean;
}): Board => {
  let hasCurrentUser = !!newBoard.hasCurrentUser;
  if (ignoreHasCurrentUser) {
    hasCurrentUser = !!oldBoard?.hasCurrentUser;
  }

  let thumbnails: Board['thumbnails'];
  if (newBoard.thumbnails?.length) {
    thumbnails = newBoard.thumbnails;
  } else if (oldBoard && oldBoard.thumbnails) {
    thumbnails = oldBoard.thumbnails;
  }

  let ancestors: Board['ancestors'];
  if (newBoard.ancestors) {
    ancestors = newBoard.ancestors;
  } else if (oldBoard && oldBoard.ancestors) {
    ancestors = oldBoard.ancestors;
  }

  return {
    ...(oldBoard || defaultBoard),
    ...newBoard,
    ancestors,
    thumbnails,
    hasCurrentUser,
  };
};

export const boardPositionsComparator = (boardA: Board, boardB: Board) => (boardB.pos ?? 0) - (boardA.pos ?? 0);

export const addBoardTitleToURL = (board: { id: string; title: string }) => {
  const pathname = Router.asPath;
  const trailingPart = pathname.split(board.id)[1] || '';
  const boardUrlBase = createBoardRoute(board.id, board.title);
  const constructedPath = `${boardUrlBase}${trailingPart}`;

  if (constructedPath !== pathname) Router.replace(constructedPath);
};

export const isCompleteBoard = (board: LocationBoard | Board): board is Board => {
  return !!board.createdAt;
};
