import { BaseCustomField } from '@air/api/types';
import { createContext, PropsWithChildren, useContext, useMemo } from 'react';

import { useCurrentBoardPermissions } from '~/hooks/useCurrentBoardPermissions';
import { useFetchAnyWorkspacePermissions } from '~/hooks/useFetchAnyWorkspacePermissions';
import { useURLBoardIdSelector } from '~/hooks/useURLBoardIdSelector';
import { useGetPrivateCustomFields } from '~/swr-hooks/customFields/useGetPrivateCustomFields';
import { isDevOrTestStage } from '~/swr-hooks/utils';
import { reportErrorToBugsnag } from '~/utils/ErrorUtils';
import { canSeeCustomFields } from '~/utils/permissions/workspacePermissions';

type PrivateCustomFieldsProviderProps = PropsWithChildren<object>;

export interface PrivateCustomFieldsProviderContextValue {
  customFields: BaseCustomField[] | undefined;
  error?: unknown;
}

const defaultValue: PrivateCustomFieldsProviderContextValue = {
  customFields: undefined,
};

const PrivateCustomFieldsProviderContext = createContext<PrivateCustomFieldsProviderContextValue>(defaultValue);

export const PrivateCustomFieldsProvider = ({ children }: PrivateCustomFieldsProviderProps) => {
  const { data: workspacePermissions } = useFetchAnyWorkspacePermissions();
  const { boardPermissions } = useCurrentBoardPermissions();
  const boardId = useURLBoardIdSelector();
  const enabled = canSeeCustomFields(boardId ? boardPermissions : workspacePermissions);
  const { data: customFields, error } = useGetPrivateCustomFields(enabled);

  const value = useMemo(() => ({ customFields: customFields?.data, error }), [customFields?.data, error]);

  return (
    <PrivateCustomFieldsProviderContext.Provider value={value}>{children}</PrivateCustomFieldsProviderContext.Provider>
  );
};

export const usePrivateCustomFieldsContext = () => {
  const context = useContext(PrivateCustomFieldsProviderContext);

  if (context === defaultValue) {
    const error = 'PrivateCustomFieldsProviderContext used outside of PrivateCustomFieldsProvider';

    if (isDevOrTestStage()) {
      throw error;
    } else {
      reportErrorToBugsnag({
        error,
        context: error,
      });
    }
  }

  return context;
};
