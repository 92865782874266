import { noop } from 'lodash';
import { createContext, memo, PropsWithChildren, useContext, useMemo, useState } from 'react';

import { SEARCH_INITIAL_HEADER_HEIGHT } from '~/constants/WorkspaceSpacing';
import { isDevOrTestStage } from '~/swr-hooks/utils';
import { reportErrorToBugsnag } from '~/utils/ErrorUtils';

export interface SearchHeaderHeightProviderType {
  searchHeaderHeight: number;
  setSearchHeaderHeight: (height: number) => void;
}

const defaultValue: SearchHeaderHeightProviderType = {
  searchHeaderHeight: 0,
  setSearchHeaderHeight: noop,
};

export const SearchHeaderHeightContext = createContext<SearchHeaderHeightProviderType>(defaultValue);

export const SearchHeaderHeightProvider = memo(({ children }: PropsWithChildren<object>) => {
  const [searchHeaderHeight, setSearchHeaderHeight] = useState(SEARCH_INITIAL_HEADER_HEIGHT);

  const value = useMemo<SearchHeaderHeightProviderType>(
    () => ({
      searchHeaderHeight,
      setSearchHeaderHeight,
    }),
    [searchHeaderHeight],
  );

  return <SearchHeaderHeightContext.Provider value={value}>{children}</SearchHeaderHeightContext.Provider>;
});

SearchHeaderHeightProvider.displayName = 'SearchHeaderHeightProvider';

export const useSearchHeaderHeightContext = () => {
  const context = useContext(SearchHeaderHeightContext);

  if (context === defaultValue) {
    const error = 'SearchHeaderHeightContext used outside of SearchHeaderHeightProvider';
    if (isDevOrTestStage()) {
      throw error;
    } else {
      reportErrorToBugsnag({
        error,
        context: error,
      });
    }
  }

  return context;
};
