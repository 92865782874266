import { tailwindMerge } from '@air/tailwind-variants';
import { ElementRef, forwardRef } from 'react';
import TextareaAutosizeComponent, {
  TextareaAutosizeProps as TextareaAutosizeComponentProps,
} from 'react-textarea-autosize';

import { TEXTAREA_SIZE_CLASSES, textareaClasses, TextareaSize } from './constants';

export type TextareaAutosizeProps = TextareaAutosizeComponentProps & {
  size?: TextareaSize;
};

export const TextareaAutosize = forwardRef<ElementRef<typeof TextareaAutosizeComponent>, TextareaAutosizeProps>(
  ({ className, size = 'medium', ...restOfProps }, forwardedRef) => {
    return (
      <TextareaAutosizeComponent
        className={tailwindMerge(textareaClasses, TEXTAREA_SIZE_CLASSES[size], className)}
        ref={forwardedRef}
        {...restOfProps}
      />
    );
  },
);

TextareaAutosize.displayName = 'TextareaAutosize';
