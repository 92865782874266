import { FormikInput } from '@air/formik-input';
import { Form, Formik } from 'formik';
import { useEffect, useRef } from 'react';
import { object, string } from 'yup';

const VALIDATION_SCHEMA = object().shape({
  name: string().required('Name is required'),
});

export type BoardSelectCreateInputProps = {
  onBlur?: () => void;
  onSubmit: (name: string) => void;
};

export const BoardSelectCreateInput = ({ onBlur, onSubmit }: BoardSelectCreateInputProps) => {
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  return (
    <Formik
      initialValues={{ name: '' }}
      onSubmit={(values) => onSubmit(values.name)}
      validationSchema={VALIDATION_SCHEMA}
    >
      <Form
        /**
         * The `2px` y padding is to prevent the input focus border from being cut
         * off by the list item hover state.
         */
        className="py-0.5"
      >
        <FormikInput data-1p-ignore className="w-full" onBlur={onBlur} name="name" ref={inputRef} type="text" />
      </Form>
    </Formik>
  );
};
