import { memo } from 'react';

import { SharePrivateContentPermissionToggle } from '~/components/SharePrivateContentModal/shared/SharePrivateContentPermissionToggle';
import { SharePermissionSection } from '~/components/SharePrivateContentModal/SharePrivateContentSettings/SharePermissionSection';
import { SharePermissionSectionContentProps } from '~/components/SharePrivateContentModal/SharePrivateContentSettings/types';

export const ShareCustomFieldsPermissions = memo(
  ({ onPermissionUpdated, shortIdInfo, isLoading }: SharePermissionSectionContentProps) => {
    return (
      <SharePermissionSection title="Custom fields">
        <SharePrivateContentPermissionToggle
          label="Show custom fields"
          id="customField.canView"
          isLoading={isLoading}
          checked={shortIdInfo.permissions.customField.canView}
          onCheckedChange={(checked) =>
            onPermissionUpdated({
              customField: {
                canView: checked,
                canManageApplications: checked ? shortIdInfo.permissions.customField.canManageApplications : false,
              },
            })
          }
        />
        <SharePrivateContentPermissionToggle
          label="Allow editing custom fields"
          id="customField.canManageApplications"
          isLoading={isLoading}
          checked={shortIdInfo.permissions.customField.canManageApplications}
          disabled={!shortIdInfo.permissions.customField.canView}
          onCheckedChange={(checked) =>
            onPermissionUpdated({
              customField: {
                canManageApplications: checked,
              },
            })
          }
        />
      </SharePermissionSection>
    );
  },
);

ShareCustomFieldsPermissions.displayName = 'ShareCustomFieldsPermissions';
