import { tailwindMerge } from '@air/tailwind-variants';
import { ComponentProps } from 'react';

export const ToastLink = ({ className, ...props }: ComponentProps<'button'>) => (
  <button
    className={tailwindMerge(
      'line-height-[1.5] inline-block max-w-[200px] truncate text-16 font-medium text-blue-6 hover:text-blue-7 md:max-w-[328px]',
      className,
    )}
    {...props}
  />
);
