import { WorkspaceMemberType } from '@air/api/types';
import { TriangleDown } from '@air/next-icons';
import { Avatar } from '@air/primitive-avatar';
import { formatFullName, formatInitials } from '@air/utilities';
import React, { memo, RefObject } from 'react';

import { MentionsData } from '~/components/Discussions/DiscussionForm';
import { PopperOptions, usePopperContainer } from '~/hooks/usePopperContainer';
import { getAvatarImageSrc } from '~/utils/getAvatarImageSrc';

const popperOptions: PopperOptions = {
  placement: 'top',
  modifiers: [
    {
      name: 'offset',
      options: {
        offset: [0, -10],
      },
    },
  ],
};

export interface UserMentionTooltipProps {
  user: MentionsData;
  containerRef: RefObject<HTMLElement>;
}

export const UserMentionTooltip = memo(
  ({ containerRef, user: { firstName, lastName, avatar, id, type: userType, roles } }: UserMentionTooltipProps) => {
    const fullName = formatFullName(firstName, lastName);
    const initials = formatInitials(firstName, lastName);

    const { PopperContainer } = usePopperContainer({ containerRef, usePortal: true, options: popperOptions });

    const roleName =
      userType === WorkspaceMemberType.member ? roles.member?.displayName : roles?.guest?.[0]?.displayName;

    return (
      <PopperContainer>
        <div className="pointer-events-none flex flex-col items-center">
          <div className="flex items-center rounded-sm bg-white p-2 shadow-[0px_2px_8px,0px_1px_3px,0px_0px_2px_] shadow-black/20">
            <Avatar alt={fullName} size={24} src={getAvatarImageSrc(avatar, 24)} text={initials} colorSeed={id} />
            <p className="ml-2 text-14 font-medium text-grey-11">{fullName}</p>
            {!!roleName && (
              <>
                <div className="mx-1 text-14 font-medium text-grey-10">・</div>
                <div className="text-14 text-grey-10">{roleName}</div>
              </>
            )}
          </div>
          <TriangleDown className="mt-[-14px] w-8 text-white drop-shadow-[0px_3px_1px_rgba(0,0,0,0.2)]" />
        </div>
      </PopperContainer>
    );
  },
);

UserMentionTooltip.displayName = 'UserMentionTooltip';
