import { memo } from 'react';

import { SharePrivateContentPermissionToggle } from '~/components/SharePrivateContentModal/shared/SharePrivateContentPermissionToggle';
import { SharePermissionSection } from '~/components/SharePrivateContentModal/SharePrivateContentSettings/SharePermissionSection';
import { SharePermissionSectionContentProps } from '~/components/SharePrivateContentModal/SharePrivateContentSettings/types';

export const ShareVersionsPermissions = memo(
  ({ onPermissionUpdated, shortIdInfo, isLoading }: SharePermissionSectionContentProps) => {
    return (
      <SharePermissionSection title="Versions">
        <SharePrivateContentPermissionToggle
          label="Show versions"
          id="canViewAllVersions"
          isLoading={isLoading}
          checked={shortIdInfo.permissions.asset.canViewAllVersions}
          onCheckedChange={(checked) => onPermissionUpdated({ asset: { canViewAllVersions: checked } })}
        />
      </SharePermissionSection>
    );
  },
);

ShareVersionsPermissions.displayName = 'ShareViewerPermissions';
