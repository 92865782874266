import { useAirModal } from '@air/provider-modal';
import { useCallback } from 'react';

import { SubscriptionExpiredModal } from '~/components/Modals/SubscriptionExpiredModal';
import { useCurrentWorkspacePermissionsContext } from '~/providers/CurrentWorkspacePermissionsProvider';
import { useSubscriptionContext } from '~/providers/SubscriptionProvider';
import { canViewSubscription } from '~/utils/permissions/workspacePermissions';

/** This hook returns a function that you can pass any function to and it will call that function if the workspace's subscription has expired. If the subscription has expired, it will not call the function and instead open the SubscriptionExpiredModal.  */
export function useActiveSubscriptionCallback() {
  const { data: subscription } = useSubscriptionContext();
  const { data: permissions } = useCurrentWorkspacePermissionsContext();

  const canSeeSubscription = canViewSubscription(permissions);
  const hasActiveSubscription = !canSeeSubscription || (subscription && subscription.hasActiveSubscription);

  const [showSubscriptionExpiredModal] = useAirModal(SubscriptionExpiredModal);

  const activeSubscriptionCallback = useCallback(
    <T extends (...args: any[]) => any>(fn: T): ReturnType<T> | ReturnType<typeof showSubscriptionExpiredModal> => {
      return hasActiveSubscription ? fn() : showSubscriptionExpiredModal();
    },
    [hasActiveSubscription, showSubscriptionExpiredModal],
  );

  return {
    activeSubscriptionCallback,
  };
}
