import { tailwindMerge } from '@air/tailwind-variants';
import { ComponentPropsWithRef, ElementRef, forwardRef } from 'react';

import { TEXTAREA_ELEMENT_TAG, TEXTAREA_SIZE_CLASSES, textareaClasses, TextareaSize } from './constants';

export type TextareaProps = Omit<ComponentPropsWithRef<typeof TEXTAREA_ELEMENT_TAG>, 'size'> & {
  size?: TextareaSize;
};

export const Textarea = forwardRef<ElementRef<typeof TEXTAREA_ELEMENT_TAG>, TextareaProps>(
  ({ className, rows = 3, size = 'medium', ...restOfProps }, forwardedRef) => {
    return (
      <textarea
        className={tailwindMerge(textareaClasses, TEXTAREA_SIZE_CLASSES[size], className)}
        ref={forwardedRef}
        rows={rows}
        {...restOfProps}
      />
    );
  },
);

Textarea.displayName = 'Textarea';
