import classNames from 'classnames';
import type { ComponentProps } from 'react';
import { ElementRef, forwardRef } from 'react';

export type TreeItemActionProps = ComponentProps<'button'>;

export const TreeItemAction = forwardRef<ElementRef<'button'>, TreeItemActionProps>(
  ({ className, ...restOfProps }, forwardedRef) => {
    return (
      <button
        className={classNames(
          'text-pigeon-11 flex h-6 w-6 cursor-pointer items-center justify-center rounded hover:bg-grey-4',
          className,
        )}
        ref={forwardedRef}
        {...restOfProps}
      />
    );
  },
);

TreeItemAction.displayName = 'TreeItemAction';
