import { sizes } from '@air/provider-media-query';
import { css, FlattenInterpolation } from 'styled-components';

class MediaQueries {
  lt400 = (width: number) => width < 400;
  lt560 = (width: number) => width < 560;
  lt592 = (width: number) => width < 592;
  gte560 = (width: number) => width >= 560;
  gte592 = (width: number) => width < 592;
  sizes = sizes;
  max = {
    small: (values: FlattenInterpolation<any>) => css`
      @media (max-width: ${this.sizes.small / 16}em) {
        ${values}
      }
    `,
    medium: (values: FlattenInterpolation<any>) => css`
      @media (max-width: ${this.sizes.medium / 16}em) {
        ${values}
      }
    `,
    large: (values: FlattenInterpolation<any>) => css`
      @media (max-width: ${this.sizes.large / 16}em) {
        ${values}
      }
    `,
    xlarge: (values: FlattenInterpolation<any>) => css`
      @media (max-width: ${this.sizes.xlarge / 16}em) {
        ${values}
      }
    `,
    xxlarge: (values: FlattenInterpolation<any>) => css`
      @media (max-width: ${this.sizes.xxlarge / 16}em) {
        ${values}
      }
    `,
  };
  min = {
    small: (values: FlattenInterpolation<any>) => css`
      @media (min-width: ${this.sizes.small / 16}em) {
        ${values}
      }
    `,
    medium: (values: FlattenInterpolation<any>) => css`
      @media (min-width: ${this.sizes.medium / 16}em) {
        ${values}
      }
    `,
    large: (values: FlattenInterpolation<any>) => css`
      @media (min-width: ${this.sizes.large / 16}em) {
        ${values}
      }
    `,
    xlarge: (values: FlattenInterpolation<any>) => css`
      @media (min-width: ${this.sizes.xlarge / 16}em) {
        ${values}
      }
    `,
  };
}

export default new MediaQueries();
