import styled, { css } from 'styled-components';

import MQ from '~/utils/MediaQueries';

/*
 * Setting overflox-x hidden because the dashboard sub-sections (like jump back in and recent updates)
 * have the width of this component - 24px in padding, but have overflow-x scroll (auto).
 */
const Main = styled.main`
  height: inherit;
  display: flex;
  position: relative;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
  ${MQ.max.medium(css`
    overflow-x: hidden;
  `)}
`;

export default Main;
