import { TrackClickedConnectToSlackOptionParams, useTrackClickedConnectToSlackOption } from '@air/analytics';
import { Slack } from '@air/api';
import { Board } from '@air/api/types';
import { useCallback } from 'react';

import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';
import { reportErrorToBugsnag } from '~/utils/ErrorUtils';

import { useSlackIntregrationProcessLocation } from './useSlackIntregrationProcessLocation';

interface OnConnectSlackProps {
  board: Pick<Board, 'id' | 'title'>;
  trackLocation: TrackClickedConnectToSlackOptionParams['trackLocation'];
}

export const useConnectToSlack = () => {
  const { currentWorkspace } = useCurrentWorkspace();
  const { trackClickedConnectToSlackOption } = useTrackClickedConnectToSlackOption();
  const { setSlackIntegrationLocation } = useSlackIntregrationProcessLocation();

  const connectToSlack = useCallback(
    async ({ board, trackLocation }: OnConnectSlackProps) => {
      const workspaceId = currentWorkspace?.id;
      if (!workspaceId) {
        throw new Error('No workspace id');
      }
      trackClickedConnectToSlackOption({
        boardTitle: board.title,
        trackLocation,
      });
      const windowOpenRef = window.open();
      try {
        const { url } = await Slack.getAuthUrlForBoard({ workspaceId, boardId: board.id });
        setSlackIntegrationLocation(trackLocation);
        if (windowOpenRef) {
          windowOpenRef.location.href = url;
        }
      } catch (error) {
        if (windowOpenRef) {
          windowOpenRef.close();
        }
        throw reportErrorToBugsnag({
          error,
          context: `Failed to get slack auth url for board`,
          metadata: { data: { board } },
        });
      }
    },
    [trackClickedConnectToSlackOption, setSlackIntegrationLocation, currentWorkspace?.id],
  );

  return { connectToSlack };
};
