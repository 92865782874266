import { AirActionTrackingLocation } from '@air/analytics';
import { ShortUrlResponse } from '@air/api/types';
import React, { memo, useCallback } from 'react';

import { EditableBoardSharePrivateLink } from '~/components/SharePrivateBoardModal/EditableBoardSharePrivateLink';
import { useUpdateShareBoardLink } from '~/components/SharePrivateBoardModal/hooks/useUpdateShareBoardLink';
import {
  EnableSharedLinkToggle,
  EnableSharedLinkToggleProps,
} from '~/components/SharePrivateContentModal/EnableSharedLinkToggle';
import { EnableShareLinkDownloadToggle } from '~/components/SharePrivateContentModal/EnableShareLinkDownloadToggle';
import { SharePrivateContentExpirationDateDisplay } from '~/components/SharePrivateContentModal/SharePrivateContentExpirationDateDisplay';

interface SharePrivateBoardLinkInfoProps {
  shortIdInfo: ShortUrlResponse;
  boardId: string;
  trackLocation: AirActionTrackingLocation;
  goToSettings: () => void;
}

export const SharePrivateBoardLinkInfo = memo(
  ({ shortIdInfo, boardId, trackLocation, goToSettings }: SharePrivateBoardLinkInfoProps) => {
    const { onShortUrlUpdate, isLoading } = useUpdateShareBoardLink({ boardId, shortIdInfo });

    const onToggleEnableLink = useCallback(
      (enabled: boolean) =>
        onShortUrlUpdate({
          disabled: !enabled,
        }),
      [onShortUrlUpdate],
    );

    const onDownloadToggle = useCallback<EnableSharedLinkToggleProps['onToggle']>(
      (enabled) =>
        onShortUrlUpdate({
          permissions: {
            asset: {
              canViewOriginal: enabled,
            },
          },
        }),
      [onShortUrlUpdate],
    );

    return (
      <div className="flex flex-col gap-3">
        <EnableSharedLinkToggle enabled={!shortIdInfo.disabled} isLoading={isLoading} onToggle={onToggleEnableLink} />
        {!shortIdInfo.disabled && (
          <>
            <EnableShareLinkDownloadToggle
              enabled={shortIdInfo.permissions.asset.canViewOriginal}
              isLoading={isLoading}
              onToggle={onDownloadToggle}
              infoText="Assets in this board can be downloaded or saved to other Air workspaces"
            />
            <EditableBoardSharePrivateLink boardId={boardId} trackLocation={trackLocation} shortIdInfo={shortIdInfo} />
            {!!shortIdInfo.expirationDate && (
              <SharePrivateContentExpirationDateDisplay
                expirationDate={shortIdInfo.expirationDate}
                goToAdvancedSettings={goToSettings}
              />
            )}
          </>
        )}
      </div>
    );
  },
);

SharePrivateBoardLinkInfo.displayName = 'SharePrivateBoardLinkInfo';
