import { tailwindMerge } from '@air/tailwind-variants';
import React, { ComponentProps, memo, ReactNode, useCallback } from 'react';
import Linkify, { Props as LinkifyProps } from 'react-linkify';

import { MentionsData } from '~/components/Discussions/DiscussionForm';
import { UserMention } from '~/components/Discussions/UserMention/UserMention';
import { uuidRegExpString } from '~/utils/Url';

// Captures mentions in the style of '@[First Last](UUID)'
// Same as react-mentions, this pattern matching assumes no parentheses and
// brackets in user names.
const MentionRegExp = /(@\[[^()]*\]\([^()]*\))/;
const MentionNameRegExp = /\[(.*)\]/;
const MentionIdRegExp = new RegExp(`.*(${uuidRegExpString}).*`, 'i');

export type CommentDisplayProps = Pick<ComponentProps<'div'>, 'className'> & {
  text: string;
  ['data-testid']?: string;
  metadata?: ReactNode;
  mentionsList?: MentionsData[];
};

export const CommentDisplay = memo(
  ({ className, text, ['data-testid']: testId, metadata, mentionsList = [] }: CommentDisplayProps) => {
    const linkDecorator: LinkifyProps['componentDecorator'] = useCallback(
      (decoratedHref: string, decoratedText: string, key: number) => {
        return (
          <a href={decoratedHref} key={key} target="_blank" rel="noreferrer">
            {decoratedText}
          </a>
        );
      },
      [],
    );

    return (
      <Linkify componentDecorator={linkDecorator}>
        <div className={tailwindMerge('whitespace-pre-wrap text-14 text-grey-11', className)} data-testid={testId}>
          {text.split(MentionRegExp).map((str, index) => {
            if (index === 0 && metadata) {
              return (
                <React.Fragment key={index}>
                  {metadata}
                  <span className="text-14 text-grey-12">{str}</span>
                </React.Fragment>
              );
            }
            if (str.match(MentionRegExp)) {
              const userName = str.match(MentionNameRegExp)?.[1] ?? 'Anonymous';
              const userId = str.match(MentionIdRegExp)?.[1];

              const mentionedUser = mentionsList.find(({ id }) => id === userId);

              return <UserMention key={index} userName={userName} user={mentionedUser} />;
            }
            return str;
          })}
        </div>
      </Linkify>
    );
  },
);

CommentDisplay.displayName = 'CommentDisplay';
