import { useTrackActivatedIntercom } from '@air/analytics';
import { Check as CheckIcon } from '@air/next-icons';
import { Button } from '@air/primitive-button';
import { Modal, ModalCloseButton } from '@air/primitive-modal';

import { Routes } from '~/constants/routes';

export const enterpriseOptions = [
  'Enriched usage reporting',
  'Advanced security and permissions',
  'Dedicated success manager',
  'Custom migrations',
  'Team training and onboarding',
];

export const EnterprisePlanModal = ({ onClose }: AirModalProps<object>) => {
  const { trackActivatedIntercom } = useTrackActivatedIntercom();

  const clickedContactSales = () => {
    trackActivatedIntercom({ location: 'enterprise-contact-sales' });
  };

  return (
    <Modal isOpen className="w-[320px]" onDismiss={onClose}>
      <>
        <div className="flex items-center justify-end">
          <ModalCloseButton className="shrink-0" onClick={onClose} />
        </div>
        <div className="flex flex-col items-center gap-4">
          <p className="text-center text-24 font-semibold text-grey-12"> Enterprise plan</p>
          <p className="text-center text-12 text-grey-11">For medium to large teams with 20+ members</p>
          <p className="text-center text-16 font-medium text-grey-12">Need to make changes?</p>
          <Button appearance="outline" asChild color="blue" size="large" onClick={clickedContactSales}>
            <a href={Routes.enterprise.expansion} target="_blank">
              Contact Us
            </a>
          </Button>
        </div>
        <div className="my-10 flex flex-col gap-3">
          <p className="text-16 font-semibold text-grey-12">Your plan includes</p>
          <div className="flex flex-col gap-3">
            {enterpriseOptions.map((option, index) => (
              <div className="flex gap-2" key={index}>
                <CheckIcon className="w-4 text-teal-9" />
                <p className="text-14 text-grey-11">{option}</p>
              </div>
            ))}
          </div>
        </div>
      </>
    </Modal>
  );
};
