import { useAirModal } from '@air/provider-modal';
import { useEffect } from 'react';

import { SubscriptionExpiredModal } from '~/components/Modals/SubscriptionExpiredModal';
import { useSubscriptionContext } from '~/providers/SubscriptionProvider';

/**
 * While mounted, this hook shows the subscription expired modal if the workspace subscription has expired. It also returns you a boolean stating whether or not the subscription has expired.
 */
export function useShowSubscriptionExpiredModal({ onClose }: AirModalProps) {
  const { data: subscription } = useSubscriptionContext();
  const [showSubscriptionExpiredModal] = useAirModal(SubscriptionExpiredModal);

  useEffect(() => {
    if (subscription && !subscription.hasActiveSubscription) {
      onClose();
      showSubscriptionExpiredModal();
    }
  }, [subscription, showSubscriptionExpiredModal, onClose]);

  return {
    showingSubscriptionExpiredModal: subscription && !subscription.hasActiveSubscription,
  };
}
