import { Boards } from '@air/api';
import { SortDirection } from '@air/types';
import { useInfiniteQuery } from '@tanstack/react-query';
import invariant from 'tiny-invariant';

import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';

export const getFollowingBoardsKey = (workspaceId: string | undefined) => ['FOLLOWING_BOARDS', { workspaceId }];

export const useFollowingBoards = () => {
  const { currentWorkspace } = useCurrentWorkspace();

  const workspaceId = currentWorkspace?.id;

  const { data, isLoading, isFetchingNextPage, fetchNextPage, hasNextPage } = useInfiniteQuery({
    queryKey: getFollowingBoardsKey(workspaceId),
    queryFn: async ({ pageParam }) => {
      invariant(workspaceId, 'No workspace id');

      return Boards.list({
        workspaceId,
        includeAncestors: true,
        following: true,
        libraryBoards: 'ALL',
        sortField: {
          name: 'name',
          direction: SortDirection.asc,
        },
        limit: 100,
        cursor: pageParam || null,
      });
    },
    enabled: !!workspaceId,
    getNextPageParam: (lastPage) => lastPage?.pagination?.cursor,
    initialPageParam: '',
  });

  return {
    data: data?.pages.flatMap((d) => d.data) ?? [],
    isLoading,
    isFetchingNextPage,
    fetchNextPage: hasNextPage ? fetchNextPage : undefined,
    total: data?.pages[0]?.total ?? 0,
  };
};
