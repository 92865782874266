import { AirActionTrackingLocation, useTrackMovedClipsToBoard } from '@air/analytics';
import { Items } from '@air/api';
import { useMutation } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';

import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';
import { moveAssetsToBoardRequestAction } from '~/store/centralizedClip/actions';
import { isFlattenedViewSelector } from '~/store/router/selectors';
import { ClipAndAssetId } from '~/store/selectedItems/types';
import { useUpdateBoardStats } from '~/utils/mutateUtils/BoardStats';
import { useRemoveAssetsFromAllViews, useRemoveAssetsFromUnusedAssetsView } from '~/utils/mutateUtils/GalleryAssets';
import { useAirStore } from '~/utils/ReduxUtils';

interface MoveAssetsToBoardParams {
  clipsInfo: ClipAndAssetId[];
  board: Parameters<typeof moveAssetsToBoardRequestAction>[0]['board'];
  parentBoardId: string;
  trackLocation: AirActionTrackingLocation;
}

export const useMoveAssetsToBoard = () => {
  const dispatch = useDispatch();
  const store = useAirStore();
  const { removeAssetsFromAllViews } = useRemoveAssetsFromAllViews();
  const { updateBoardStats } = useUpdateBoardStats();
  const { removeAssetsFromUnusedAssetsView } = useRemoveAssetsFromUnusedAssetsView();
  const { trackMovedClipsToBoard } = useTrackMovedClipsToBoard();
  const { currentWorkspace } = useCurrentWorkspace();
  const workspaceId = currentWorkspace?.id;

  const moveAssetsToBoard = useMutation({
    mutationFn: ({ clipsInfo, board, parentBoardId }: MoveAssetsToBoardParams) => {
      if (!workspaceId) {
        throw new Error('workspaceId is required');
      }

      return Items.batchMove({
        targetBoardId: board.id,
        workspaceId,
        items: {
          assetIds: clipsInfo.map(({ assetId }) => assetId),
          assetSourceBoardId: parentBoardId,
        },
      });
    },
    onMutate: ({ clipsInfo, board }) => {
      const clipIds = clipsInfo.map(({ id }) => id);
      const assetIds = clipsInfo.map(({ assetId }) => assetId);
      removeAssetsFromUnusedAssetsView({ clipIds });

      dispatch(moveAssetsToBoardRequestAction({ assetIds, board }));
    },
    onSuccess: (_data, { clipsInfo, board, parentBoardId, trackLocation }) => {
      const clipIds = clipsInfo.map(({ id }) => id);

      trackMovedClipsToBoard({
        fromId: parentBoardId,
        toId: board.id,
        clipIds,
        location: trackLocation,
      });

      const isFlattenedView = isFlattenedViewSelector(store.getState()) === 'true';

      if (isFlattenedView) {
        removeAssetsFromAllViews({
          clipIds,
          queryKeyParams: { filters: { boardTree: { is: parentBoardId } } },
        });
      }

      removeAssetsFromAllViews({ clipIds, parentBoardId });
      updateBoardStats(parentBoardId);
    },
  });

  return {
    moveAssetsToBoard,
  };
};
