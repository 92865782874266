import { Clip, ClipAndBoardListItem } from '@air/api/types';

interface TableClipItem {
  data: Clip;
  type: 'asset' | 'file';
  id: string;
}

export const isClipTableItem = (item: ClipAndBoardListItem): item is TableClipItem =>
  item.type === 'asset' || item.type === 'file';
