import { ShortUrlResponse } from '@air/api/types';
import { memo } from 'react';

import { ShareUrlResponseWithSource } from '~/components/SharePrivateClipModal/types';
import { SharePrivateContentExpirationDateSetting } from '~/components/SharePrivateContentModal/SharePrivateContentAdvancedBoardSettings/SharePrivateContentExpirationDateSetting';
import { SharePrivateContentPasscodeProtection } from '~/components/SharePrivateContentModal/SharePrivateContentAdvancedBoardSettings/SharePrivateContentPasscodeProtection';
import { SharePermissionSection } from '~/components/SharePrivateContentModal/SharePrivateContentSettings/SharePermissionSection';
import { ShareRequireAccountSetting } from '~/components/SharePrivateContentModal/SharePrivateContentSettings/ShareRequireAccountSetting';
import { UpdateShortIdParams } from '~/swr-hooks/shortUrl/useUpdateShortId';

interface ShareSecurityTogglesProps {
  shortIdInfo: ShortUrlResponse;
  isLoading: boolean;
  onShortUrlUpdate: (
    props: DeepPartial<Omit<UpdateShortIdParams, 'id' | 'objectId' | 'boardContext' | 'shortId'>>,
  ) => Promise<ShareUrlResponseWithSource>;
}

export const ShareSecurityToggles = memo(({ shortIdInfo, onShortUrlUpdate, isLoading }: ShareSecurityTogglesProps) => {
  return (
    <SharePermissionSection title="Security">
      <ShareRequireAccountSetting isLoading={isLoading} shortIdInfo={shortIdInfo} onShortUrlUpdate={onShortUrlUpdate} />
      <SharePrivateContentPasscodeProtection
        shortIdInfo={shortIdInfo}
        onChangePasscode={(passcode) => onShortUrlUpdate({ passcode })}
      />
      <SharePrivateContentExpirationDateSetting
        shortIdInfo={shortIdInfo}
        onChangeExpirationDate={(expirationDate) => onShortUrlUpdate({ expirationDate })}
      />
    </SharePermissionSection>
  );
});

ShareSecurityToggles.displayName = 'ShareSecurityToggles';
