import { tailwindVariants, VariantProps } from '@air/tailwind-variants';
import type { ComponentProps, ReactNode } from 'react';

export const banner = tailwindVariants({
  base: 'flex gap-2 rounded',
  variants: {
    appearance: {
      danger: 'bg-red-3 text-red-11',
      info: 'bg-blue-2 text-blue-12',
      quiet: 'bg-grey-3 text-grey-12',
      success: 'bg-teal-5 text-blue-12',
      warning: 'bg-yellow-2 text-yellow-12',
    },
    size: {
      small: 'p-2 text-14',
      medium: 'p-4 text-14',
    },
  },
  defaultVariants: {
    appearance: 'quiet',
    size: 'medium',
  },
});

export type BannerVariants = VariantProps<typeof banner>;

export type BannerProps = Omit<ComponentProps<'div'>, 'prefix'> &
  BannerVariants & {
    prefix?: ReactNode;
    suffix?: ReactNode;
  };

export const Banner = ({ appearance, children, className, prefix, size, suffix, ...restOfProps }: BannerProps) => {
  return (
    <div className={banner({ appearance, size, class: className })} data-testid="BANNER" role="alert" {...restOfProps}>
      {prefix ? <div className="shrink-0">{prefix}</div> : null}

      <div className="grow">{children}</div>

      {suffix ? <div className="shrink-0">{suffix}</div> : null}
    </div>
  );
};
