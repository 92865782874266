import { Board } from '@air/api/types';

export const getLibraryBoards = (boards: Board[]) => {
  const boardsOfLibraries: Record<string, Board[]> = {};
  boards.forEach((board) => {
    const libraryId = board.library?.id;
    if (!libraryId) {
      return;
    }
    if (boardsOfLibraries[libraryId]) {
      boardsOfLibraries[libraryId].push(board);
    } else {
      boardsOfLibraries[libraryId] = [board];
    }
  });

  return boardsOfLibraries;
};

export const getUpdatedLibraryBoards = (prev: Record<string, Board[]>, newBoard: Board) => {
  const libraryId = newBoard.library?.id;
  if (!libraryId) {
    return prev;
  }
  const copy = { ...prev };
  if (copy[libraryId]) {
    copy[libraryId].push(newBoard);
  } else {
    copy[libraryId] = [newBoard];
  }
  return copy;
};
