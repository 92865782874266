import { Switch, SwitchProps } from '@air/primitive-switch';
import { PropsWithChildren } from 'react';

export type SharePrivateContentPermissionToggleProps = Pick<
  SwitchProps,
  'checked' | 'disabled' | 'id' | 'isLoading' | 'onCheckedChange'
> & {
  label: string;
};

export const SharePrivateContentPermissionToggle = ({
  checked,
  disabled,
  id,
  isLoading,
  label,
  onCheckedChange,
  children,
}: PropsWithChildren<SharePrivateContentPermissionToggleProps>) => {
  return (
    <div className="flex w-full items-center justify-between">
      <div className="mt-1 flex items-center">
        <label className="mr-2 text-14 text-grey-12">{label}</label>
        {children}
      </div>
      <Switch
        checked={checked}
        data-id={id}
        disabled={disabled}
        id={id}
        isLoading={isLoading}
        onCheckedChange={onCheckedChange}
      />
    </div>
  );
};
