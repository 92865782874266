import { Boards } from '@air/api';
import { Board } from '@air/api/types';
import { useQuery } from '@tanstack/react-query';

import { useCurrentWorkspace } from '~/providers/CurrentWorkspaceProvider';

const IS_BOARD_FOLLOWER = 'IS_BOARD_FOLLOWER';

export const isBoardFollowerKey = (boardId?: Board['id']) => [IS_BOARD_FOLLOWER, { boardId }];

export const useIsFollowingBoard = (boardId?: string) => {
  const { currentWorkspace } = useCurrentWorkspace();
  const workspaceId = currentWorkspace?.id;

  return useQuery({
    queryKey: isBoardFollowerKey(boardId),

    queryFn: () => {
      if (!workspaceId) {
        throw new Error('No workspaceId found');
      }

      if (!boardId) {
        throw new Error('No boardId found');
      }

      return Boards.follows({ boardId, workspaceId });
    },
    enabled: !!boardId && !!workspaceId,
  });
};
