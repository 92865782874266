/**
 * @description This is the `date-fns` format for year-month-day. This particular format (that includes dashes) should be avoided if possible as it
 * could break or render the incorrect date when not within the date picker. Prefer slashes (if you absolutely need this format). This format yields: `2021-06-28`.
 * @url `date-fns` format options: https://date-fns.org/v2.22.1/docs/format
 */
export const YEAR_MONTH_DAY_FORMAT = 'yyyy-MM-dd';

export const MONTH_DAY_YEAR_FORMAT = 'MMM d, yyyy';

/**
 * @description Takes a string in `2020-03-24 16:32:12.16+00` or `2021-06-23T21:33:23.663872+00` and converts it to ISO 8601 (i.e. `2020-06-20T00:54:10.897Z`) before then converting it to a date object
 * @param date
 * @returns Date (in ISO 8601 format) corresponding to the string passed in
 */
export const parseAirDateToISO = (date: string) => {
  const dateStringWithoutPlus = date.split('+')[0].replace(' ', 'T');
  return dateStringWithoutPlus.includes('Z') ? new Date(dateStringWithoutPlus) : new Date(`${dateStringWithoutPlus}Z`);
};

/**
 * @description Takes a string in `2020-03-24` and replaces it with `2020/03/04`. Browsers will either break or provide the
 * incorrect date when there is a dash (even after calling new Date() on it) so the solution is to replace with slash
 * @param string
 * @returns string in exact same format, with only the dashes made into slash
 */
export const replaceDashWithSlash = (dateWithDash: string) => {
  return dateWithDash.replace(/-/g, '/');
};
