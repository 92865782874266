import { useTrackToggledAnonymousAccountRequirementOff } from '@air/analytics/src/events/useTrackToggledAnonymousAccountRequirementOff';
import { useTrackToggledAnonymousAccountRequirementOn } from '@air/analytics/src/events/useTrackToggledAnonymousAccountRequirementOn';
import { ShortUrlResponse } from '@air/api/types';
import { memo, useCallback } from 'react';

import { ShareUrlResponseWithSource } from '~/components/SharePrivateClipModal/types';
import { PermissionToggleInfo } from '~/components/SharePrivateContentModal/shared/PermissionToggleInfo';
import { SharePrivateContentPermissionToggle } from '~/components/SharePrivateContentModal/shared/SharePrivateContentPermissionToggle';
import { UpdateShortIdParams } from '~/swr-hooks/shortUrl/useUpdateShortId';

interface ShareRequireAccountSettingProps {
  shortIdInfo: ShortUrlResponse;
  isLoading: boolean;
  onShortUrlUpdate: (
    props: DeepPartial<Omit<UpdateShortIdParams, 'id' | 'objectId' | 'boardContext' | 'shortId'>>,
  ) => Promise<ShareUrlResponseWithSource>;
}

export const ShareRequireAccountSetting = memo(
  ({ shortIdInfo, isLoading, onShortUrlUpdate }: ShareRequireAccountSettingProps) => {
    const { trackToggledAnonymousAccountRequirementOn } = useTrackToggledAnonymousAccountRequirementOn();
    const { trackToggledAnonymousAccountRequirementOff } = useTrackToggledAnonymousAccountRequirementOff();

    const onCheckedChange = useCallback(
      async (checked: boolean) => {
        const response = await onShortUrlUpdate({
          requireAccount: checked,
        });

        if (checked) {
          trackToggledAnonymousAccountRequirementOn({
            share_url: response.id,
            object_id: response.objectId,
            object_type: response.objectType,
          });
        } else {
          trackToggledAnonymousAccountRequirementOff({
            share_url: response.id,
            object_id: response.objectId,
            object_type: response.objectType,
          });
        }
      },
      [onShortUrlUpdate, trackToggledAnonymousAccountRequirementOff, trackToggledAnonymousAccountRequirementOn],
    );

    return (
      <SharePrivateContentPermissionToggle
        label="Require name and email to view"
        id="requireAccount"
        isLoading={isLoading}
        checked={!!shortIdInfo.requireAccount}
        onCheckedChange={onCheckedChange}
      >
        <PermissionToggleInfo text="Link visitors must enter name and email to view" />
      </SharePrivateContentPermissionToggle>
    );
  },
);

ShareRequireAccountSetting.displayName = 'ShareRequireAccountSetting';
